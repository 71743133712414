<!-- <div class="container" style="margin-top: 100px;margin-left: 0px;"> -->
  <div class="row">
    <div class="col-12">

      <div class="headPage" >
        <div class="titlelist">
          <h1 class="addmodeltitle">Ajouter un modèle</h1>
        </div>
        <div class="allpaths">
          <a class="pathing" [routerLink]="'/pages/insuredmodel/'">Modèles</a><mat-icon svgIcon="chevronright" class="chevronrightIcon" ></mat-icon><a class="current">  Ajouter un modèle</a>
        </div>
      </div>
      
    </div>
    <div class="col-12">
      <form [formGroup]="InsuredModelForm" (ngSubmit)="saveInsuredModel()" class="titles">
        <div class="col text-right" style="margin-top: 62px;">
          <button type="button" (click)="retour()" class="btn btn-info"><i class="fa fa-arrow-left"></i> Retourner à la liste</button>
          <button class="btn btn-success" type="submit" style="margin-left: 41px;"><i class="fa fa-save"></i> Enregistrer</button>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label for="label">Libellé</label>
              <input type="text" class="form-control" id="label" formControlName="label">
            </div>
          </div>
        </div>

        <div class="row align-items-end">
          <div class="col-6">
            <div class="form-group">
              <label for="description">Description</label>
              <textarea row="6" class="form-control" id="description" formControlName="description"></textarea>
            </div>
          </div>
        </div>
        <div class ="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="description">Coverages</label>
             <ng-multiselect-dropdown
              name = "coverages"
              [placeholder]="'Select Coverages'"
              [settings]="dropdownSettingsCoverages"
              [data]="coveragesList"
              [(ngModel)]="selectedCoverages" [ngModelOptions]="{standalone: true}"
              (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)"
              (onDeSelect) = "onItemDeselect($event)">
            </ng-multiselect-dropdown>
          </div>
          <div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <ng-multiselect-dropdown
          name = "Taxes"
          [placeholder]="'Select Taxes'"
          [settings]="dropdownSettingsTaxe"
          [data]="taxe"
          [(ngModel)]="selectedTaxes" [ngModelOptions]="{standalone: true}"
          (onSelect)="onItemSelectTaxe($event)"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelect) = "onItemDeselect($event)">
          </ng-multiselect-dropdown>
  
      <!-- <mat-form-field>
      <mat-label for="description">Taxes</mat-label>
      <mat-select multiple  [(ngModel)]="taxeinfo.taxeid" name="taxeid">
        <mat-option *ngFor="let element of taxe" [value]="element.id" >
  {{element.label}}
        </mat-option>
      </mat-select> 
  </mat-form-field> -->
  </div>
    </div>
      </form>
    </div>
  <div style="display: flex;justify-content: space-around;flex-direction: row; margin:10px;">
    <div style="margin-top: 50px;margin-right:40px;">
      <!--
      **
      ** To Drag and drop Parmaeters
      **
      -->
      <div cdkDropList class="example-list"
           id="cdk-drop-list-init-0"
           [cdkDropListData]="itemsList"
           [cdkDropListConnectedTo]="[doneList,todoList]"
           [cdkDropListEnterPredicate]="noReturnPredicate">
        <div class="row" style="width: 550px;">
          <div class="col-6" *ngFor="let option of itemsList">
            <div class="example-box" cdkDrag>
              <div *cdkDragPlaceholder ></div>
              <button mat-raised-button class="optionsButtons" type="button"
                      style="margin-bottom: 10px">
                {{option.label}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  style="margin-top: 0px;">

      <!--
      **
      ** Insured Parmaeters
      **
      -->
      <div  style="text-align: center; margin-bottom: 50px;">Paramètres assurés</div>

      <div cdkDropList class="example-list"
           #doneList="cdkDropList"
           (cdkDropListDropped)="drop($event)"
           [cdkDropListData]="insuredOptionsList"
           [cdkDropListConnectedTo]="[todoList]" >
        <div >
          <div *ngFor="let item of insuredOptionsList, index as i" >
            <div class="example-custom-option" cdkDrag >
              <div  *cdkDragPlaceholder  ></div>
              <div>
                <div class="row">
                  <div class="col-12" style="padding-right: 40px; padding-left: 40px; padding-top: 20px; padding-bottom: 20px;">
                    <div class="row form-group" >
                      <div class="col-2"><div class="example-handle" cdkDragHandle>
                        <svg width="24px" fill="#480072" viewBox="0 0 24 24">
                          <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </div>
                    </div>
                      <div class="col-6">
                        <app-form-view [item]="item"></app-form-view>
                      </div>
                      <div style="text-align: right;" class="col-2">
                        <i *ngIf="item.toggle" (click)="item.toggle=false" class="fa fa-chevron-up pull-right"></i>
                        <i *ngIf="!item.toggle" (click)="item.toggle=true" class="fa fa-chevron-down pull-right"></i>
                        <i  class="fa fa-trash pull-right" (click)="deleteInsuredOptionsList(i)" ></i>
                      </div>
                    </div>
                    <div *ngIf="item.toggle" class="toggle-Wrapper">
                      <app-form-params [listName]="'insured'" (itemListNameEmitter)="setListNameFormControlSetter($event)"
                                       (itemIdEmitter)="FormControlIdSetter($event)" [item]="item" ></app-form-params>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="example-custom-option-empty" >
              <div class="draggerIdle">
                <img src="assets/icons/modelDrag.png" class="model_drag">
                <h5 style="color: #989898;">Glisser ici les paramètres assurés</h5>
              </div>  
              <div class="example-custom-placeholder" *cdkDragPlaceholder >
               
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--
      **
      ** Global Parmaeters
      **
      -->

      <div style="text-align: center; margin-bottom: 50px;margin-top: 50px;">Paramètres global</div>

      <div cdkDropList class="example-list"
           id="cdk-drop-list-global-0"
           #todoList="cdkDropList"
           (cdkDropListDropped)="drop($event)"
           [cdkDropListData]="globalOptionsList"
           [cdkDropListConnectedTo]="[doneList]">
        <div class="row">
          <div class="col-12" *ngFor="let item of globalOptionsList, index as i">
            <div class="example-custom-option" cdkDrag>
              <div  *cdkDragPlaceholder></div>
              <div>
                <div class="row">
                  <div class="col-12" style="padding-right: 40px; padding-left: 40px; padding-top: 20px; padding-bottom: 20px;">
                    <div class="row form-group">
                      <div class="col-2"><div class="example-handle" cdkDragHandle>
                        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </div></div>
                      <div class="col-8">
                        <app-form-view [item]="item"></app-form-view>
                      </div>
                      <div style="text-align: right;" class="col-2">
                        <i *ngIf="item.toggle" (click)="item.toggle=false" class="fa fa-chevron-up pull-right"></i>
                        <i *ngIf="!item.toggle" (click)="item.toggle=true" class="fa fa-chevron-down pull-right"></i>
                        <i  class="fa fa-trash pull-right" (click)="deleteGlobalOptionsList(i)" style="margin-left: 20px;"></i>
                      </div>
                    </div>
                    <div *ngIf="item.toggle" class="toggle-Wrapper">
                      <app-form-params [listName]="'global'" [item]="item" (itemListNameEmitter)="setListNameFormControlSetter($event)"
                                       (itemIdEmitter)="FormControlIdSetter($event)" ></app-form-params>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="example-custom-option-empty">
              <div class="draggerIdle">
                <img src="assets/icons/modelDrag.png" class="model_drag">
                <h5 style="color: #989898;">Glisser ici les paramètres globales</h5>
              </div>  
              <div class="example-custom-placeholder-empty" *cdkDragPlaceholder></div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
<!-- </div> -->






<!-- Select Form Control Modal -->
<div class="modal fade bd-example-modal-lg" id="MainModel" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleMainModel">Select Form Control</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-bottom: 20px;">
          <div class="col-4">
            <div class="input-group md-form form-sm form-1 pl-0">
              <div class="input-group-prepend">
            <span class="input-group-text purple lighten-3" id="basic-text1">
              <i class="fa fa-search"></i>
              </span>
              </div>
              <input [(ngModel)]="searchStringFormControl" class="form-control my-0 py-1" type="text"
                     placeholder="Search" aria-label="Search">
            </div>
          </div>
          <div class="col-5"></div>
          <div class="col-3 text-right">
            <button class="btn btn-success" data-toggle="modal" (click)="UpdateFormControleList()"
                    data-target="#addFormControlModal">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table text-center">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Label</th>
                <th scope="col">Select</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let fc of formControlList | filter:'label' : searchStringFormControl ; let i = index">
                <th>{{i + 1}}</th>
                <td>{{fc.label}}</td>
                <td>
                  <button type="button" (click)="selectFormControl(fc.id)" data-toggle="modal"
                          data-target="#fillModelContainer" data-dismiss="modal" class="btn btn-warning"><i
                    class="fa fa-check-circle"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<!-- Add Form Control Modal -->
<div class="modal fade" id="addFormControlModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Form Control</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formControl">
          <div class="form-group">
            <label >Label</label>
            <input class="form-control" type="text" formControlName="label" (change)="setFormControlUniqueIdentifier()">
          </div>
          <div class="form-group" style="display: none">
            <label>Unique Identifier</label>
            <input class="form-control" type="text" formControlName="uniqueIdentifier">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveFormControl()">Save changes
        </button>
      </div>
    </div>
  </div>
</div>
