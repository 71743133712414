import {DataTable} from './DataTable';

export class TablePrice {
  constructor(init?: Partial<TablePrice>) {
    Object.assign(this, init);
  }
  id:number;
  label:string="";
  companyId:number;
  productId:number;
  formula:string="";
  dataTables:DataTable[]=[];

  effectiveDate: string="";

  priceTypeTTC: boolean ; 
  paymentAnnuel: boolean;

  tarificationValidity: number;

}
