import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {InsuredModelContainerFull} from '../../entity/InsuredModelContainerFull';
import {Option} from '../../../Model-Inventory/entity/Option';
import {SelectOption} from '../../../Model-Inventory/entity/possibleOpt/SelectOption';
import {TextAreaOption} from '../../../Model-Inventory/entity/possibleOpt/TextAreaOption';
import {CategoryOption} from '../../../Model-Inventory/entity/possibleOpt/CategoryOption';
import {
  CheckBoxInput,
  DateInput,
  NumberInput,
  RadioInput,
  RangeInput,
  TextInput
} from '../../../Model-Inventory/entity/possibleOpt/InputOption';
import {CoverageRequest, FormDataKVP, FormStep, FraisSupp, InputKVP, Insured, PricingEntity} from '../entity/FormStep';
import {Subject} from 'rxjs';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { CustomResponse } from '../entity/CustomResponse';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { ProductFull } from '../../entity/ProductFull';
import { CoverageValue } from 'src/app/pages/Company-Inventory/entity/coveragesValue';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { formatDate } from '@angular/common';
import * as moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-step-form',
  templateUrl: './step-form.component.html',
  styleUrls: ['./step-form.component.css'],
  providers: [
    {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
],
})
export class StepFormComponent implements OnInit {
  @Input() CoveragePerUnitStepForm: any[];
  @Input() insuredModelsContainers:InsuredModelContainerFull;
  @Output() formField = new EventEmitter()
  @Output() checkboxChange: EventEmitter<any[]> = new EventEmitter();
  @Output() fraisSupplement: EventEmitter<any> = new EventEmitter();
  @Output() datesemitEffet: EventEmitter<any> = new EventEmitter();
  @Output() datesemitSous: EventEmitter<any> = new EventEmitter();
  @Input() CoverageGlobalStepForm: any[];

  eventsSubject: Subject<Map<any, any>> = new Subject<Map<any, any>>();
  numberMainInsuredModel:number=0;
  option: Option;
  selectOption:any;
  insuredOptions:Option[]=[];
  productOptions:Option[]=[];
  contractOptions:Option[]=[];
  insuredMap:Map<any, any>=new Map();
  insuredList:Insured[]=[]
  insuredListFull:Insured[]=[]
  contractValues:InputKVP[]=[]
  productValues:InputKVP[]=[]
  nbDuplications:number=1;
  garanties2:any[]
  @Input() product:ProductFull;
  @Input() coveragesValues: any [] | undefined;
  selectedCoverages:CoverageRequest[] =[];
  coverageRequest : CoverageRequest = new CoverageRequest() ;
  cv:any;
  @ViewChild('fraissupplementairespopup') fraissupplementairespopup: TemplateRef<any>;
  cvChecked: boolean []= [];
  cvCheckedG: boolean;
  selectedCoveragesemit : CoverageRequest[] = [];
  coverageRequestglobal : CoverageRequest = new CoverageRequest() ;
  globalinsured:Insured;
  dateNow: Date = new Date();
  souscriptionDate = new FormControl();
  dateForm: FormGroup;

  constructor(private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer, private coverageInventoryService:CoverageInventoryService,private dialog: MatDialog,
    private _formBuilder: FormBuilder ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/deleteIcon.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      `plusSign`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/plusSign.svg`)
    );





// TOday's Date
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = today.getDate().toString().padStart(2, '0');
    this.DateSousDefault = `${year}-${month}-${day}`;
// Tomorrow's Date
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const year2 = tomorrow.getFullYear();
    const month2 = (tomorrow.getMonth() + 1).toString().padStart(2, '0');
    const day2 = tomorrow.getDate().toString().padStart(2, '0');
    this.DateEffetDefault = `${year2}-${month2}-${day2}`;



}

DateSousDefault: string;
DateEffetDefault: string;



       ngOnInit():void {
        this.dateForm = this._formBuilder.group({
          souscriptionDate: new FormControl(moment()),
          effectiveDate: new FormControl(moment().add(1, 'd')),
        });

    this.insuredModelsContainers.insuredModel.parameters.forEach(x=>{
      if(x.typeProperty!=3 ) this.insuredOptions.push(this.caster(x));
      else this.contractOptions.push(this.caster(x));
    })
    this.insuredMap.set(0,new Insured(this.insuredModelsContainers.step));
    this.insuredList.push(new Insured(this.insuredModelsContainers.step));
     this.requiredCoverageGlobal(this.CoverageGlobalStepForm)
     this.requiredCoveragePerUnit(this.CoveragePerUnitStepForm)
     /* this.changeDateEffet({ target: { value: this.DateEffetDefault } });
     this.changeDateSous({ target: { value: this.DateSousDefault } }); */

     this.dateForm.valueChanges.subscribe((form: any)=>{
      const souscriptionDate = form?.souscriptionDate?.toString();
      const effectiveDate = form?.effectiveDate?.toString();
      if(souscriptionDate)
      this.datesemitSous.emit(formatDate(souscriptionDate, 'yyyy-MM-dd', 'en-US'))
      if(effectiveDate)
      this.datesemitEffet.emit(formatDate(effectiveDate, 'yyyy-MM-dd', 'en-US'))
     })
  }

  fraisSupp:FraisSupp[]= [{ label: '', val: 0,recurrent:false,amortized:false,perUnit:false,pourcentage:false}];
  fraisAndValues = {};

  addFraisSupplementaires() {
    const FraisAndValues = [];
    for (const fraissupp of this.fraisSupp) {
      if (fraissupp.label && fraissupp.val) {
        const frais = {
          label: fraissupp.label,
          val: fraissupp.val,
          recurrent: fraissupp.recurrent,
          amortized: fraissupp.amortized,
          perUnit: fraissupp.perUnit,
          pourcentage : fraissupp.pourcentage
        };
        FraisAndValues.push(frais);
      }
    }

    this.fraisSupplement.emit(FraisAndValues);

  }



  addfraisSupp() {
    if (this.fraisSupp.length === 0 || (this.fraisSupp.length > 0 && this.fraisSupp[this.fraisSupp.length - 1].label !== '' && this.fraisSupp[this.fraisSupp.length - 1].val !== null)) {

    this.fraisSupp.push({ label:'',val: 0 ,recurrent:false,amortized:false ,perUnit:false,pourcentage:false});
    }
  }

  removefraisSupp(index: number) {
    if (this.fraisSupp.length > 1) {
    this.fraisSupp.splice(index, 1);}
  }


  dateEffet:string=""
  dateSous:string=""


  changeDateEffet(event){
    this.dateEffet = event.target.value
        this.datesemitEffet.emit(this.dateEffet)

  }

  changeDateSous(event){
    this.dateSous = event.target.value
        this.datesemitSous.emit(this.dateSous)

  }

  chosenYearHandlerFilter(normalizedYear: any, dp) {
    const ctrlValue = this.souscriptionDate.value;
    ctrlValue.year(normalizedYear.year());
    dp._destroyPopup()
    this.datesemitSous.emit(this.dateSous =  formatDate(this.souscriptionDate.value, 'dd-MM-yyyy', 'en-US'))
  }



  addInsured() {
    if(this.insuredMap.size<this.insuredModelsContainers.maxMultiplicity){
      this.nbDuplications++;
    }
    this.insuredMap.set(this.nbDuplications-1,new Insured(this.insuredModelsContainers.step));
  }

  deleteInsured(i:number) {
    if(this.insuredMap.size>this.insuredModelsContainers.minMultiplicity){
      if(this.insuredMap.get(i).mainInsured) this.numberMainInsuredModel--;
      this.insuredMap.delete(i);
      const el:HTMLElement = document.getElementById('form_'+i);
      el.remove();
      this.buildFullInsuredList();
    }

  }

  caster(opt:any){
    let selectOpt:any=null;
    if(opt.possibleOption.type=='Select'){
      selectOpt= new SelectOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='TextArea'){
      selectOpt= new TextAreaOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='Category'){
      selectOpt= new CategoryOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='Input'){
      if(opt.possibleOption.typeInput=='text'){
        selectOpt=new TextInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='number'){
        selectOpt=new NumberInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='date'){
        selectOpt=new DateInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='radio'){
        selectOpt=new RadioInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='range'){
        selectOpt=new RangeInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='checkbox'){
        selectOpt=new CheckBoxInput(opt.possibleOption);
      }
    }
    this.selectOption=selectOpt;
    opt.possibleOption=selectOpt;
    return opt;
  }


  requiredCoveragePerUnit(coverageValue: any) {

coverageValue.forEach((element) => {
        if (element.required) {
          this.coverageRequest = new CoverageRequest();
          this.coverageRequest.coverageCode = element.coverage.code;
          this.selectedCoverages.push(this.coverageRequest);
        }
      });
  }

  requiredCoverageGlobal(coverageValue: any) {
    coverageValue.forEach((element) => {

      if (element.required) {
        this.coverageRequest = new CoverageRequest();
        this.coverageRequest.coverageCode = element.coverage.code;

        this.selectedCoveragesemit.push(this.coverageRequest);
      }
    });

    this.checkboxChange.emit(this.selectedCoveragesemit);
    this.buildFullInsuredList();

  }


  onCheckboxChange(coverageValue: any, event) {
    // this.selectedCoveragesemit=[]
    this.coverageRequest = new CoverageRequest();
    this.coverageRequest.coverageCode = coverageValue.coverage.code;
    if (event.checked) {

      this.selectedCoveragesemit.push(this.coverageRequest);

    } else {
      this.selectedCoveragesemit = this.selectedCoveragesemit.filter(cr => cr.coverageCode !== this.coverageRequest.coverageCode);
    }
    this.checkboxChange.emit(this.selectedCoveragesemit);
    this.buildFullInsuredList()
  }

  onCheckboxChangeperunit(coverageValue: any,event) {
    this.coverageRequest = new CoverageRequest();
    this.coverageRequest.coverageCode =  coverageValue.coverage.code ;
    if (event.checked) {

      // if( !this.selectedCoverages[i]) {
      //     this.selectedCoverages = [];
      // }
      this.selectedCoverages.push(this.coverageRequest);

    } else {
      this.selectedCoverages =  this.selectedCoverages.filter(cr => cr.coverageCode !== this.coverageRequest.coverageCode);
    }
    this.buildFullInsuredList()

  }







   changeForm($event,i:number){
    let insured:Insured =this.insuredMap.get(i);
    insured.inputs=[];
    Object.keys($event.controls).forEach(key => {
      if($event.controls[key].value instanceof Array){
        let val:string="";
        $event.controls[key].value.forEach(opt=>{
          //val+=opt+","
          val+=opt
        })
        insured.inputs.push(new InputKVP(key,val))
      }else{
        insured.inputs.push(new InputKVP(key,$event.controls[key].value))
      }
    });
    insured.coverages = this.selectedCoverages ;
    this.insuredMap.set(i,insured);
    this.buildFullInsuredList();


  }


  emitEventToChild(insuredMap:Map<any, any>) {
    this.eventsSubject.next(insuredMap);
  }

  changeContractOptions($event: any) {
    this.contractValues=[];
    Object.keys($event.controls).forEach(key => {
      if($event.controls[key].value instanceof Array){
        let val:string="";
        $event.controls[key].value.forEach(opt=>{
          val+=opt+","
        })
        this.contractValues.push(new InputKVP(key,val))
      }else{
        this.contractValues.push(new InputKVP(key,$event.controls[key].value))
      }
    });
    this.buildFullInsuredList();
  }

  changeProductOptions($event: any) {
    this.productValues=[];
    Object.keys($event.controls).forEach(key => {
      if($event.controls[key].value instanceof Array){
        let val:string="";
        $event.controls[key].value.forEach(opt=>{
          val+=opt+","
        })
        this.productValues.push(new InputKVP(key,val))
      }else{
        this.productValues.push(new InputKVP(key,$event.controls[key].value))
      }
    });
    this.buildFullInsuredList();
  }

  addContractValues(insured:Insured):Insured {
    this.contractValues.forEach(val=>{
      insured.inputs.push(val);
    })
    return insured;
  }

  addProductValues(insured:Insured):Insured {
    this.productValues.forEach(val=>{
      insured.inputs.push(val);
    })
    return insured;
  }

  buildFullInsuredList(){
    this.insuredListFull=[];
    for (let insured of this.insuredMap.values()) {
      let insured_1=new Insured(this.insuredModelsContainers.step);
      insured_1.mainInsured=insured.mainInsured;
      insured_1.coverages=insured.coverages;
      insured.inputs.forEach(inputs=>{
        insured_1.inputs.push(inputs);
      });
      insured_1 = this.addContractValues(insured_1);
      insured_1 = this.addProductValues(insured_1);
      this.insuredListFull.push(insured_1);
    }
    this.formField.emit(this.insuredListFull);

  }

  handleMainInsured($event: number,insuredIndex:number) {
    if($event>0)
      if(this.insuredModelsContainers.maxMainInsured>this.numberMainInsuredModel){
        this.numberMainInsuredModel+=$event;
        this.insuredMap.get(insuredIndex).mainInsured=true;
      }
      else {
        alert("Maximum number of main Insured is: "+this.insuredModelsContainers.maxMainInsured);
      }
    else{
      if(this.insuredModelsContainers.minMainInsured<this.numberMainInsuredModel){
        this.numberMainInsuredModel+=$event;
        this.insuredMap.get(insuredIndex).mainInsured=false;
      }
      else {
        alert("Minimum number of main Insured is: "+this.insuredModelsContainers.minMainInsured);
      }
    }
    this.emitEventToChild(this.insuredMap);
    this.buildFullInsuredList();
  }

  getCoverage(id:any){
    this.coverageInventoryService.getCoverageById(id).subscribe((data:CustomResponse) => {
      this.garanties2 = data.response ;
    });

  }

  openDialog(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.fraissupplementairespopup, {
      width: 'fit-content', // Set the desired width
    });}


    closeDialog(): void {
      this.dialog.closeAll(); // Close all open dialogs
    }




}
