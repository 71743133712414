import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CustomProductFillComponent } from './custom-product-fill/custom-product-fill.component';
import { CreateInsuredModelComponent } from './Model-Inventory/create-insured-model/create-insured-model.component';
import { InsuredModelsListComponent } from './Model-Inventory/insured-models-list/insured-models-list.component';
import { CompanyListComponent } from './Company-Inventory/company-list/company-list.component';
import { CompanyDetailsComponent } from './Company-Inventory/company-details/company-details.component';
import { CompanyEditComponent } from './Company-Inventory/company-edit/company-edit.component';
import { FilterPipeService } from '../services/filter-pipe.service';
import { CompanysListComponent } from './Company-Engine/companys-list/companys-list.component';
import { ProductFillComponent } from './Company-Engine/product-fill/product-fill.component';
import { InputsComponent } from './Company-Engine/product-fill/inputs/inputs.component';
import { SanitizeHtmlPipe } from '../sanitize-html-pipe.service';
import { PriceTableEngineComponent } from './PriceTableEngine/price-table-engine/price-table-engine.component';
import { MultiStepBarComponent } from './Company-Engine/product-fill/multi-step-bar/multi-step-bar.component';
import { StepFormComponent } from './Company-Engine/product-fill/step-form/step-form.component';
import { PriceTablesListComponent } from './PriceTableEngine/price-tables-list/price-tables-list.component';
import { ProductDetailsComponent } from './Company-Inventory/product-details/product-details.component';
import { PriceBubbleComponent } from './Company-Engine/product-fill/price-bubble/price-bubble.component';
import { CheckBoxComponent } from './Company-Engine/product-fill/check-box/check-box.component';
import { RulesInventoryComponent } from './rules-inventory/rules-inventory.component';
import { DynamicFormComponent } from './Model-Inventory/dynamic-form/dynamic-form.component';
import { FormViewComponent } from './Model-Inventory/dynamic-form/form-view/form-view.component';
import { FormParamsComponent } from './Model-Inventory/dynamic-form/form-params/form-params.component';
import { UpdateInsuredModelComponent } from './Model-Inventory/update-insured-model/update-insured-model.component';
import { CoveragesListComponent } from './Coverage-Inventory/coverages-list/coverages-list.component';
import { CreateCoverageComponent } from './Coverage-Inventory/create-coverage/create-coverage.component';
import { EditCoverageComponent } from './Coverage-Inventory/edit-coverage/edit-coverage.component';
import { ComparatorComponent } from './Comparator/comparator/comparator.component';
import { MultiStepBarComparatorComponent } from './Comparator/multi-step-bar-comparator/multi-step-bar-comparator.component';
import { StepFormComparatorComponent } from './Comparator/step-form-comparator/step-form-comparator.component';
import { InputsComparatorComponent } from './Comparator/inputs-comparator/inputs-comparator.component';
import { DialogComponent } from './Company-Engine/product-fill/dialog/dialog/dialog.component';
import { TaxesComponent } from './Taxes/taxes/taxes.component';
import { CategoriesComponent } from './Taxes/categories/categories.component';
import { TabTaxComponent } from './Taxes/tab-tax/tab-tax.component';
import { environment } from 'src/environments/environment';
import { PagesComponent } from './pages.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomePageComponent } from './home-page/home-page.component';
import { AuthGuard } from '../_auth-guard/auth.guard';
import { TarifRefComponent } from './TarifRef/tarif-ref/tarif-ref.component';
import { TreeViewComponent } from './rules-inventory/tree-view/tree-view.component';

import {MatTreeModule} from '@angular/material/tree';
import { FreeRuleComponent } from './rules-inventory/free-rule/free-rule.component';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { ClipboardModule } from '@angular/cdk/clipboard';




const routes: Routes = [

  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: environment.insuredModelURL+'/add/old',
        component: CreateInsuredModelComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.insuredModelURL+'/add',
        component: DynamicFormComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.insuredModelURL+'/update/:id',
        component: UpdateInsuredModelComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.insuredModelURL,
        component: InsuredModelsListComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.companyURL,
        component: CompanyListComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.companyURL+'/:id/details',
        component: CompanyDetailsComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.companyURL+'/:id/update',
        component: CompanyEditComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'product'+'/:id/details',
        component: ProductDetailsComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'companys',
        component: CompanysListComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'productfill/:id',
        component: ProductFillComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'pricetable/add',
        component: PriceTableEngineComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'rules/edit',
        component: RulesInventoryComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'pricetable/list',
        component: PriceTablesListComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'home',
        component: HomePageComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'coverages',
        component: CoveragesListComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.coverageUrl+'/createCoverage',
        component: CreateCoverageComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: environment.coverageUrl+'/updateCoverage/:code',
        component : EditCoverageComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path:'comparateur',
        component: ComparatorComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path:'taxes',
        component: TabTaxComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      },
      {
        path: 'tarification',
        component: CustomProductFillComponent,
        canActivate: [AuthGuard],
        data:{roles: ['TESTER']}
      },
      /* {
        path:'tarifref',
        component: TarifRefComponent,
        canActivate: [AuthGuard],
        data:{roles: ['SUPERADMIN']}
      }, */
      {
        path: '**',
        redirectTo: '/not-found'
      }
    ]
  }
]


@NgModule({
  declarations: [
    PagesComponent,
    CreateInsuredModelComponent,
    InsuredModelsListComponent,
    CompanyListComponent,
    CompanyDetailsComponent,
    CompanyEditComponent,
    FilterPipeService,
    CompanysListComponent,
    ProductFillComponent,
    InputsComponent,
    SanitizeHtmlPipe,
    PriceTableEngineComponent,
    HomePageComponent,
    MultiStepBarComponent,
    StepFormComponent,
    PriceTablesListComponent,
    ProductDetailsComponent,
    PriceBubbleComponent,
    CheckBoxComponent,
    RulesInventoryComponent,
    DynamicFormComponent,
    FormViewComponent,
    FormParamsComponent,
    UpdateInsuredModelComponent,
    CoveragesListComponent,
    CreateCoverageComponent,
    EditCoverageComponent,
    ComparatorComponent,
    MultiStepBarComparatorComponent,
    StepFormComparatorComponent,
    InputsComparatorComponent,
    DialogComponent,
    TaxesComponent,
    CategoriesComponent,
    TabTaxComponent,
    CustomProductFillComponent,
    TarifRefComponent,
    FreeRuleComponent,
    TreeViewComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    ClipboardModule

  ], exports:[
    FilterPipeService,SanitizeHtmlPipe,
  ]
})
export class PagesModule { }
