<!-- <div class="container" style="margin-top: 100px;margin-left: 0px;width: 100%;"> -->
<!-- <div style="width:100%;overflow-x: hidden;"> -->
<!-- <div class="container" style="margin-top: 100px;margin-left: 30px;overflow-x: hidden;"> -->

<div class="row">
    <div class="col-9">
        <h1 class="titlemodel">Détails de la compagnie</h1>
    </div>
    <div class="col-3">
        <a class="pathing" [routerLink]="'/pages/company/'">Compagnies</a>
        <mat-icon svgIcon="chevronright" class="chevronrightIcon"></mat-icon><a class="current"> Détails</a>
    </div>
</div>
<div class="row  p-5">
    <div class="col-9">
        <button class="modifiertaxebtndarker" [routerLink]="'/pages/company'">
          <mat-icon>arrow_back</mat-icon> Retourner à la liste</button>
    </div>
    <div class="col-3">
        <button class="modifiertaxebtnajouter" data-toggle="modal" data-target="#addProduct">
        <mat-icon>add</mat-icon>
        Ajouter un Produit
      </button>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card bg-light">

            <div class="card-body" style="background-color: #e8d0f3;color: #480072;">
                <h3>{{company.label}}</h3>
                <p class="card-text">{{company.description}}</p>
            </div>
        </div>
    </div>
</div>
<table class="outlines">
    <!-- <tr>
        <td>
      <tr>
        <h1 class="donne-model">Données des Compagnie</h1>
      </tr>
      <tr>
        <h1 class="donne-model-description"></h1>
      </tr> -->
    <!-- <tr>
          <div class="vueD">
            <h1 class="vue">Vues</h1>
            <input class="spinButton" type="number" value="10">
            <h1 class="vue">par ligne</h1>
            <div class="searchI">
            <h1 class="vue">Recherche</h1>
            <input type="text" class="searchinput" >
          </div>
          </div>
      </tr> -->
    <!-- <table class="table text-center">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nom du produit</th>
          <th scope="col">Ligne de produit</th>
          <th scope="col">Gamme</th>
          <th scope="col">Propriétés</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of company.products; let i= index">
            <th>{{i+1}}</th>
            <td>{{product.title}}</td>
            <td>{{product.productLine}}</td>
            <td>{{product.productRange}}</td>
            <td>
              <button class="btn btn-add" [routerLink]="'/product/'+product.id+'/details'"> <i class="fa fa-folder-open"></i></button>

              <button class="btn btn-warning" data-toggle="modal" data-target="#editProduct" (click) = "getProductById(product.id)"> <mat-icon svgIcon="edit" class="editIcon" ></mat-icon></button>

              <button class="btn btn-danger" (click)="deleteProduct(product.id)">  <mat-icon svgIcon="delete" class="deleteIcon" ></mat-icon></button>
            </td>
          </tr>
        </tbody>
      </table>-->


    <!-- </td>
      </tr>-->
</table>
<!--</div>
      </div> -->
<div class="row">
    <div class="col-12">
        <!-- MAT-TABLE DATATABLE BEGINS -->

        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Rechercher...">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let index = index">{{index+1}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="libelle">
                <th mat-header-cell *matHeaderCellDef> Nom du produit </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Ligne de produit </th>
                <td mat-cell *matCellDef="let element"> {{element.productLine}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="nbrproduit">
                <th mat-header-cell *matHeaderCellDef> Gamme </th>
                <td mat-cell *matCellDef="let element"> {{element.productRange}} </td>
            </ng-container>

            <ng-container matColumnDef="proprietes">
                <th mat-header-cell *matHeaderCellDef> Propriétés </th>
                <td mat-cell *matCellDef="let element">
                    <button class="btniconopen" [routerLink]="'/pages/product/'+element.id+'/details'"> <mat-icon>folder</mat-icon>
      </button>

                    <button class="btniconedit" data-toggle="modal" data-target="#editProduct" (click)="getProductById(element.id);getProductCoverages(element.id)"> <mat-icon>edit</mat-icon>
      </button>

                    <!-- <button class="buttonDelete" (click)="deleteProduct(element.id)">  <mat-icon>delete</mat-icon></button> -->
                    <button class="btnicondelete" (click)="openDialog(element.id,element.title)">  <mat-icon>delete</mat-icon>
      </button>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
    </div>
    <!-- MAT-TABLE DATATABLE ENDS  -->
    <tr>
        <!-- <div class="pages">
        <h1 class="pages-listing" *ngIf="company.products.length >= 10">Vue 10 de sur {{company.products.length}} lignes</h1>
        <h1 class="pages-listing" *ngIf="10 > company.products.length">Vue {{company.products.length}} de sur {{company.products.length}} lignes</h1>
        <div class="buttons">
        <button class="pagesbuttonleft" type="button">
            <mat-icon svgIcon="arrowsback" class="arrowsbackIcon" ></mat-icon>
        </button>
        <button class="pagesbutton" type="button">
          1
      </button>
        <button class="pagesbuttonright" type="button">
            <mat-icon svgIcon="arrowsnext" class="arrowsnextIcon" ></mat-icon>
        </button>
      </div> -->

</div>
<!-- </div> -->


<!-- AJOUTER UN PRODUIT POP UP -->
<div class="modal fade" id="addProduct" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: rgba(72,0,114,0.28999999165534973);backdrop-filter: blur(2px); overflow-y:auto;
">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="width: 80%;height: auto;left:30%;top:-15vh;max-height: 30%;">
            <div class="modal-header" style="border-color: #a4a4a4;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: 10px">Ajouter un produit</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body" style="margin-left: 31px;margin-top: 20px;">
                <form [formGroup]="productForm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="title">Nom du produit</label>
                                <input type="text" class="form-control" id="title" formControlName="title" style="width: 509px;height: 37px;">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="productRange">Ligne de produit</label>
                                <input type="text" class="form-control" id="productRange" formControlName="productRange" style="width: 509px;height: 37px;">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="productLine">Gamme</label>
                                <input type="text" class="form-control" id="productLine" formControlName="productLine" style="width: 509px;height: 37px;">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-10">
                            <div class="form-group">
                                <label for="productLine">Les modèles</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="">
                                <div class="table-responsive col-12 form-group">
                                    <table id="sort2" class="grid table table-bordered table-sortable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Modèle</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let i of insuredModelContainerList;let index=index">
                                                <td data-id="11">{{index + 1}}</td>
                                                <td><textarea type="text" value="{{i.insuredModel.label}}" class="form-controlinput" disabled></textarea>


                                                </td>
                                                <td>
                                                    <button class="btn btn-danger">
                          <i class="fa fa-trash"></i>
                        </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-id="11"></td>
                                                <td><input type="text" value="" class="form-control" disabled></td>
                                                <td><input type="text" value="" class="form-control" disabled></td>

                                                <td style="min-width: 140px;">
                                                    <div class="row d-flex justify-content-center align-items-baseline">
                                                        <button style="margin:5px;" type="button" data-toggle="modal" data-target="#MainModel" data-dismiss="#exampleModalLabel" class="btn btn-warning">
                              <i class="fa fa-folder-open"></i> Parcourire </button>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-lg-5">
                            <div class="form-group">
                                <!-- <label for="description">Garanties</label> -->
                                <button class="btnaddg" [disabled]="insuredModelContainerList.length === 0" data-toggle="modal" data-target="#addCoverageValue">Configurer les garanties </button>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <mat-chip-list [disabled]="insuredModelContainerList.length === 0">
                                <mat-chip *ngFor="let garantie of garanties">{{garantie}}</mat-chip>
                            </mat-chip-list>
                        </div>
                    </div>
                </form>
                <div style="margin-top:30px;" *ngIf="insuredModelContainerList.length > 0">
                    <div class="row">
                        <div class="col-lg-12">

                            <!-- <mat-label for="description">Taxes</mat-label> -->

                            <ng-multiselect-dropdown name="taxegaranties" [placeholder]="'Select Taxes'" [settings]="dropdownSettings2" [data]="taxesPerModel" [(ngModel)]="taxeinfo.taxeid" [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelectTaxe()" (onSelectAll)="onSelectAll($event)"
                                (onDeSelect)="onItemDeselect($event)" style="width: 509px;height: 37px; overflow-y: auto;">
                            </ng-multiselect-dropdown>



                            <!-- <mat-form-field >
          <mat-label for="description">Taxes</mat-label>
          <mat-select multiple  [(ngModel)]="taxeinfo.taxeid" name="taxeid">
            <mat-option *ngFor="let element of taxesPerModel" [value]="element.id" >
      {{element.label}}
            </mat-option>
          </mat-select>
      </mat-form-field> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <!-- <div class="row d-flex justify-content-center"> -->
                <!-- <div class="col-lg-6 d-flex justify-content-start"> -->
                <button type="button" class="modifiertaxebtnannuler" data-dismiss="modal">Annuler</button>

                <!-- </div> -->
                <!-- <div class="col-lg-6 d-flex justify-content-end"> -->
                <!-- <button type="button" class="modifiertaxebtn" data-toggle="modal" data-target="#addCoverageValue"  data-dismiss="modal">Suivant</button> -->
                <button type="button" class="modifiertaxebtnsave" data-dismiss="modal" (click)="addProduct();
            ">Ajouter Produit</button>
                <!-- (click)="addCoverageValue()" -->
                <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>

<!--Update Product-->
<div class="modal fade" id="editProduct" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: rgba(72,0,114,0.28999999165534973);backdrop-filter: blur(2px);">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="width: 100%;height: auto;left:20%;top:20%;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifier un produit</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="ProductEditForm">
                    <div class="row align-items-end">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="title">Nom du produit</label>
                                <input type="text" class="form-control" id="title" formControlName="title">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="productRange">Ligne de produit</label>
                                <input type="text" class="form-control" id="productRange" formControlName="productRange">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label for="productLine">Gamme</label>
                                <input type="text" class="form-control" id="productLine" formControlName="productLine">
                            </div>
                        </div>
                        <!--
              <div class="col-12">
              <div class="form-group">
                <label for="productLine">Etapes</label>
                <input (change)="changeSteps()" type="number" min="1" class="form-control" id="steps" formControlName="steps" >
              </div>
            </div>
            -->
                    </div>
                    <div class="row">
                        <div class="col-10">
                            <div class="form-group">
                                <label for="productLine">Les modèles</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="">
                                <div class="table-responsive col-12 form-group">
                                    <table id="sort2" class="grid table table-bordered table-sortable">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Modèle</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let i of productInsuredModels;let index=index">
                                                <td data-id="11">{{index + 1}}</td>
                                                <td><input type="text" value="{{i.label}}" class="form-control" disabled></td>
                                                <td>
                                                    <button class="btn btn-danger">
                          <i class="fa fa-trash"></i>
                        </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-id="11"></td>
                                                <td><input type="text" value="" class="form-control" disabled></td>
                                                <td><input type="text" value="" class="form-control" disabled></td>
                                                <td style="min-width: 140px;">
                                                    <button type="button" data-toggle="modal" data-target="#MainModel" class="btn btn-warning">
                          <i class="fa fa-folder-open"></i>
                          Parcourir
                        </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>
                    </div>
                </form>

                <div class="col-12" *ngIf="productInsuredModels.length > 0">
                    <div class="form-group">
                        <label for="description">Coverages</label>
                        <!-- <ng-multiselect-dropdown
                name = "coverages"
                [placeholder]="'Select Coverages'"
                [settings]="dropdownSettingsCoverages"
                [data]="coveragesListupdate"
                [(ngModel)]="selectedCoverages" [ngModelOptions]="{standalone: true}"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelect) = "onItemDeselect($event)">
              </ng-multiselect-dropdown> -->

                        <!-- <mat-expansion-panel [expanded]="true" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title> Selectionner les garanties </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-selection-list >
                  <mat-list-option *ngFor="let coverageupdate of coveragesListupdate" [selected]="isCoverageSelected(coverageupdate)" (change)="onCheckboxChange($event)" value  >{{coverageupdate.coverage.label}}</mat-list-option>
                </mat-selection-list>
              </mat-expansion-panel>     -->

                        <mat-expansion-panel [expanded]="true" hideToggle style="margin-bottom:10px;">
                            <mat-expansion-panel-header>
                                <mat-panel-title> Selectionner les garanties </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row" *ngFor="let coverageupdate of coveragesListupdate">
                                <div class="col-12">
                                    <mat-checkbox [checked]="isCoverageSelected(coverageupdate)" (change)="onCheckboxChange(coverageupdate)" value>{{coverageupdate.coverage.label}}</mat-checkbox>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-divider></mat-divider>
                        <div class="col-lg-12">
                            <table mat-table [dataSource]="selectedCoverages" class="mat-elevation-z8">

                                <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                                <!-- Position Column -->
                                <ng-container matColumnDef="coveragelabelupdate">
                                    <th mat-header-cell *matHeaderCellDef> Garantie </th>
                                    <td mat-cell *matCellDef="let elementupdate"> {{ elementupdate.label ? elementupdate.label : elementupdate.coverage.label }}</td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="perunitupdate">
                                    <th mat-header-cell *matHeaderCellDef> Per Unit </th>
                                    <td mat-cell *matCellDef="let elementupdate">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="elementupdate.perUnit" name="perunit{{elementupdate.code}}"></mat-slide-toggle>

                                    </td>
                                </ng-container>

                                <!-- Weight Column -->
                                <ng-container matColumnDef="requiredupdate">
                                    <th mat-header-cell *matHeaderCellDef> Required </th>
                                    <td mat-cell *matCellDef="let elementupdate">
                                        <!-- <mat-checkbox class="example-margin" [checked]="checkboxA" [disabled]="checkboxA"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="elementupdate.required" name="required{{elementupdate.code}}" [checked]="elementupdate.required" [disabled]="elementupdate.includedInTotalPrice"></mat-slide-toggle>

                                    </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="includedinpriceupdate">
                                    <th mat-header-cell *matHeaderCellDef> Included in price </th>
                                    <td mat-cell *matCellDef="let elementupdate">
                                        <!-- <mat-checkbox class="example-margin" [(ngModel)]="checkboxA" [checked]="checkboxA" (change)="checkA()"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="elementupdate.includedInTotalPrice" name="includedinprice{{elementupdate.code}}" (change)="checkAupdate(elementupdate)"></mat-slide-toggle>

                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="paymentannuelupdate">
                                    <th mat-header-cell *matHeaderCellDef> paymentAnnuel </th>
                                    <td mat-cell *matCellDef="let elementupdate">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="elementupdate.paymentAnnuel" name="paymentannuel{{elementupdate.code}}"></mat-slide-toggle>

                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="pricetypettcupdate">
                                    <th mat-header-cell *matHeaderCellDef> priceTypeTTC </th>
                                    <td mat-cell *matCellDef="let elementupdate">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="elementupdate.priceTypeTTC" name="pricetypettc{{elementupdate.code}}"></mat-slide-toggle>

                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="taxesupdate">
                                    <th mat-header-cell *matHeaderCellDef> taxes </th>
                                    <td mat-cell *matCellDef="let elementupdate">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <!-- <mat-slide-toggle [(ngModel)]="element2.taxes" name="taxes{{element2.code}}" ></mat-slide-toggle> -->
                                        <ng-multiselect-dropdown name="taxegaranties" [placeholder]="'Select Taxes'" [settings]="dropdownSettingscoverageupdate" [data]="taxesPerModel" [(ngModel)]="elementupdate.taxes" [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelectTaxe()" (onSelectAll)="onSelectAll($event)"
                                            (onDeSelect)="onItemDeselect($event)" style="width: 509px;height: 37px; overflow-y: auto;">
                                        </ng-multiselect-dropdown>

                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsgarantiesupdate"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsgarantiesupdate;"></tr>
                            </table>
                        </div>


                    </div>
                    <mat-label for="description">Taxes</mat-label>
                    <ng-multiselect-dropdown name="Taxes" [placeholder]="'Select Taxes'" [settings]="dropdownSettings2" [data]="taxesPerModel" [(ngModel)]="selectedTaxes" [ngModelOptions]="{standalone: true}" (onSelectAll)="onSelectAll($event)" (onSelect)="onItemSelectTaxe()"
                        (onDeSelect)="onItemDeselect($event)">
                    </ng-multiselect-dropdown>
                </div>
                <!-- </form> -->



            </div>
            <div class="modal-footer">
                <button type="button" class="modifiertaxebtnannuler" data-dismiss="modal">Annuler</button>
                <button type="button" class="modifiertaxebtnsave" (click)="ProductTaxeUpdate();updateCoverage(productFilled.id)" data-dismiss="modal">Enregistrer</button>
            </div>
        </div>
    </div>
</div>

<!-- Select Model Modal -->
<div class="modal fade bd-example-modal-lg" id="MainModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="backdrop-filter: blur(2px);
">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="width: 100%;height:70vh;left:20%;top:70%;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleMainModel">Sélectionner le modèle principal assuré</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-3">
                        <div class="input-group md-form form-sm form-1 pl-0">
                            <div class="input-group-prepend">
                                <span class="input-group-text purple lighten-3" id="basic-text1">
              <i class="fa fa-search"></i>
              </span>
                            </div>
                            <input [(ngModel)]="searchString" class="form-control my-0 py-1" type="text" placeholder="Rechercher" aria-label="Search">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div style="overflow-y: auto;width:auto;height: 30vh;">
                            <table class="table text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Libellé</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Nombre des options</th>
                                        <th scope="col">sélectionner</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let model of modelsList | filter:'label' : searchString ; let i = index">
                                        <th>{{i+1}}</th>
                                        <td>{{model.label}}</td>
                                        <td>{{model.description}}</td>
                                        <td>{{model.parameters.length}}</td>
                                        <td>
                                            <button mat-icon-button type="button" (click)="selectModel(model.id)" data-toggle="modal" data-target="#fillModelContainer" data-dismiss="modal" class="checkaddmodel"> <i class="fa fa-check-circle"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="modifiertaxebtnannuler" data-dismiss="modal">Annuler</button>

            </div>
        </div>
    </div>
</div>

<!-- Fill Model Container Modal -->
<!-- OPTIONS POPUP -->
<div class="modal fade" id="fillModelContainer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: rgba(72,0,114,0.28999999165534973);backdrop-filter: blur(2px);
">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="width: 80%;height: auto;left:30%;top:50%;">
            <div class="modal-header">
                <h5 class="modal-title">Options</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="insuredModelContainerForm">
                    <div class="row align-items-end">
                        <div class="col-lg-6 d-flex justify-content-center">
                            <div class="form-group">
                                <mat-checkbox style="margin:10px;" id="duplicated" #duplicated formControlName="multiple" [disabled]="useAsMainInsured.checked">Dupliqué</mat-checkbox>
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-center">
                            <div class="form-group">
                                <mat-checkbox style="margin:10px;" id="useAsMainInsured" formControlName="useAsMainInsured" #useAsMainInsured [disabled]="duplicated.checked">Utiliser comme assuré principal:</mat-checkbox>
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-center " *ngIf="insuredModelContainerForm.value['multiple']">
                            <div class="form-group">
                                <label for="productLine">Duplication minimale</label>
                                <input type="number" class="form-control" id="minMultiplicity" formControlName="minMultiplicity">
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-center" *ngIf="insuredModelContainerForm.value['multiple']">
                            <div class="form-group">
                                <label for="productLine">Duplication maximale</label>
                                <input type="number" class="form-control" id="maxMultiplicity" formControlName="maxMultiplicity">
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-center" *ngIf="insuredModelContainerForm.value['useAsMainInsured']">
                            <div class="form-group">
                                <label for="productLine">N° minimale des assurés principal </label>
                                <input type="number" class="form-control" id="minMainInsured" formControlName="minMainInsured">
                            </div>
                        </div>
                        <div class="col-lg-6 d-flex justify-content-center" *ngIf="insuredModelContainerForm.value['useAsMainInsured']">
                            <div class="form-group">
                                <label for="productLine">N° maximale des assurés principal</label>
                                <input type="number" class="form-control" id="maxMainInsured" formControlName="maxMainInsured">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="modifiertaxebtnannuler" data-dismiss="modal">Annuler</button>
                <button type="button" class="modifiertaxebtnsave" data-dismiss="modal" (click)="pushInsuredModelContainer()
        ">Enregistrer</button>
            </div>
        </div>
    </div>
</div>



<!-- Add Coverage Value Modal -->
<div class="modal fade" id="addCoverageValue" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" style="width: 100%;height:70vh;left:20%;top:70%;">
            <div class="modal-header">
                <h5 class="modal-title">Garanties</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <!-- <div class="valuesForm" [formGroup]="coverageValuesFormGroup" style="margin-top: 30px;"> -->
                <div class="valuesForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5>Selectionner les garanties</h5>
                            <mat-divider style="margin:20px;"></mat-divider>
                            <!-- <ng-multiselect-dropdown
                name = "coverages"
                [placeholder]="'Select Coverages'"
                [settings]="dropdownSettings"
                [data]="garanties"
                [(ngModel)]="selectedCoverages"
                (onSelect)="onItemSelect($event)"
                (onSelectAll)="onSelectAll($event)"
                (onDeSelect) = "onItemDeselect($event)"
                style="width: 509px;height: 37px; overflow-y: auto;">
  </ng-multiselect-dropdown> -->
                            <mat-expansion-panel [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title> Selectionner les garanties </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-selection-list [(ngModel)]="coverageselect" name="coverageslect" #shoes>
                                    <mat-list-option *ngFor="let coverage of coveragesList" [value]="coverage">{{coverage.label}}</mat-list-option>
                                </mat-selection-list>
                            </mat-expansion-panel>


                            <!-- <mat-form-field>
        <mat-label>Toppings</mat-label>
        <mat-select  multiple [(ngModel)]="coverageselect"  name="coverageslect">
          <mat-option *ngFor="let coverage of coveragesList"  [value]="coverage">{{coverage.label}}</mat-option>
        </mat-select>
      </mat-form-field> -->
                            <mat-divider style="margin:20px;"></mat-divider>
                        </div>

                        <div class="col-lg-12">
                            <table mat-table [dataSource]="coverageselect" class="mat-elevation-z8">

                                <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

                                <!-- Position Column -->
                                <ng-container matColumnDef="coveragelabel">
                                    <th mat-header-cell *matHeaderCellDef> Garantie </th>
                                    <td mat-cell *matCellDef="let element2"> {{element2.label}}</td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="perunit">
                                    <th mat-header-cell *matHeaderCellDef> Per Unit </th>
                                    <td mat-cell *matCellDef="let element2">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="element2.perunit" name="perunit{{element2.code}}"></mat-slide-toggle>

                                    </td>
                                </ng-container>
                                <!-- Weight Column -->
                                <ng-container matColumnDef="required">
                                    <th mat-header-cell *matHeaderCellDef> Required </th>
                                    <td mat-cell *matCellDef="let element2">
                                        <!-- <mat-checkbox class="example-margin" [checked]="checkboxA" [disabled]="checkboxA"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="element2.required" name="required{{element2.code}}" [checked]="element2.required" [disabled]="element2.includedprice"></mat-slide-toggle>

                                    </td>
                                </ng-container>

                                <!-- Symbol Column -->
                                <ng-container matColumnDef="includedinprice">
                                    <th mat-header-cell *matHeaderCellDef> Included in price </th>
                                    <td mat-cell *matCellDef="let element2">
                                        <!-- <mat-checkbox class="example-margin" [(ngModel)]="checkboxA" [checked]="checkboxA" (change)="checkA()"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="element2.includedprice" name="includedprice{{element2.code}}" (change)="checkA(element2)"></mat-slide-toggle>
                                        <!-- <button type="button" class="modifiertaxebtnajouter" (click)="checkA(element2)" >Sauvegarder</button> -->

                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="paymentannuel">
                                    <th mat-header-cell *matHeaderCellDef> paymentAnnuel </th>
                                    <td mat-cell *matCellDef="let element2">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="element2.paymentannuel" name="paymentannuel{{element2.code}}"></mat-slide-toggle>

                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="pricetypettc">
                                    <th mat-header-cell *matHeaderCellDef> priceTypeTTC </th>
                                    <td mat-cell *matCellDef="let element2">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <mat-slide-toggle [(ngModel)]="element2.pricetypettc" name="pricetypettc{{element2.code}}"></mat-slide-toggle>

                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="taxes">
                                    <th mat-header-cell *matHeaderCellDef> taxes </th>
                                    <td mat-cell *matCellDef="let element2">
                                        <!-- <mat-checkbox class="example-margin"></mat-checkbox> -->
                                        <!-- <mat-slide-toggle [(ngModel)]="element2.taxes" name="taxes{{element2.code}}" ></mat-slide-toggle> -->
                                        <ng-multiselect-dropdown name="taxegaranties" [placeholder]="'Select Taxes'" [settings]="dropdownSettings2" [data]="taxesPerModel" [(ngModel)]="element2.taxesgaranties" [ngModelOptions]="{standalone: true}" (onSelect)="onItemSelectTaxe()" (onSelectAll)="onSelectAll($event)"
                                            (onDeSelect)="onItemDeselect($event)" style="width: 509px;height: 37px; overflow-y: auto;">
                                        </ng-multiselect-dropdown>

                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsgaranties"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsgaranties;"></tr>
                            </table>
                        </div>




                        <!-- <ng-container formArrayName="coveragesV">
                <ng-container *ngFor="let coverageForm of coveragesV.controls; let i = index">
                    <div class="coverageForm" [formGroup]= "coverageForm" (change)="fillCoverageValue()">

                      <label style="font-size: 20px;font-weight: 400;">{{selectedCoverages[i].label}} :</label>
                      <div class="inputsValues">
                        <label class="inputLabel">Valeur :</label>
                        <input class="inputInput"  type="text" formControlName="value"  />
                        <label class="inputLabel">Unité :</label>
                        <input class="inputInput" type="text" formControlName="unite" />
                      </div>
                    </div>
                </ng-container>
            </ng-container> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="modifiertaxebtnannuler" data-dismiss="modal" data-target="#addProduct" data-toggle="modal">Annuler</button> -->
                    <button type="button" class="modifiertaxebtnajouter" data-dismiss="modal" data-target="#addProduct">Sauvegarder</button>
                </div>
            </div>
        </div>

        <!-- Pop Up Delete Confirmation -->
        <ng-template #deleteconfirmationdialog>
            <form (ngSubmit)="deleteProduct()">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-12 d-flex justify-content-center">
                        <mat-icon class="warningicon">warning_amber</mat-icon>
                    </div>
                    <div class="col-lg-12 d-flex justify-content-center">
                        <mat-label>Voulez-vous vraiment supprimer</mat-label>
                    </div>
                    <div class="col-lg-12 d-flex justify-content-center">
                        <h5>{{selectedItemToDelete.title}}</h5>
                    </div>
                </div>
                <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

                <div class="row d-flex justify-content-center">
                    <div class="col-lg-6 d-flex justify-content-center">
                        <button class="modifiertaxebtnannuler" type="button" [matDialogClose]>Annuler</button>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-center">
                        <button class="modifiertaxebtn" mat-dialog-close type="submit">Confirmer</button>
                    </div>
                </div>
            </form>
        </ng-template>
        <!-- Pop Up Delete Confirmation -->