import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyEngineService} from '../../../../services/company-engine.service';
import {ProductFull} from '../../entity/ProductFull';
import {InsuredModelContainerFull} from '../../entity/InsuredModelContainerFull';
import {CoverageRequest, FormMultiStep, FraisSupp, Insured, PricingEntity} from '../entity/FormStep';
import { CoverageValue } from 'src/app/pages/Company-Inventory/entity/coveragesValue';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { CustomResponse } from '../entity/CustomResponse';

@Component({
  selector: 'app-multi-step-bar',
  templateUrl: './multi-step-bar.component.html',
  styleUrls: ['./multi-step-bar.component.css']
})

export class MultiStepBarComponent implements OnInit {
@Input() coveragesValues: any [] | undefined;
@Input() CoverageGlobal: any[];
@Input() CoveragePerUnit: any[];
@Input() product:ProductFull;
@Output() formField = new EventEmitter();
@Output() insuredContainerIndex = new EventEmitter();
@Output() newItemEvent = new EventEmitter<string>();

@Output() checkboxChange: EventEmitter<any[]> = new EventEmitter();
@Output() checkboxChangeperunit: EventEmitter<any[]> = new EventEmitter();
@Output() fraisSupplement: EventEmitter<any> = new EventEmitter();
@Output() datesemitEffet: EventEmitter<any> = new EventEmitter();
@Output() datesemitSous: EventEmitter<any> = new EventEmitter();
selectedCoveragesemit :CoverageRequest[] =[];
coverageRequest : CoverageRequest = new CoverageRequest() ;
 pricingForms:Insured[]=[];
 step:number=1;
 selectedInsuredModel:InsuredModelContainerFull=null;

  constructor(private coverageInventoryService: CoverageInventoryService,private cdr: ChangeDetectorRef) {}

  onCheckboxChange(selectedCoveragesemit: CoverageRequest[]) {
    this.checkboxChange.emit(selectedCoveragesemit);
  }
  addFraisSupplementaires(fraisAndValues){
    this.fraisSupplement.emit(fraisAndValues);
  }


  onCheckboxChangeperunit(selectedCoveragesPerUnit:any[]) {
    // this.checkboxChangeperunit.emit(selectedCoveragesPerUnit) ;
  }

  changeDateEffet(dateEffet){
    this.datesemitEffet.emit(dateEffet)
  }



  changeDateSous(dateSous){
    this.datesemitSous.emit(dateSous)
  }

  ngOnInit(): void {
    this.selectInsuredContainer();
    // this.getCoveragesByProductId(this.product.id)

  }







  selectInsuredContainer(){
    this.product.insuredModelContainers.forEach( insuredContainer =>{
      if(insuredContainer.step==(this.step+1)) this.selectedInsuredModel=insuredContainer;
    });
  }
  setStep(i: number) {
    this.step=i;
    this.selectInsuredContainer();
  }
  changeForm($event:any,i:number) {
    this.pricingForms=$event;
    this.insuredContainerIndex.emit(i);
    this.formField.emit(this.pricingForms);
  }
}
