import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { FreeRule } from '../pages/rules-inventory/entities/models/models';

@Injectable({
  providedIn: 'root'
})
export class RulesInventoryService {

  service:string="RULES-INVENTORY/";

  constructor(private http:HttpClient) { }
  addPreRule(priceTableId:number,data:string){
    return this.http.post(environment.baseUrl+this.service+"rule/add/pre/"+priceTableId,data,{
      headers:new HttpHeaders({
        'content-type':'application/text'
      })
    })
  }
  addPostRule(priceTableId:number,data:string){
    return this.http.post(environment.baseUrl+this.service+"rule/add/post/"+priceTableId,data,{
      headers:new HttpHeaders({
        'content-type':'application/text'
      })
    })
  }
  getPostRule(priceTableId:number){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(environment.baseUrl+this.service+"rule/post/"+priceTableId,{ headers, responseType: 'text'})
  }
  getPreRule(priceTableId:number){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(environment.baseUrl+this.service+"rule/pre/"+priceTableId,{ headers, responseType: 'text'})
  }

  addFreeRule(freeRule : FreeRule){
    return this.http.post(environment.baseUrl+this.service+"freeRule/V0/add/",freeRule,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })
    })
  }

  getFreeRules(){
    return this.http.get(environment.baseUrl+this.service+"freeRule/V0/all")
  }

  getFreeRuleById(freeRuleId : string) {
    return this.http.get(environment.baseUrl+this.service+"freeRule/V0/"+freeRuleId)
  }
}
