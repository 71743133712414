import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {CompanyInventoryService} from '../../../services/company-inventory.service';
import {Router} from '@angular/router';
import {Company} from '../entity/company';
import {FormBuilder, FormGroup} from '@angular/forms';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements OnInit {
  companysList:Company[]=[];
  companyForm:FormGroup;
  displayedColumns: string[] = ['index', 'libelle', 'description', 'nbrproduit', 'proprietes'];
  dataSource :  MatTableDataSource<any>= new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private companyInventoryService:CompanyInventoryService,private route:Router,private formBuilder: FormBuilder,private domSanitizer: DomSanitizer,public dialog: MatDialog) {
    this.companyForm= this.formBuilder.group(
      {
        label:'',
        description:'',
      }
    )
  }

  ngOnInit(): void {
    this.getCompanysList();
  }
  @ViewChild('deleteconfirmationdialog') deleteconfirmationdialog: TemplateRef<any>;
  @ViewChild('dialogContentTemplate') dialogContentTemplate: TemplateRef<any>;
  selectedItemToDelete: { id: number, label: string };
  openDialog(id,label): void {
    this.selectedItemToDelete = {id,label};

    const dialogRef: MatDialogRef<any> = this.dialog.open(this.deleteconfirmationdialog, {
      width: 'fit-content', // Set the desired width
    
    
    });

  
  }


  getCompanysList(){
    this.dataSource.paginator = this.paginator;
    this.companyInventoryService.getAllCompanys().subscribe((data:Company[])=>{
      this.companysList=data;
      this.dataSource.data= data;
    })
  }


  addCompany() {
    let company:Company = new Company(this.companyForm.value);
    this.companyInventoryService.addCompany(company).subscribe(data=>{
      // this.ngOnInit();
      this.companyForm.reset();
      window.location.reload();
    })
  }

  delete() {
    this.companyInventoryService.deleteCompany(this.selectedItemToDelete.id).subscribe(data=>{
      this.ngOnInit();
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}



