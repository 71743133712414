export class FormStep {
  forms:any[]=[];
}

export class FormDataKVP {

  constructor(id: string, val: any) {
    this.id = id;
    this.val = val;
  }

  id:string;
  val:any;
}

export class FormMultiStep{
  steps:FormStep[]=[];
}


export class PricingEntity {
  companyId:number;
  productId:number;
  tablePriceId:number;
  coverages : CoverageRequest[]=[] ;
  fraisSupp:FraisSupp[]=[];
  creationDate:string=null;
  effectiveDate:string=null;
    form:Insured[]=[];
  addInsured(insured:Insured){
    this.form.push(insured)
  }
}
export class Insured {
  constructor(num_form:number) {
    this.form=num_form;
  }
  inputs:InputKVP[]=[];
  mainInsured:boolean=false;
  form:number;
  coverages: CoverageRequest[] = [];
}

export class CoverageRequest {
  coverageCode: string ;
}



export class FraisSupp{
  
  label:string;
  val:number;
  recurrent:boolean;
  amortized:boolean;
  perUnit:boolean;
  pourcentage:boolean; 
}

export class InputKVP {

  constructor(key: string, val: string) {
    this.key = key;
    this.val = val;
  }
  key:string;
  val:string;
}
