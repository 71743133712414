import { Component, OnInit , ChangeDetectionStrategy , Input } from '@angular/core';


import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { RulesEngineService } from 'src/app/services/rules-engine.service';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';

interface ObjectStructure {
  name: string;
  children?: ObjectStructure[];
}

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent implements OnInit {
  @Input() inputData: Object = null ; 

  objectStructure : ObjectStructure[] = []
  treeControl = new NestedTreeControl<ObjectStructure>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ObjectStructure>();


  constructor(private rulesEngineService:RulesEngineService) { }

  ngOnInit(): void {
    this.rulesEngineService.getJsonStructure(this.inputData).subscribe((response: CustomResponse) => {
        this.objectStructure = response.response;
        this.dataSource.data = this.objectStructure;
    });
  }

  hasChild = (_: number, node: ObjectStructure) => !!node.children && node.children.length > 0;

}
