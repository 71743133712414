export class CoverageDto {
    constructor(init?: Partial<CoverageDto>) {
        Object.assign(this, init);
      }
    
    code: string;
    description: string;
    label: string;
    unite: string;
}

export class ProductCoverageDto {
    coverageCode: string;
    includedInTotalPrice: boolean;
    perUnit: boolean;
    required: boolean;
}

export class ProductDtoCoverage {
    coverages:ProductCoverageDto[];
    productId: number;
    productlineId: number;
}
export class ProductlineDto {
    coveragesCodes: string[];
    productLineId: number;
}

