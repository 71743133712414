import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { environment } from 'src/environments/environment';
import { Coverage } from '../entity/Coverage';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CoverageDto } from './entities/dtos/coveragedtos';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import { ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-coverages-list',
  templateUrl: './coverages-list.component.html',
  styleUrls: ['./coverages-list.component.css']
})
export class CoveragesListComponent implements OnInit {
  CoverageForm: FormGroup;
  coveragesList:Coverage[]=[];
  coveragedto:CoverageDto[]=[] 
  displayedColumns: string[] = ['index', 'code', 'active', 'libelle', 'description', 'unite' , 'proprietes'];
  dataSource :  MatTableDataSource<any>= new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  location: any;
  CoverageEditForm: FormGroup;
  coverage:Coverage ; 
  coverageID : any ; 
  coverageCode : any ; 

  constructor(private route:Router,private coverageInventoryService:CoverageInventoryService ,private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer,private formBuilder: FormBuilder,public dialog: MatDialog,private router:Router,private activeRoute: ActivatedRoute ,) { 
  
  }

  ngOnInit(): void {
    this.getAll(); 
    this.initForm(); 
    this.initFormupdate(); 
    
  }
   initForm() {

    this.CoverageForm = this.formBuilder.group({
      label: '',
      description: '', 
      code: '', 
      unite :'',
      active:true

    });
    
  }

  getAll(){
    this.dataSource.paginator = this.paginator;
    this.coverageInventoryService.getAllCoverages().subscribe((data:CustomResponse)=>{
      this.coveragesList = data.response;
      this.dataSource.data= this.coveragesList;
      this.dataSource.paginator = this.paginator;
      console.log(this.coveragesList);


    })
  }

  

  editCoverage(id:number) {
    this.route.navigate(['/'+environment.coverageUrl+'/updateCoverage/'+id])
  }

  // addCoverage() {
  //   this.route.navigate(['/'+environment.coverageUrl+'/createCoverage/'])
  // }

  addCoverage(){
    let coverage = new CoverageDto(this.CoverageForm.value);  
    console.log("yi5dim !!! "); 

    this.coverageInventoryService.addCoverage(coverage).subscribe(data => {
      alert("Garantie ajouté avec succées");
      this.returnToList();

    }); 
  }

  returnToList(){
    // this.route.navigate(['/'+environment.coverageUrl]); 
    location.reload();

  }


  findByType(type :string){
    this.coverageInventoryService.findByType(type).subscribe((data : Coverage[]) =>{
      if (data.length == 0 || type.length == 0){
        this.getAll();
      }else{
      this.coveragesList = data ;
      }
    }); 
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();

    }
  }



  @ViewChild('deleteconfirmationdialog') deleteconfirmationdialog: TemplateRef<any>;
  @ViewChild('dialogContentTemplate') dialogContentTemplate: TemplateRef<any>;
  @ViewChild('updatecoveragedialog') updatecoveragedialog: TemplateRef<any>;
   selectedItemToDelete: { id: number, label: string };
   selectedItemToupdate: {code:any};
   openDialog(id,label): void {
     this.selectedItemToDelete = {id,label};
 
     const dialogRef: MatDialogRef<any> = this.dialog.open(this.deleteconfirmationdialog, {
       width: 'fit-content', // Set the desired width
     
     
     });
 
   
   } 
   openDialogupdate(code): void {
    this.selectedItemToupdate = {code};

    this.getCoverage(code); 

     const dialogRef: MatDialogRef<any> = this.dialog.open(this.updatecoveragedialog, {
       width: 'fit-content', // Set the desired width
       
     
     });
 
   
   }
 
   delete() {
    this.coverageInventoryService.deleteById(this.selectedItemToDelete.id).subscribe(data=>{
      this.ngOnInit();
    })
  }


  setStatus(code:string){
    this.coverageInventoryService.CoverageStatus(code).subscribe(
       data => {
        console.log("element is now active",this.coveragesList)
       },
       error => {
         console.error("Error changing status:", error);
       }
     );
   }
   toggleStatusText(element: any): void {
    // Update the status text based on the element's active property
    element.active = !element.active;
    console.log(element.active)
  }

  getStatusText(element: any): string {
    return element.active ? 'Active' : 'Désactivé';
  }

// Update Covergae 

private initFormupdate() {

  this.CoverageEditForm = this.formBuilder.group({
    label: '',
    description: '', 
    code: '', 
    unite:'',
    active:true

  });
  
}
getCoverage(code:any){
  this.coverageInventoryService.getCoverageByCode(code).subscribe((data:CustomResponse) => {
    this.coverage = data.response ; 
    console.log(this.coverage,"that's the coverage ?");
    this.CoverageEditForm.patchValue({
      label : this.coverage.label,
      description : this.coverage.description ,
      code: this.coverage.code,
      //plafon:this.coverage.plafon,
      unite:this.coverage.unite
    })
  }); 
  
}

// updateCoverage(){
//   let coverageUpdated = new Coverage(this.CoverageEditForm.value)

//   this.coverageInventoryService.updateCoverage(coverageUpdated,this.coverageID).subscribe(data => {
//     alert("Mise à jour avec succées ! "); 
//     this.returnToList() ; 
//       this.CoverageEditForm.reset(); 
//   })
// }



CoverageUpdate(code:any){
  if (this.CoverageEditForm.valid) {
    const coveragedto: CoverageDto = {
      label: this.CoverageEditForm.get('label').value,
      description: this.CoverageEditForm.get('description').value,
      code: this.CoverageEditForm.get('code').value,
      unite: this.CoverageEditForm.get('unite').value,
    };

  this.coverageInventoryService.updateCoverage(code,coveragedto).subscribe(
     (data) => {
      this.ngOnInit();

     },
     error => {
       console.error("Error updating coverage :", error);
       
     }
   );
 }
}


}
