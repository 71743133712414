<h2 mat-dialog-title>Détails échéancier par unité</h2>
<mat-dialog-content class="mat-typography">
    <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <form action="">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="unitsChoice">Unités</label>
                  <select (change)="chooseUnit($event.target.value)" class="form-control" id="unitsChoice">
                    <option disabled selected>Sélectionner l'unité ...</option>
                    <option *ngFor="let unitnumber of range(1, data.tarification.unitsCount)" value="{{unitnumber}}">{{unitnumber}}</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
  <mat-tab-group *ngIf="unitChoice != 0">
  <div class="col-16" *ngFor="let echeancier of data.echeancierDetails">
   
      <mat-tab label={{echeancier.typeFractionnement}}>
    <table style="display: table;">
      <tr> 
        <td style="display: table-cell; min-width: 80px; font-weight: bold;">Ordre</td>
        <td style="display: table-cell; min-width: 150px; font-weight: bold;">Montant TTC</td>
        <td style="display: table-cell; min-width: 150px; font-weight: bold;">Montant HT</td>
        <td style="display: table-cell; min-width: 150px; font-weight: bold;">Taxes</td>
        <td style="display: table-cell; min-width: 200px; font-weight: bold;">Date début</td>
        <td style="display: table-cell; min-width: 200px; font-weight: bold;">Date fin</td>
        <td style="display: table-cell; min-width: 150px; font-weight: bold;">Supplément</td>
       
      </tr>
      <tr *ngFor="let echeance of echeancier.echeances"> 
        <ng-container *ngFor="let unitDet of echeance.unitsDetails">
        <ng-container *ngIf = "unitDet.unitNumber === unitChoice">
        <td style="display: table-cell; min-width: 80px; font-weight: bold;">{{ echeance.order }}</td>
        <td style="display: table-cell; min-width: 150px; ">{{ unitDet.totalAmountTTC }}</td>
        <td style="display: table-cell; min-width: 150px; ">{{ unitDet.totalAmountHT }}</td>
        <td style="display: table-cell; min-width: 150px; ">{{ unitDet.totalTaxeAmount }}</td>
        <td style="display: table-cell; min-width: 200px; ">{{ echeance.startDate }}</td>
        <td style="display: table-cell; min-width: 200px; ">{{ echeance.endDate }}</td>
        <td style="display: table-cell; min-width: 150px; ">{{ echeance.supplement }}</td>
        <td style="display: table-cell; min-width: 130px;">
          <div class="hint-icon">
            <div class="hint-content">
              <div style="display: flex;justify-content: center;align-items: center;">
                <label style="font-weight: bold;">Détails des taxes</label>
              </div>
              <table style="display: table;">
                <tr >
                  <td style="display: table-cell; min-width: 150px; font-weight: bold;">Taxe</td>
                  <td style="display: table-cell; min-width: 150px; font-weight: bold;">Valeur</td>
                </tr>
                <tr *ngFor="let taxeDetails of unitDet.taxeDetails | keyvalue">
                    <td>{{ taxeDetails.key }}</td>
                    <td>{{ taxeDetails.value }}</td>
                    <td *ngIf="taxeDetails.perUnit === true">Par unité</td>
                    <td *ngIf="taxeDetails.perUnit === false">Globale</td> 
                    
                </tr>
              </table>
            </div>
            <div class="icon">
              <i class="fas fa-info-circle"></i>
            </div>
          </div>
        </td>
    </ng-container>
    </ng-container>
      </tr>
      <tr>
        <td><div class="empty-space"></div></td>
      </tr>
    </table>
  </mat-tab>

  </div>
</mat-tab-group> 
  
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button mat-button mat-dialog-close class="modifiertaxebtnannuler">Fermer</button>
</mat-dialog-actions>
