<div id="custom-product-fill">
    <div class="header mb-5">
        <img src="/assets/logopr.png" alt="">
        <h1>Tarificateur universelle</h1>
    </div>

    <div class="">
        <button mat-raised-button color="primary" *ngIf="productId" (click)="productId = null">
          <mat-icon class="mat-18">keyboard_arrow_left</mat-icon>
          Retourner à la liste
        </button>
    </div>
    <div id="company" *ngIf="!productId">
        <!-- <div class="container" style="margin-top: 100px;margin-left:0px;"> -->
        <h1>Liste des compagnies</h1>
        <div class="row">
            <ng-container *ngFor="let c of companyList">
                <div class="col-lg-3 col-sm-6 col-xs-12">
                    <div class="card" style="width: 18rem;">
                        <div class="card-body" style="display: flex;justify-content: center;flex-direction: column;">
                            <img src="/assets/0.jpeg" style="width: -moz-available;" alt="">
                            <h5 class="card-title">{{c.label}}</h5>
                            <p class="card-text">{{c.description}}</p>
                            <button (click)="productId = c.id" class="modifiertaxebtn"><mat-icon>euro</mat-icon> Tarification</button>
                        </div>
                    </div>
                </div>
                <div class="col-1"></div>
            </ng-container>
            <mat-progress-bar *ngIf="loadingCompanies" mode="indeterminate"></mat-progress-bar>
        </div>
        <!-- </div> -->
    </div>

    <app-product-fill *ngIf="productId" [productId]="productId"></app-product-fill>
</div>