<!-- <div class="container" style="margin-top: 100px;margin-left: 30px;    overflow-x: hidden;"> -->
<!-- <div class="headPage" > -->
<div class="row">
    <div class="col-lg-9 col-sm-6 col-xs-12">
        <h1>Liste des compagnies</h1>
    </div>
    <div class="col-lg-3 col-sm-6 col-xs-12 ">
        <h1 class="pathing">Compagnies</h1>
    </div>
</div>
<!-- </div> -->
<!-- <div class="row" >
    <div class="col" >
      <button class="btn btn-success" data-toggle="modal" data-target="#addCompanyModal" >    
        <i class="fa fa-plus"></i>
        Ajouter compagnie
      </button>
    </div> -->
<!-- </div> -->
<div class="row">
    <!-- <table class="outlines">
      <tr>
        <td>
      <tr> -->
    <div class="col-lg-9 col-sm-6 col-xs-12">
        <h3>Données des Compagnie :</h3>
    </div>
    <!-- </tr>
      <tr> -->
    <div class="col-lg-3  col-sm-6 col-xs-12">
        <button class="modifiertaxebtn" data-toggle="modal" data-target="#addCompanyModal">  
            <mat-icon>add</mat-icon>
            Ajouter compagnie 

          </button>
    </div>
</div>
<!-- </tr> -->
<!-- </div> -->
<div class="row">
    <div class="col-lg-12 col-sm-6 col-xs-12 ">
        <!-- MAT-TABLE DATATABLE BEGINS -->
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let index = index">{{index+1}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="libelle">
                <th mat-header-cell *matHeaderCellDef> Libelle </th>
                <td mat-cell *matCellDef="let element"> {{element.label}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="nbrproduit">
                <th mat-header-cell *matHeaderCellDef> Nombre PRODUIT </th>
                <td mat-cell *matCellDef="let element"> {{element.products.length}} </td>
            </ng-container>

            <ng-container matColumnDef="proprietes">
                <th mat-header-cell *matHeaderCellDef> Proprietes </th>
                <td mat-cell *matCellDef="let element">
                    <button class="btniconopen" [routerLink]="'/pages/company/'+element.id+'/details'"> <mat-icon>folder</mat-icon>
            </button>

                    <button class="btniconedit" [routerLink]="'/pages/company/'+element.id+'/update'"> <mat-icon>edit</mat-icon>
            </button>

                    <button class="btnicondelete" (click)="openDialog(element.id,element.label)">  <mat-icon>delete</mat-icon>
            </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
        <!-- MAT-TABLE DATATABLE ENDS  -->
    </div>
</div>
<!-- </div> -->
<!-- </td>
  </tr>
  </table> -->

<!-- </div> -->

<!-- Modal -->
<div class="modal fade" id="addCompanyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="background-color: rgba(72,0,114,0.28999999165534973);">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 603px;height: 380px;">
            <div class="modal-header" style="border-color: #a4a4a4;">
                <h5 class="modal-title" id="exampleModalLabel" style="margin-left: 10px">Ajouter une compagnie</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body" style="margin-left: 31px;margin-top: 20px;">
                <form [formGroup]="companyForm">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="label">Libellé</label>
                                <input type="text" class="form-control" id="label" formControlName="label" style="width: 509px;height: 37px;">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="description">Description</label>
                                <textarea class="form-control" id="description" formControlName="description" style="width: 509px;height: 56px;max-height:56px"></textarea>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            <div class="footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                <button type="button" class="btn btn-primary" (click)="addCompany()" data-dismiss="modal">Enregistrer</button>
            </div>

        </div>
    </div>
    <!-- </div> -->

    <!-- Pop Up Delete Confirmation -->
    <ng-template #deleteconfirmationdialog>
        <form (ngSubmit)="delete()">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-12 d-flex justify-content-center">
                    <mat-icon class="warningicon">warning_amber</mat-icon>
                </div>
                <div class="col-lg-12 d-flex justify-content-center">
                    <mat-label>Voulez-vous vraiment supprimer</mat-label>
                </div>
                <div class="col-lg-12 d-flex justify-content-center">
                    <h5>{{selectedItemToDelete.label}}</h5>
                </div>
            </div>
            <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

            <div class="row d-flex justify-content-center">
                <div class="col-lg-6 d-flex justify-content-center">
                    <button class="modifiertaxebtnannuler" type="button" [matDialogClose]>Annuler</button>
                </div>
                <div class="col-lg-6 d-flex justify-content-center">
                    <button class="modifiertaxebtn" mat-dialog-close type="submit">Confirmer</button>
                </div>
            </div>
        </form>
    </ng-template>
    <!-- Pop Up Delete Confirmation -->