<!-- <div class="container" style="margin-top: 100px;margin-left: 0px;"> -->
    <div class="row">
      <div class="col-12">
  
        <div class="row" style="margin-bottom: 30px">
          <div class="col">
            <h1 class="addmcoveragetitle">Modifier un garantie</h1>
          </div>
        </div>
       
      </div>
      <div class="col-12">
        <form [formGroup]="CoverageEditForm" (ngSubmit)="CoverageUpdate(coverageCode)" class="titles">
          <div class="col-xs-12 text-right" style="margin-top: 62px;">
            <button type="button" class="modifiertaxebtndarker" (click)="returnToList()"><mat-icon>arrow_back</mat-icon> Retourner à la liste</button>
            <button class="modifiertaxebtn" type="submit" style="margin-left: 41px;"><mat-icon>save</mat-icon> Enregistrer</button>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">Libellé</label>
                <input type="text" class="form-control" id="label" formControlName="label">
              </div>
            </div>
          </div>
  
          <div class="row align-items-end">
            <div class="col-6">
              <div class="form-group">
                <label for="description">Description</label>
                <textarea row="6" class="form-control" id="description" formControlName="description"></textarea>
              </div>
            </div>
          </div>

       <!--   <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">Plafond</label>
                <input type="text" class="form-control" id="plafon" formControlName="plafon">
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">Code</label>
                <input type="text" class="form-control" id="code" formControlName="code">
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">unite</label>
                <input type="text" class="form-control" id="unite" formControlName="unite">
              </div>
            </div>
          </div>

         
        </form>
      </div>
    </div>
<!-- </div> -->
   
  
  
 