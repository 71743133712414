import { Component, OnInit,Inject } from '@angular/core';
import { Ventilation } from '../../entity/ventilation/ventilation';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  unitChoice: number = 0; 
  constructor(@Inject(MAT_DIALOG_DATA) public data: Ventilation) { }

  ngOnInit(): void {
  }

  chooseUnit(unitNumber: string) {
    this.unitChoice = parseInt(unitNumber, 10);
    console.log(typeof this.unitChoice)
  }

  range(start: number, end: number): number[] {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  

}
