<!-- <div style="margin-top: 100px;margin-left: 0px;overflow-x: hidden;"> -->
  <div class="row"  >
    <div class="col-lg-9 col-sm-6 col-xs-12">
      <h1 class="titlemodel">Liste des modèles</h1>
    </div>
    <div class="col-lg-3 col-sm-6 col-xs-12" >
      <a class="pathing">Modèles</a>
    </div>
  </div>
  <div class="row" >
    <div class="col-lg-9 col-sm-6 col-xs-12">
      <h1>Données de base des modèles</h1>

    </div>
    <div class="col-lg-3 col-sm-6 col-xs-12" style="padding-left: 0px;">
      <button class="modifiertaxebtn" style="margin-bottom: 15px" (click)="addModel()">    
        <mat-icon>add</mat-icon>
        Ajouter un modèle
      </button>
    </div>
  </div>
  <!-- <div class="row" style="margin-left: 0px;">
    <table>
      <tr>
        <td> -->
      <!-- <tr>
        <h1 class="donne-model">Données de base des modèles</h1>
      </tr>
      <tr>
        <h1 class="donne-model-description">DataTable a la plupart des fonctionnalités activées par défaut, donc tout ce que vous avez à faire pour l'utiliser avec vos propres tables est d'appeler la fonction de construction ..</h1>
      </tr> -->
      <!-- <tr>
          <div class="vueD">
            <h1 class="vue">Vues</h1>
            <input class="spinButton" type="number" value="10">
            <h1 class="vue">par ligne</h1>
            <div class="searchI">
            <h1 class="vue">Recherche</h1>
            <input type="text" class="searchinput">
          </div>
          </div>
      </tr> -->
      <div class="row">
        <div class="col-lg-12">
 <!-- MAT-TABLE DATATABLE BEGINS -->
 <mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="index">
    <th mat-header-cell *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let index = index">{{index+1}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="libelle">
    <th mat-header-cell *matHeaderCellDef> Libelle </th>
    <td mat-cell *matCellDef="let element"> {{element.label}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef> Description </th>
    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="nbroptions">
    <th mat-header-cell *matHeaderCellDef> Nombre PRODUIT </th>
    <td mat-cell *matCellDef="let element"> {{element.parameters.length}} </td>
  </ng-container>

  <ng-container matColumnDef="proprietes">
    <th mat-header-cell *matHeaderCellDef> Proprietes </th>
    <td mat-cell *matCellDef="let element">  
      <button class="btniconedit" (click)="editModel(element.id)"> <mat-icon>edit</mat-icon></button>
          <button class="btnicondelete" (click)="openDialog(element.id,element.label)"> <mat-icon>delete</mat-icon></button> </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
<!-- MAT-TABLE DATATABLE ENDS  -->

      
    </div>
   
    <!-- Pop Up Delete Confirmation -->
<ng-template #deleteconfirmationdialog>
  <form  (ngSubmit)="delete()">
    <div class="row d-flex justify-content-center">
    <div class="col-lg-12 d-flex justify-content-center">
      <mat-icon class="warningicon">warning_amber</mat-icon>
    </div> 
    <div class="col-lg-12 d-flex justify-content-center">
    <mat-label>Voulez-vous vraiment supprimer</mat-label>
    </div>
    <div class="col-lg-12 d-flex justify-content-center">
    <h5>{{selectedItemToDelete.label}}</h5>
    </div>
    </div>
    <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-6 d-flex justify-content-center">
      <button class="modifiertaxebtnannuler" type="button" [matDialogClose] >Annuler</button>
      </div>
      <div class="col-lg-6 d-flex justify-content-center">
  <button class="modifiertaxebtn" mat-dialog-close type="submit">Confirmer</button>
</div>
</div>
</form>
</ng-template>
<!-- Pop Up Delete Confirmation -->
