export class User{
  firstName:       string;
  lastName:        string;
  email:           string;
  userName:        string;
  enabled:         boolean;
  dateInscription: string;
  role:            string;
  expirationTime:  number;
}
