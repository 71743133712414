import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/_models/User';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  user: User = new User();

  constructor(private _cookiesService: CookieService) { }

  ngOnInit(): void {
    if(this._cookiesService.check("currentUser")){
      this.user = JSON.parse(this._cookiesService.get("currentUser"));
    }
  }

}
