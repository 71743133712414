<mat-tab-group animationDuration="0ms" mat-align-tabs="center">
    <mat-tab label="Tarification">
        <h1 class="mt-5">Liste des compagnies</h1>
        <div class="row" [ngClass]="{'d-block': productId}">
            <ng-container *ngIf="!productId">
                <ng-container *ngFor="let c of companyList">
                    <div class="col-lg-3 col-sm-6 col-xs-12">
                        <div class="card" style="width: 18rem;">
                            <div class="card-body" style="display: flex;justify-content: center;flex-direction: column;">
                                <img src="/assets/0.jpeg" style="width: -moz-available;" alt="">
                                <h5 class="card-title">{{c.label}}</h5>
                                <p class="card-text">{{c.description}}</p>
                                <button (click)="productId = c.id" class="modifiertaxebtn"><mat-icon>euro</mat-icon> Tarification</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-1"></div>
                </ng-container>
            </ng-container>
            <app-product-fill *ngIf="productId" [productId]="productId"></app-product-fill>
        </div>
        <div class="row my-3">
            <button type="button" *ngIf="productId" (click)="productId = null" class="btn btn-info"><mat-icon>keyboard_arrow_left</mat-icon> Retourner à la liste</button>
        </div>
    </mat-tab>
    <mat-tab label="Détails de tarification">
        <app-tarif-ref></app-tarif-ref>
    </mat-tab>
</mat-tab-group>