import {Option} from './Option';

export class PossibleOption {
  typeInput : any;
  constructor(init?: Partial<PossibleOption>) {
    Object.assign(this, init);
  }
  id:number;
  type:string;
  activated:boolean;
}
