import {PossibleOption} from '../PossibleOption';

export class SelectPossibleValues {
  id:number;
  label:string;
  val:string;
  constructor(label:string,val:string) {
    this.label = label;
    this.val= val;
  }

}

export class SelectOption extends PossibleOption{

  selectPossibleValues:SelectPossibleValues[];
  constructor(init?: Partial<SelectOption>) {
    super();
    super.type="Select"
    Object.assign(this, init);
  }
}

