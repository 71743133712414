<form>
    <div>
        <div class="form-group">
            <label>Libellé</label>
            <input type="text" rows="4" class="form-control" [(ngModel)]="item.label" value="{{item.label}}" name="" [ngModelOptions]="{standalone: true}">
        </div>
        <!-- Hide  Tag
    <div class="form-group">
      <label >Tag</label>
      <div class="row">
        <div class="col-10">
          <input class="form-control" type="text" value="{{item.formControl.label}}" disabled>
        </div>
        <div class="col-2">
          <button (click)="emitItemId()" type="button" data-toggle="modal" data-target="#MainModel"
                  class="btn btn-warning">
            <i class="fa fa-folder-open"></i>
          </button>
        </div>
      </div>
      <input type="hidden" value="{{item.formControl.id}}">
    </div>
  -->
        <div class="form-group" style="display: none">
            <label>Nom de la configuration</label>
            <input class="form-control" type="text">
        </div>
        <div class="form-group" style="display: none">
            <label>Ordre de tracé</label>
            <input class="form-control" type="number">
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea rows="4" class="form-control" [(ngModel)]="item.description" [ngModelOptions]="{standalone: true}"></textarea>
        </div>
        <div class="row">
            <div class="col-4 text-center">
                <div class="form-group">
                    <label>Axe de tarification</label>
                    <input class="form-control" type="checkbox" [(ngModel)]="item.requiredTarif" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-3 text-center">
                <div class="form-group">
                    <label>Adhérence requise</label>
                    <input class="form-control" type="checkbox" [(ngModel)]="item.requiredAdhesion" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-5 text-center">
                <div class="form-group">
                    <label>Remplissage automatique</label>
                    <input class="form-control" type="checkbox" [(ngModel)]="item.autofill" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
        </div>

        <!-- OPTIONS -->
        <!-- Select + Radio -->
        <div class="row" *ngIf="item.possibleOption.type=='Select'">
            <div class="col-12">
                <form [formGroup]="possibleOptionForm">
                    <div class="table-responsive col-12 form-group">
                        <table class="grid table table-bordered table-sortable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Libellé</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let i of item.possibleOption.selectPossibleValues;let index=index">
                                    <td data-id="11">{{index + 1}}</td>
                                    <td><input type="text" value="{{i.label}}" class="form-control" disabled></td>

                                    <td>
                                        <button class="btn btn-danger">
                    <i class="fa fa-trash" (click)="deletePossibleOption(item,index)"></i>
                  </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-id="11">{{ item.possibleOption.selectPossibleValues.length + 1}}</td>
                                    <td><input type="text" value="" class="form-control" formControlName="label">
                                    </td>
                                    <td>
                                        <button type="button" (click)="addPossibleOpt(item)" class="btn btn-primary">
                    <i class="fa fa-plus"></i>
                  </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
        <div class="row" *ngIf=" item.possibleOption.typeInput=='radio'">
            <div class="col-12">
                <form [formGroup]="possibleOptionForm">
                    <div class="table-responsive col-12 form-group">
                        <table class="grid table table-bordered table-sortable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Libellé</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let i of item.possibleOption.radioPossibleValues;let index=index">
                                    <td data-id="11">{{index + 1}}</td>
                                    <td><input type="text" value="{{i.label}}" class="form-control" disabled></td>

                                    <td>
                                        <button class="deleteButton" (click)="deletePossibleOption(item,index)">
                    <mat-icon svgIcon="delete" class="deleteIcon" ></mat-icon>
                  </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-id="11">{{ item.possibleOption.radioPossibleValues.length + 1}}</td>
                                    <td><input type="text" value="" class="form-control" formControlName="label">
                                    </td>
                                    <td>
                                        <button type="button" (click)="addPossibleOpt(item)" class="addButton">
                    <i class="fa fa-plus" style="width: 15px;height: 15px;"></i>
                  </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
        <!-- Category -->
        <div class="row" *ngIf="item.possibleOption.type=='Category'">
            <form [formGroup]="possibleOptionForm">
                <div class="table-responsive col-md-12 form-group">
                    <table class="grid table table-bordered table-sortable">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Libellé</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let i of item.possibleOption.possibleValues;let index=index">
                                <td data-id="11">{{index + 1}}</td>
                                <td><input type="text" value="{{i.label}}" class="form-control" disabled></td>
                                <td><textarea rows="4" value="{{i.val}}" class="form-control" disabled></textarea>
                                </td>
                                <td>
                                    <button class="btn btn-danger">
                  <i class="fa fa-trash" (click)="deleteCategoryOption(item,index)"></i>
                </button>
                                </td>
                            </tr>
                            <tr>
                                <td data-id="11">{{item.possibleOption.possibleValues.length + 1}}</td>
                                <td><input type="text" value="" class="form-control" formControlName="label"></td>
                                <td><textarea rows="4" value="" class="form-control" formControlName="catVal"></textarea></td>
                                <td>
                                    <button type="button" (click)="addPossibleOptCategory(item)" class="btn btn-primary">
                  <i class="fa fa-plus"></i>
                </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
        <!-- Text + TextArea
    <form [formGroup]="regexForm" *ngIf="typeOptionForm.value['typeOption'] == 'TextAreaOption' || typeOptionForm.value['typeOption'] == 'TextInput' ">
      <div class="form-group row">
        <label for="regexInput" class="col-sm-2 col-form-label">Regex:</label>
        <div class="col-sm-10">
          <input id="regexInput" class="form-control" formControlName="regex" type="text">
        </div>
      </div>
    </form>-->
        <form [formGroup]="possibleOptionForm" *ngIf="item.possibleOption.typeInput=='number'">
            <div class="form-group row">
                <label for="regexInput" class="col-sm-2 col-form-label">Min:</label>
                <div class="col-sm-10">
                    <input class="form-control" formControlName="min" type="number" (change)="getMinMax($event)">
                </div>
            </div>
            <div class="form-group row">
                <label for="regexInput" class="col-sm-2 col-form-label">Max:</label>
                <div class="col-sm-10">
                    <input class="form-control" formControlName="max" type="number" (change)="getMinMax($event)">
                </div>
            </div>
            <!-- Hide Regex & Step
      <div class="form-group row">
        <label for="regexInput" class="col-sm-2 col-form-label">Step:</label>
        <div class="col-sm-10">
          <input  class="form-control" formControlName="" type="number">
        </div>
      </div>
      <div class="form-group row">
        <label for="regexInput" class="col-sm-2 col-form-label">Regex:</label>
        <div class="col-sm-10">
          <input  class="form-control" formControlName="regex" type="text">
        </div>
      </div>
      -->
        </form>
        <!-- Range
    <form [formGroup]="rangeOptionForm" *ngIf="typeOptionForm.value['typeOption'] == 'RangeInput'  ">
      <div class="form-group row" >
        <label for="rangeMin" class="col-sm-2 col-form-label">Min:</label>
        <div class="col-sm-10">
          <input id="rangeMin" class="form-control" formControlName="min" type="number">
        </div>
        <br/>
        <label style="margin-top: 15px;" for="rangeMax" class="col-sm-2 col-form-label">Max:</label>
        <div style="margin-top: 15px;" class="col-sm-10">
          <input id="rangeMax" class="form-control" formControlName="max" type="number">
        </div>
      </div>
      <div class="row">
        <form [formGroup]="possibleOptionForm">
          <div class="table-responsive col-md-12 form-group">
            <table id="sort4" class="grid table table-bordered table-sortable">
              <thead>
              <tr>
                <th>Range</th>
                <th>Label</th>
                <th>min</th>
                <th>max</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let i of possibleVals;let index=index">
                <td data-id="11">{{index + 1}}</td>
                <td><input type="text" value="{{i.label}}" class="form-control" disabled></td>
                <td><input type="text" value="{{i.min}}" class="form-control" disabled></td>
                <td><input type="text" value="{{i.max}}" class="form-control" disabled></td>
                <td>
                  <button class="btn btn-danger">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td data-id="11">{{nbPossibleOpts}}</td>
                <td><input type="text" value="" class="form-control" formControlName="label" ></td>
                <td><input type="number" value="" class="form-control" formControlName="min"></td>
                <td><input type="number" value="" class="form-control" formControlName="max"></td>
                <td>
                  <button type="button" (click)="addPossibleOpt(item)" class="btn btn-primary">
                    <i class="fa fa-plus"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </form>-->
        <!-- End OPTIONS -->
        <div *ngIf="listName=='insured'" class="form-group">
            <label>Propriété</label>
            <select [(ngModel)]="item.typeProperty" [ngModelOptions]="{standalone: true}" class="form-control" id="typeOptionSelect">
        <option value="" [defaultSelected]>Choisir ...</option>
        <option value="1">Adhérant</option>
        <option value="2">Produit</option>
      </select>
        </div>
    </div>
    <div class="col-12">
        <div class="form-group">
            <label>Step</label>
            <input type="number" class="form-control" [(ngModel)]="item.stepOrder" [ngModelOptions]="{standalone: true}">
        </div>
    </div>
</form>