import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css']
})

export class CheckBoxComponent implements OnInit {
  @Input() useAsMainInsured:boolean;
  @Output() onChangeStatus:EventEmitter<any>=new EventEmitter();
  randomstr:string;
  checkbox1Id:string;
  checkbox2Id:string;

  makeRandom() {
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopkrstuvwxyz";
    const lengthOfCode = 12;
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


  constructor() { }

  ngOnInit(): void {
    this.randomstr=this.makeRandom();
    this.checkbox1Id=this.randomstr+"1";
    this.checkbox2Id=this.randomstr+"2";
  }

  IncMainInsured() {
    this.onChangeStatus.emit(1);
    $("#"+this.checkbox1Id).prop("checked", this.useAsMainInsured);
    $("#"+this.checkbox2Id).prop("checked", this.useAsMainInsured);
  }

  DiscMainInsured() {
    this.onChangeStatus.emit(-1);
    $("#"+this.checkbox1Id).prop("checked", this.useAsMainInsured);
    $("#"+this.checkbox2Id).prop("checked", this.useAsMainInsured);
  }


}
