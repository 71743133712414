import { Component, OnInit } from '@angular/core';
import {ProductFull} from '../../Company-Engine/entity/ProductFull';
import {CompanyInventoryService} from '../../../services/company-inventory.service';
import {Company} from '../entity/company';
import {ActivatedRoute} from '@angular/router';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { Coverage } from '../../Coverage-Inventory/entity/Coverage';
import { TaxesService } from 'src/app/services/taxes.service';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import { ProductDto } from '../../Taxes/entities/dto/dtos';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  product:ProductFull;
  productId:number;
  coveragesList : Coverage [] = []; 
  garanties2 : any [] = []; 
  taxeproduct: ProductDto[]=[];

  constructor(private taxesService:TaxesService,private companyInventoryService:CompanyInventoryService,private routerURL: ActivatedRoute,private coverageInventoryService:CoverageInventoryService ) { }


  ngOnInit(): void {
    this.routerURL.params.subscribe(params=>{
      this.companyInventoryService.getProductById(params['id']).subscribe((data:ProductFull)=>{
        this.product=data;
        this.productId=params['id'];
        this.getCoverage(params['id']);
        this.getProductTaxesbyId(this.productId)

      })
    });
     

    
  }

  getCoverage(id:any){
    this.coverageInventoryService.getCoverageById(id).subscribe((data:CustomResponse) => {
      this.garanties2 = data.response ; 
      console.log (this.garanties2)
    }); 

  }




  getProductTaxesbyId(id:number){
    this.taxesService.getProductTaxesbyId(id).subscribe((dataproduct:CustomResponse)=>{
      this.taxeproduct=dataproduct.response;
    },
    (error) => {
      console.error("An error occurred whehn getting taxes:", error);  
    });
  }


}
