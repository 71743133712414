<div *ngIf="!useAsMainInsured" (click)="IncMainInsured()" >
  <input type="checkbox" id="{{checkbox1Id}}" [checked]="useAsMainInsured" />
  <span></span>
</div>

<div *ngIf="useAsMainInsured" (click)="DiscMainInsured()" >
  <input type="checkbox" id="{{checkbox2Id}}" [checked]="useAsMainInsured" />
  <span></span>
</div>

