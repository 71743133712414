import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {CompanyEngineService} from '../../../services/company-engine.service';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../entity/company';
import {ProductFull} from '../entity/ProductFull';
import {InsuredModelContainerFull} from '../entity/InsuredModelContainerFull';
import {FormMultiStep, FraisSupp, Insured, PricingEntity} from './entity/FormStep';
import {PricingReturn} from './entity/PricingReturn';
import {ContractServiceService} from '../../../services/contract-service.service';
import {RulesEngineService} from '../../../services/rules-engine.service';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { Coverage } from '../../Coverage-Inventory/entity/Coverage';
import { CoverageValue } from '../../Company-Inventory/entity/coveragesValue';
import { TotalCoverages } from './entity/TotalCoverages';
import { value } from '../../Model-Inventory/form-builder/global.model';
import { CustomResponse } from './entity/CustomResponse';
import { EcheancierDetails, Ventilation } from './entity/ventilation/ventilation';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { DialogComponent } from './dialog/dialog/dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-product-fill',
  templateUrl: './product-fill.component.html',
  styleUrls: ['./product-fill.component.css']
})
export class ProductFillComponent implements OnInit {
  @Input() productId: string;
  tarifMode: number = 1;
  showDetails : boolean = false ;
  company:Company=new Company();
  selectedProduct:ProductFull=null;
  priceTableId:number=null;
  insuredModelsContainers:InsuredModelContainerFull[]=[]
  insuredSizes:number[]=[];
  steps:number=0;
  dinamicForm: PricingEntity=new PricingEntity();
  selectedInsuredContainerIndex=null;
  price:PricingReturn=new PricingReturn();

  ventilation : Ventilation = new Ventilation() ;


  productCoverages : Coverage [] = [] ;
  coveragesValues : CoverageValue [] = [] ;
  totalCoverages : TotalCoverages [] = [] ;
  totalCoverage = new TotalCoverages ()  ;
  selectedCoverages : String[]=[];


  coveragesValues2: any [] = []
  coveragesValuesGlobal: any[]=[];
  coveragesValuesPerUnit: any[]=[];
  @Output() CoveragesEmitter = new EventEmitter<any>();



  constructor(public dialog: MatDialog,private contractService:ContractServiceService,private companyEngineService:CompanyEngineService,private route: ActivatedRoute,private pricingEngineService:RulesEngineService,private coverageInventoryService: CoverageInventoryService,private clipboard: Clipboard) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    if(!id) id = this.productId;
    this.companyEngineService.getCompanyById(Number(id)).subscribe((data:Company)=> {
      this.company = data;
      this.dinamicForm.companyId=this.company.id;
    })
  }

  openDialog(data : Ventilation) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  selectProductById(id:number){
    this.totalCoverages =[];
    this.insuredSizes=[];
    let selected= false;
    for(let prod of this.company.products){
      if(prod.id == id){
        this.selectedProduct=prod;
        this.insuredModelsContainers=prod.insuredModelContainers;
        this.insuredModelsContainers.forEach(x=>{
          this.insuredSizes.push(1);
          this.dinamicForm.form.push(new Insured(null));
        })
        this.insuredModelsContainers.sort((a,b)=> a.step - b.step);
        this.steps=prod.steps;
        this.dinamicForm.productId=this.selectedProduct.id;
        this.dinamicForm.tablePriceId=null;
        this.dinamicForm.coverages=null;


      //   this.coverageValue.forEach((element , index) => {
      //     this.dinamicForm.coverages[index] = element;
      // })

        selected=true;
      }
    }
    if(!selected) this.selectedProduct=null;

  }





  onCheckboxChange(selectedCoveragesemit:any[]) {
    if (!selectedCoveragesemit) {
      selectedCoveragesemit = [] ;
    }
    this.dinamicForm.coverages=selectedCoveragesemit;
  }

  addFraisSupplementaires(fraisAndValues){
    this.dinamicForm.fraisSupp=fraisAndValues;

  }

  changeDateEffet(dateEffet){
    this.dinamicForm.effectiveDate=dateEffet;

  }
  changeDateSous(dateSous){
    this.dinamicForm.creationDate=dateSous;
  }


    getCoveragesByProductId(id:any){
     this.coverageInventoryService.getProductCoverages(id).subscribe((data:CustomResponse) => {
      this.coveragesValues2 = data.response ;
      this.sortCoveragesArrays();
    });
  }


  isDataLoaded: boolean = false;

   async sortCoveragesArrays() {
    try{
    this.coveragesValuesGlobal = []
    this.coveragesValuesPerUnit = []
    this.coveragesValuesGlobal = this.coveragesValues2.filter(cv => !cv.perUnit);
    this.coveragesValuesPerUnit = this.coveragesValues2.filter(cv => cv.perUnit);
    this.isDataLoaded = true;}
    catch(error) {console.error("sortCoveragesArrays",error)}

}



  changeProduct(id) {
    this.getCoveragesByProductId(id);
    this.selectProductById(id);
  }

  onChangePriceTable(id) {
    this.priceTableId=+id;
    this.dinamicForm.tablePriceId=this.priceTableId;
  }

  handleChange($event: Insured[]) {
    let start=0;
    for(let i=0;i<this.selectedInsuredContainerIndex;i++)
      start+=this.insuredSizes[i];
    let size=this.insuredSizes[this.selectedInsuredContainerIndex];
    this.dinamicForm.form.splice(start,size);
    $event.forEach((element, index)=>{
      this.dinamicForm.form.splice(start+index,0,element);
    })
    this.insuredSizes[this.selectedInsuredContainerIndex]=$event.length;
    this.pricingEngineService.getPrice(this.dinamicForm).subscribe((data:CustomResponse)=>{
      this.ventilation=data.response ;
      this.showDetails = true ;
      this.scrollToDetails()
    })

  }

  scrollToDetails() {
    const element = document.getElementById('scrollToDetails');
    element.scrollIntoView()
    const offset = 1160;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  setInsuredContainerIndex($event){
    this.selectedInsuredContainerIndex=$event;
  }

  onSubmit() {
    this.contractService.addContract(this.dinamicForm).subscribe(data=>{console.log(data);})
  }

  setTarifMode(mode:number){
    this.tarifMode = mode ;
  }

  copyToClipboard(): void {
    const jsonData = JSON.stringify(this.ventilation, null, 2);
    this.clipboard.copy(jsonData); 
  }
}
