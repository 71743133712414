import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../_models/User';
import { AuthService } from '../authentification/_service/auth.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [
    trigger('sidenavAnimation', [
      state('open', style({ width: '310px' })),
      state('reduced', style({ width: '70px' })),
      transition('open <=> reduced', animate('30ms ease-in-out'))
    ])
  ]
})
export class PagesComponent implements OnInit {

  user: User = new User();
  constructor(private _cookiesService: CookieService,
    private _authService: AuthService,
    private _router: Router) { }

  ngOnInit(): void {
    document.getElementById('sidenav-content').style.marginLeft = '0px';
    document.getElementById('toggleSideNav').style.display = 'true';
    document.getElementById('sideNavBar').style.display = 'true';
    if(this._cookiesService.check('currentUser')) {
      this.user = JSON.parse(this._cookiesService.get('currentUser'));
      if(this.user.role == "TESTER"){
        document.getElementById('sidenav-content').style.marginLeft = '0px';
        document.getElementById('toggleSideNav').style.display = 'none';
        document.getElementById('sideNavBar').style.display = 'none';
      }
    }
  }

  signOut(): void{
    this._authService.logout()
  }

  generateAvatarUrl(): string {
    const initials = `${this.user.firstName.charAt(0)}${this.user.lastName.charAt(0)}`;
    // You can use an avatar-generating service or a library here
    // For simplicity, using a placeholder URL for demonstration purposes
    return `https://ui-avatars.com/api/?name=${initials}&size=40&background=random&color=fff`;
  }

  currentUrl: string;
  router: any;

  getCurrentUrl(): string {
    return this.router.url;
  }
  // ngOnInit() {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       this.currentUrl = event.url;
  //     }
  //   });
  // }

  // isSidenavOpened = true;
  // handleSidenavAnimation(event: AnimationEvent) {
  //   if (event.phaseName === 'done') {
  //     this.isSidenavOpened = true;
  //   }
  // }
  isSidenavReduced = false;
contentMargin=310;
contentDisplay="block";




  toggleSidenavWidth() {
    this.isSidenavReduced = !this.isSidenavReduced;
    if(this.isSidenavReduced){
      this.contentMargin=70;
    this.contentDisplay="none"}
      else{
        this.contentMargin=310;
        this.contentDisplay="block"
      }
    }
    reduceSidenavWidth() {
      this.contentMargin=30;

    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth < 900) {
          this.isSidenavReduced = true;
          this.reduceSidenavWidth();

        }
    }


    closeSidenavAfterDelay() {
      this.toggleSidenavWidth()

      setTimeout(() => {
        this.isSidenavReduced = true;
        this.toggleSidenavWidth()

      }, 7000); // Adjust the delay time as desired (in milliseconds)
    }
}
