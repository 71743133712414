import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { environment } from '../../../../environments/environment';
import { ModelInventoryService } from '../../../services/model-inventory.service';
import { Coverage } from '../../Coverage-Inventory/entity/Coverage';
import { FormControl } from '../entity/FormControl';
import { InsuredModel } from '../entity/InsuredModel';
import { Option } from '../entity/Option';
import { CategoryOption, CategoryPossibleValues } from '../entity/possibleOpt/CategoryOption';
import {
  CheckBoxInput,
  DateInput,
  NumberInput,
  RadioInput,
  RadioPossibleValue,
  RangeInput,
  RangePossibleValues,
  TextInput,
} from '../entity/possibleOpt/InputOption';
import { SelectOption, SelectPossibleValues } from '../entity/possibleOpt/SelectOption';
import { TextAreaOption } from '../entity/possibleOpt/TextAreaOption';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Productline } from '../../Coverage-Inventory/entity/productLine';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


// Taxes

import { TaxesService } from 'src/app/services/taxes.service';
import { Taxe } from '../../Taxes/entities/models/models';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import { ModelDto, TaxeDto } from '../../Taxes/entities/dto/dtos';
import { CoverageDto, ProductlineDto } from '../../Coverage-Inventory/coverages-list/entities/dtos/coveragedtos';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {

  itemsList: Option[]=[];
  toggle=false;

  globalOptionsList: Option[]=[];
  insuredOptionsList: Option[]=[];

  formControlList: FormControl[] = [];
  InsuredModelForm: FormGroup;
  formControl: FormGroup;
  option: Option;
  optionsList: Option[] = [];

  searchStringFormControl: string;

  formControleTargetOrd:number=0;
  formControleTargetList:string="";

  coveragesList = [];
  selectedCoverages : any [] = [];
  selectedCoveragesFinal : Coverage [] = [];
  selectedCoveragesCodes : any [] = [];
  dropdownSettings :IDropdownSettings = {} ; 
  dropdownSettingsCoverages :IDropdownSettings = {} ; 
  dropdownSettingsTaxe :IDropdownSettings = {} ; 
  // coverage:Coverage ; 
  coverage:any ; 
  productLine = new ProductlineDto() ; 
  // Taxes 
  taxe : Taxe[]=[] ;
  selectedTaxIds:number[] = [];  
  selectedTaxes: Taxe[] = [];

  constructor(private taxesService:TaxesService,private formBuilder: FormBuilder, private route:Router,private modelInventoryService: ModelInventoryService,private coverageInventoryService:CoverageInventoryService,private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer) {
   }


// Taxes 

getTax(){
  this.taxesService.getAllTaxe().subscribe((data:CustomResponse)=>{
    this.taxe= data.response ;
    this.dropdownSettingsTaxe = {
      singleSelection: false,
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: this.taxe.length,
      allowSearchFilter: true,
      showSelectedItemsAtTop: true
    }; 
  })
  
} 
taxeinfo = {
  idModel:0,
  taxeid:[],
};
// Taxes end

  ngOnInit(): void {
    this.initOptionsList()
    this.initForm();
    this.UpdateFormControleList();
    this.getAll();
    this.getCoverage();
this.getTax();

   
  }


 


  
  private initOptionsList(){
    this.itemsList=[]

    let option = new Option();
    option.label="Text";
    option.possibleOption = new TextInput();
    this.itemsList.push(option);

    option = new Option();
    option.label="Radio";
    let radioInput = new RadioInput();
    radioInput.radioPossibleValues = [];
    radioInput.radioPossibleValues.push(new RadioPossibleValue("label_1", this.GenerateRandomStr()));
    option.possibleOption = radioInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="Category";
    let categoryInput = new CategoryOption();
    categoryInput.possibleValues = [];
    categoryInput.possibleValues.push(new CategoryPossibleValues("label_1","choice1\nchoice2\nchoice3"));
    option.possibleOption = categoryInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="Multi-Options Select";
    let selectOption = new SelectOption();
    selectOption.selectPossibleValues = [];
    selectOption.selectPossibleValues.push(new SelectPossibleValues("label_1", this.GenerateRandomStr()));
    option.possibleOption = selectOption;
    this.itemsList.push(option);

    option = new Option();
    option.label="Text Area";
    let textAreaOption = new TextAreaOption();
    textAreaOption.setRegex("");
    option.possibleOption = textAreaOption;
    this.itemsList.push(option);

    option = new Option();
    option.label="Date";
    option.possibleOption = new DateInput();
    this.itemsList.push(option);

    option = new Option();
    option.label="Range";
    let rangeInput = new RangeInput();
    rangeInput.min = 0;
    rangeInput.max = 0;
    rangeInput.ranges=[];
    rangeInput.ranges.push(new RangePossibleValues(0, 15,'label_1','<15'));
    option.possibleOption = rangeInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="Number";
    let numberInput = new NumberInput();
    numberInput.setmin(0);
    numberInput.setmax(0);
    numberInput.setstep(0);
    numberInput.setRegex("");
    option.possibleOption = numberInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="CheckBox";
    option.possibleOption = new CheckBoxInput();
    this.itemsList.push(option);


  }

 

  private initForm() {

    this.InsuredModelForm = this.formBuilder.group({
      label: '',
      description: ''
    });
    this.formControl = this.formBuilder.group({
      label:'',
      uniqueIdentifier:''
    })
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      if(event.previousContainer.id=="cdk-drop-list-init-0"){
        this.initOptionsList();
      }

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      if(event.container.id=="cdk-drop-list-global-0"){
        event.container.data[event.currentIndex].typeProperty=3;
      }else{
        event.container.data[event.currentIndex].typeProperty=2;
      }

    }

    for (let i = 0; i < event.container.data.length; i++) {
      event.container.data[i].ord=i;
    }
    for (let i = 0; i < event.previousContainer.data.length; i++) {
      event.previousContainer.data[i].ord=i;
    }

  }

  startDrag() {

  }

  deleteInsuredOptionsList(i: number) {
    this.insuredOptionsList.splice(i,1)
  }

  deleteGlobalOptionsList(i: number) {
    this.globalOptionsList.splice(i,1)
  }


  noReturnPredicate(){
    return false;
  }

  seeTaxeIDS(){
    console.log("this is taxeids :",this.taxeinfo.idModel)
    console.log("this is taxeinfo.taxeid:" ,this.taxeinfo.taxeid)
  }


  saveInsuredModel() {
    let im = new InsuredModel(this.InsuredModelForm.value);

    this.globalOptionsList.forEach(element=>{
      im.parameters.push(element);
    });

    this.insuredOptionsList.forEach(element=>{
      im.parameters.push(element);
    });
    im.parameters.forEach(parameter=>{
      parameter.label = parameter.label.trim()
      parameter.formControl.label = parameter.label
    })
    im.parameters.forEach(parameter=>{
      var re = / /gi; 
      parameter.formControl.uniqueIdentifier = this.field_name_normalizer(parameter.label.replace(re, "_"));
    })



    this.selectedTaxes.forEach((element,index) => {
      this.selectedTaxIds[index]=element.id

    });


    
    
    

    
    this.modelInventoryService.addInsuredModel(im).subscribe(data=>{
      this.addCoveragesToProductLine(this.selectedCoveragesCodes,data.id)
      this.addProductLineWithCoverages(data);
      const modelId = data.id;
      const modelDto: ModelDto = {
        idModel: modelId,
         taxesIds:this.selectedTaxIds
       };

       if (!modelDto || modelDto.taxesIds.length === 0) {
        console.log("empty taxes in model dto won't hit api" )
        }

        else {
       this.taxesService.addTaxeToModel(modelDto).subscribe(
         response => {
           console.log('POST request successful:', response);
         },
         error => {
           console.error('Error in POST request:', error);
           alert("erreur lors de l'ajout des taxes au model")
         }
       );
        }
       alert("Modèle ajouté avec succès");
      this.route.navigate(["/pages/"+environment.insuredModelURL]);
        },
      error => {
      alert("Veuillez revoir votre formulaire, vous avez peut-être fait une configuration manquée");
      });

  }

 

  retour() {
    this.route.navigate(["/pages/"+environment.insuredModelURL])
  }

  setOptionOrder() {

  }

  GenerateRandomStr(){
    var chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var length=18;
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  setFormControlUniqueIdentifier() {

  }

  saveFormControl() {
    let fc = new FormControl(this.formControl.value);
    this.modelInventoryService.saveFormControl(fc).subscribe(data=>{
      this.UpdateFormControleList();
      this.formControl.reset();
    });
  }

  selectFormControl(id: number) {
    for(let formControl of this.formControlList){
      if(formControl.id==id){
        if(this.formControleTargetList=="global"){
          this.globalOptionsList[this.formControleTargetOrd].formControl=formControl;
          this.fillConfigName("global");
        }else if(this.formControleTargetList=="insured")
        {
          this.insuredOptionsList[this.formControleTargetOrd].formControl=formControl;
          this.fillConfigName("insured");
        }

      }
    }
  }

  fillConfigName(label:string) {
    if(label=="global"){
      this.globalOptionsList[this.formControleTargetOrd].configName=this.field_name_normalizer(this.globalOptionsList[this.formControleTargetOrd].label);
    }else{
      this.insuredOptionsList[this.formControleTargetOrd].configName=this.field_name_normalizer(this.insuredOptionsList[this.formControleTargetOrd].label);
    }
  }

  field_name_normalizer(str:string){
    str.replace("\s","_")
    str.replace("^(\d)+","_\\1")
    return str+'_'+this.GenerateRandomStr();
  }

  UpdateFormControleList() {
    this.modelInventoryService.getFormControlList().subscribe((data:FormControl[])=>{
      this.formControlList=data;
    })
  }


  FormControlIdSetter($event: number) {
    this.formControleTargetOrd=$event;
  }

  setListNameFormControlSetter($event: string) {
    this.formControleTargetList=$event;
  }

/*---------------------------------------------------CoveragesSettings----------------------------------------------------------------*/

  onItemSelect(item:any) {
    console.log("selectedcoverageslist",this.selectedCoverages)
    this.selectedCoverages.forEach((element,index) => {
      this.selectedCoveragesCodes[index] = element.code
    });
    console.log("coverage based on code ?",this.selectedCoveragesCodes) 
  }

  onItemDeselect(item: any) {
    this.selectedCoveragesFinal.forEach((element,index)=>{
      if(element.id == item.id) this.selectedCoveragesFinal.splice(index,1); 
    });
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  getCoverage(){
    this.coverageInventoryService.getAllCoverages().subscribe((data:CustomResponse) => {
      this.coverage = data.response ; 
      console.log("coverage",this.coverage)
      this.selectedCoveragesFinal.push(this.coverage);
      this.coverage= null ;  
    });
    
    this.selectedCoverages.forEach((element,index) => {
      this.selectedCoveragesCodes[index]=element.code
      console.log("selected coverage codes 2 :",this.selectedCoveragesCodes)
      
    });

  } 
  // getCoverage(code:any){
  //   this.coverageInventoryService.getCoverageByCode(code).subscribe((data:CustomResponse) => {
  //     this.coverage = data.response ; 
  //     console.log("coverage based on code ?",this.coverage)
  //     this.selectedCoveragesFinal.push(this.coverage);
  //     this.coverage= null ;  
  //   }); 
  // }

  addCoveragesToProductLine(coverages : Coverage[],id:any){
    
    // console.log("selected coverage codes",this.selectedCoveragesCodes);
    console.log("selected coverage codes",coverages);
    console.log("selected coverage id",id);
      // this.coverageInventoryService.addCoveragesToProductLine(this.selectedCoveragesCodes,id).subscribe(data => {});
  }

  addProductLineWithCoverages(im : any){
  
    this.productLine.coveragesCodes = this.selectedCoveragesCodes; 
    this.productLine.productLineId = im.id ; 

    this.coverageInventoryService.addProductLine(this.productLine).subscribe(data =>{
    });

  }

  getAll(){
    this.coverageInventoryService.getAllCoverages().subscribe((data:CustomResponse)=>{
      this.coveragesList = data.response;
      console.log("this is coverages list", this.coveragesList)
      this.dropdownSettingsCoverages = {
        singleSelection: false,
        idField:'code',
        textField: 'label',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: this.coveragesList.length,
        allowSearchFilter: true,
        showSelectedItemsAtTop: true
      }; 
    })
  }



      onItemSelectTaxe(item: Taxe) {
        this.getTax(); 
      }

}
