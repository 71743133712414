import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TaxesService } from 'src/app/services/taxes.service';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import { TaxeCategory } from '../entities/models/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TaxeCategoryDto } from '../entities/dto/dtos';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';





@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  taxeCategorieForm:FormGroup
  taxeCategories : TaxeCategory [] = [] ;
  dataSource : TaxeCategory[]=[]; 
  @ViewChild('dialogContentTemplate') dialogContentTemplate: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  datasource: MatTableDataSource<any>= new MatTableDataSource();


  constructor(private taxesService:TaxesService,private formBuilder: FormBuilder,public dialog: MatDialog,private http: HttpClient) { 
    this.taxeCategorieForm= this.formBuilder.group(
      {
        label:'',
      }
    )
  }
  displayedColumns: string[] = ['position', 'name', 'weight','statustext'];



  ngOnInit(): void {
    this.getTaxCategory();

  }

getTaxCategory(){
  this.taxesService.getAllTaxeCategory().subscribe((data:CustomResponse)=>{
    this.taxeCategories = data.response ;
    console.log("these are tax categories",this.taxeCategories);
    this.datasource.data=this.taxeCategories;
    this.datasource.paginator = this.paginator

  })
}
  setStatus(id:number){
   this.taxesService.TaxeCategoryStatus(id).subscribe(
      data => {
      },
      error => {
        console.error("Error changing status:", error);
      }
    );
console.log("post IDentifier :",this.taxeCategories);
  }

  addTaxeCategorie() {
    let taxeCategoryDto:TaxeCategoryDto = new TaxeCategoryDto(this.taxeCategorieForm.value['label']);
    this.taxesService.addTaxeCategory(taxeCategoryDto).subscribe(data=>{
      this.ngOnInit();
      this.taxeCategorieForm.reset();
    })
  }

  submitTaxeCategory() {
    if (this.taxeCategorieForm.invalid) {
      return console.log("didn't add taxe");
    }
    console.log(this.taxeCategorieForm);
   


    const taxeCategoryDto = this.taxeCategorieForm.value as TaxeCategoryDto;
    console.log(taxeCategoryDto);


    this.taxesService.addTaxeCategory(taxeCategoryDto).subscribe(
      response => {
        console.log('POST request successful:', response);
        window.location.reload();
      },
      error => {
        console.error('Error in POST request:', error);
      }
    );

  }





  toggleStatusText(element: any): void {
    // Update the status text based on the element's active property
    element.active = !element.active;
  }

  getStatusText(element: any): string {
    return element.active ? 'Active' : 'Désactivé';
  }

  openDialog(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.dialogContentTemplate, {
      width: 'fit-content', // Set the desired width
    });}

    closeDialog(): void {
      this.dialog.closeAll(); // Close all open dialogs
    }


  
    applyFilter(filterValue: string) {
      this.datasource.filter = filterValue.trim().toLowerCase();
      if (this.datasource.paginator) {
        this.datasource.paginator.firstPage();
      }
    }

}
