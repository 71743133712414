<!-- <div class="container" style="margin-top: 100px;margin-left:30px;"> -->
  <div class="d-flex justify-content-center">
    <div class="col-4">
      <div class="form-group">
        <label>Type de moteur:</label>
        <select (change)="onChangeType($event.target.value)" class="form-control">
          <option disabled selected>Selectionner le type désiré</option>
          <option value="t">Tarificateur</option>
          <option value="rl">Règle libre</option>
        </select>
      </div>
    </div>
  </div>

  <ng-container *ngIf="engineType === 't'">
  <div  class="headPage">
    <div class="titlelist">
      <h1 class="titlemodel">Moteur de Règles</h1>
    </div>

    <div class="allpaths">
      <a class="pathing" >Moteur de Règles</a>
    </div>
  </div>
  <div class="selectCPT">
    <div class="col-4">
      <div class="form-group">
        <label>Compagnie:</label>
        <select (change)="onChangeCompany($event.target.value)" class="form-control">
          <option disabled selected>Selectionner votre compagnie</option>
          <option *ngFor="let c of companysList" value="{{c.id}}">{{c.label}}</option>
        </select>
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label>Produit:</label>
        <select (change)="onChangeProduct($event.target.value)" class="form-control">
          <option disabled selected>Selectionner votre produit</option>
          <option *ngFor="let c of productsList" value="{{c.id}}">{{c.title}}</option>
        </select>
      </div>
    </div>
    <div class="col-4">
      <div class="form-group">
        <label>Grille de Tarification:</label>
        <select (change)="onChangePriceTable($event.target.value)" class="form-control">
          <option disabled selected>Selectionner votre grille</option>
          <option *ngFor="let c of tablePriceList" value="{{c.id}}">{{c.label}}</option>
        </select>
      </div>
    </div>
  </div>


  <div class="jsonObject" *ngIf="contractStructure!=''">
    <div class="col-12">
      <textarea [(ngModel)]="contractStructure" (ngModelChange)="onChangeContructStructure()" rows="{{contractStructureNbRows}}" class="form-control" disabled></textarea>
    </div>
  </div>


  <div id="accordion" class="container1">
   <div class="card">
      <div class="card-header" id="headingPreBuiltFunction">
        <h5 class="mb-0">
          <div class="row">
            <div class="col-6">
              <button  class="btn btn-link" data-toggle="collapse" data-target="#collapsePreBuiltFunction" aria-expanded="true" aria-controls="collapsePreBuiltFunction" style="color: #480072;">
                Méthodes préconstruites
              </button>
            </div>
          </div>
        </h5>
      </div>

      <div id="collapsePreBuiltFunction" class="collapse show" aria-labelledby="headingPreBuiltFunction" style="background-color: #f4f4f4;" >
        <div class="card-body">
          <ul>
            <li>
              <span style="color: #ff902b; font-size: 20px">self.getAge( </span> <span style="color: #3858ff; font-size: 20px">birthday_str<span style="color: #ff902b; font-size: 20px">,</span> pattern='%m-%d-%Y' </span><span style="color: #ff902b; font-size: 20px">)  </span> <span>: Integer</span>
            </li>

            <li>
              <span style="color: #ff902b; font-size: 20px">self.getFromExternAPI( </span> <span style="color: #3858ff; font-size: 20px">url <span style="color: #ff902b; font-size: 20px"></span> </span><span style="color: #ff902b; font-size: 20px">)  </span> <span>: dict from json</span>
            </li>

            <li>
              <span style="color: #ff902b; font-size: 20px" >self.recalculateTotalPrice()</span> <span> # self.contract.totalPrice = Sum all Insureds.SinglePrice</span>
            </li>

            <li>
              <span style="color: #ff902b; font-size: 20px">self.customResponse( </span> <span style="color: #3858ff; font-size: 20px">message,status_code <span style="color: #ff902b; font-size: 20px"></span> </span><span style="color: #ff902b; font-size: 20px">)  </span> <span> # Use this function to return a custom response of the tarification API call (depending on some condition for example)</span>
            </li>
          </ul>

        </div>
      </div>
    </div>
    <!-- <mat-accordion>
      <mat-expansion-panel [expanded]="true" hideToggle>
        <mat-expansion-panel-header style="background-color: #e5e5e5 !important;">
          <mat-panel-title style="color: #480072;">
            Méthodes préconstruites
          </mat-panel-title> -->
          <!-- <mat-panel-description> -->
            <!-- <div class="col-6">
            <button [disabled]="selectedPriceTable.id==null" (click)="updateMethod('post')" style="float: right"
                    class="btn btn-success"> Enregistrer
            </button>
            </div> -->
          <!-- </mat-panel-description> -->
        <!-- </mat-expansion-panel-header>
        <div id="collapsePreBuiltFunction" class="collapse show" aria-labelledby="headingPreBuiltFunction" style="padding:30px;" >
          <div class="card-body">
            <ul>
              <li>
                <span style="color: #ff902b; font-size: 20px">self.getAge( </span> <span style="color: #3858ff; font-size: 20px">birthday_str<span style="color: #ff902b; font-size: 20px">,</span> pattern='%m-%d-%Y' </span><span style="color: #ff902b; font-size: 20px">)  </span> <span>: Integer</span>
              </li>
  
              <li>
                <span style="color: #ff902b; font-size: 20px">self.getFromExternAPI( </span> <span style="color: #3858ff; font-size: 20px">url <span style="color: #ff902b; font-size: 20px"></span> </span><span style="color: #ff902b; font-size: 20px">)  </span> <span>: dict from json</span>
              </li>
  
              <li>
                <span style="color: #ff902b; font-size: 20px" >self.recalculateTotalPrice()</span> <span> # self.contract.totalPrice = Sum all Insureds.SinglePrice</span>
              </li>
            </ul>
  
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->


    
    <!-- <div class="card">
      <div class="card-header" id="headingOne" >
        <h5 class="mb-0">
          <div class="row">
            <div class="col-6">
              <button [disabled]="selectedPriceTable.id==null"  class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style="color: #480072;">
                Méthode de pré-tarification
              </button>
            </div>
            <div class="col-6">
              <button [disabled]="selectedPriceTable.id==null" (click)="updateMethod('pre')" style="float: right"
                      class="btn btn-success"> Enregistrer
              </button>
            </div>
          </div>
        </h5>
      </div>

      <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion" style="background-color: #f4f4f4;"> -->
        <!-- <div> -->
          <!-- <textarea [(ngModel)]="prePricingMethod" rows="8" class="form-control" id="prePricingMethod"
                    placeholder="self.contract.totalPrice=0"></textarea> -->
                    
                      <!-- <ngx-monaco-editor style="padding:30px;border-radius: 10px;" [options]="editorOptions" [(ngModel)]="prePricingMethod"></ngx-monaco-editor> -->
                  


        <!-- </div> -->
      <!-- </div> -->


      
      <mat-accordion >
        <mat-expansion-panel hideToggle [disabled]="selectedPriceTable.id == null" >
          <mat-expansion-panel-header style="background-color: #e5e5e5 !important;padding-top: 13px;padding-bottom: 14px;height: 22px;
          ">
            <mat-panel-title [ngClass]="{'panel-title-enabled': selectedPriceTable.id !== null, 'panel-title-disabled': selectedPriceTable.id === null}">
              Méthode de pré-tarification
            </mat-panel-title>
            <!-- <mat-panel-description> -->
              <div class="col-6">
              <button [disabled]="selectedPriceTable.id==null" (click)="updateMethod('pre')" style="float: right"
              class="btn btn-success"> Enregistrer
      </button>  
      </div>
            <!-- </mat-panel-description> -->
          </mat-expansion-panel-header>
          <!-- <div class="monacoeditorparentdiv" > -->
          <ngx-monaco-editor class="monacoeditorparentdiv" [options]="editorOptions" [(ngModel)]="prePricingMethod"></ngx-monaco-editor>
          <!-- </div> -->
          <div> <button matTooltip="élargir l'éditeur"  (click)="openDialogmonaco()" style="float: right"
            class="btn btn-success"> <mat-icon>open_in_new</mat-icon> </button></div>
        </mat-expansion-panel>
      </mat-accordion>


      <mat-accordion >
        <mat-expansion-panel hideToggle [disabled]="selectedPriceTable.id == null"  style="margin-bottom:10vh;">
          <mat-expansion-panel-header style="background-color: #e5e5e5 !important;">
            <mat-panel-title [ngClass]="{'panel-title-enabled': selectedPriceTable.id !== null, 'panel-title-disabled': selectedPriceTable.id === null}">
              Méthode de post-tarification
            </mat-panel-title>
            <!-- <mat-panel-description> -->
              <div class="col-6">
              <button [disabled]="selectedPriceTable.id==null" (click)="updateMethod('post')" style="float: right"
                      class="btn btn-success"> Enregistrer
              </button>
             
              </div>
            <!-- </mat-panel-description> -->
          </mat-expansion-panel-header>
          <div class="monacoeditorparentdiv">
          <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="postPricingMethod"></ngx-monaco-editor>
          </div>
          <div> <button matTooltip="élargir l'éditeur"  (click)="openDialogmonacopost()" style="float: right"
            class="btn btn-success"> <mat-icon>open_in_new</mat-icon> </button></div>
        </mat-expansion-panel>
      </mat-accordion>



    <!-- </div> -->

    <!-- <div class="card">
      <div class="card-header" id="headingTwo">
        <h5 class="mb-0">
          <div class="row">
            <div class="col-6">
              <button [disabled]="selectedPriceTable.id==null"  class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style="color: #480072;">
                Méthode de post-tarification
              </button>
            </div>
            <div class="col-6">
              <button [disabled]="selectedPriceTable.id==null" (click)="updateMethod('post')" style="float: right"
                      class="btn btn-success"> Enregistrer
              </button>
            </div>
          </div>
        </h5>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion" style="background-color: #f4f4f4;">
        <div class="card-body">
          <textarea [(ngModel)]="postPricingMethod" rows="8" class="form-control" id="postPricingMethod"
                    placeholder="self.contract.totalPrice=0"></textarea>
        </div>
      </div>
    </div> -->

  </div>
<!-- </div> -->



<!-- Pop up Monaco pretarification -->
<ng-template #monacoeditorpopuppre>
  <div class="row">
    <div class="col-lg-6 d-flex justify-content-start mx-auto">
      <label mat-dialog-title >Méthode de pré-tarification</label>
    </div>
    <div class="col-lg-6 d-flex justify-content-end">
      <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonacobigger()"  matTooltip="Plein écran" ><mat-icon>zoom_out_map</mat-icon></button>
      <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
      <mat-divider></mat-divider>

    </div>
  
  </div>
    <div class="row">
    <div class="col-lg-12" style="width:100%;border-radius: 5px;">
      <div style="width:70vw;height:400px;padding-right:15px;">      <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="prePricingMethod"></ngx-monaco-editor>
      </div>
    </div>
  </div>

</ng-template>

<!-- Pop up Monaco pretarification -->
<ng-template #monacoeditorpopupprebigger>
  <div class="row">
    <div class="col-lg-6 d-flex justify-content-start mx-auto">
      <label mat-dialog-title >Méthode de pré-tarification</label>
    </div>
    <div class="col-lg-6 d-flex justify-content-end">
      <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonaco()"  matTooltip="réduire" ><mat-icon>zoom_in_map</mat-icon></button>
      <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
      <mat-divider></mat-divider>

    </div>
  
  </div>
    <div class="row">
    <div class="col-lg-12 d-flex justify-content-center">
      <div style="width:95vw;height:85vh;">      <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="prePricingMethod"></ngx-monaco-editor>
      </div>
    </div>
  </div>

</ng-template>



<!-- Pop up Monaco posttarification -->
<ng-template #monacoeditorpost>
  <div class="row">
    <div class="col-lg-6 d-flex justify-content-start mx-auto">
      <label mat-dialog-title >Méthode de post-tarification</label>
    </div>
    <div class="col-lg-6 d-flex justify-content-end mx-auto">
      <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonacopostbigger()" ><mat-icon matTooltip="plein écran">zoom_out_map</mat-icon></button>
      <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
      </div>
      <div class="col-lg-12">
      <mat-divider></mat-divider>

    </div>
  
  </div>
    <div class="row">
    <div class="col-lg-12">
      <div style="width:70vw;height:400px;"  >
        <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="postPricingMethod"></ngx-monaco-editor>
        </div>
    </div>
  </div>

</ng-template>

<!-- Pop up Monaco posttarification -->
<ng-template #monacoeditorpostbigger>
  <div class="row">
    <div class="col-lg-6 d-flex justify-content-start mx-auto">
      <label mat-dialog-title >Méthode de post-tarification</label>
    </div>
    <div class="col-lg-6  d-flex justify-content-lg-end justify-content-xs-center mx-auto">
      <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonacopost()" matTooltip="réduire" ><mat-icon>zoom_in_map</mat-icon></button>
      <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
      </div>
      <div class="col-lg-12">
      <mat-divider></mat-divider>

    </div>
  
  </div>
    <div class="row">
    <div class="col-lg-12 d-flex justify-content-center">
      <div style="width:95vw;height:85vh;"  >
        <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="postPricingMethod"></ngx-monaco-editor>
        </div>
    </div>
  </div>

</ng-template>
</ng-container>
<ng-container *ngIf="engineType === 'rl'">
  <app-free-rule></app-free-rule>
</ng-container>
