import {PossibleOption} from '../PossibleOption';


export class InputOption extends PossibleOption{
  constructor(init?: Partial<InputOption>) {
    super();
    super.type="Input"
    Object.assign(this, init);
  }

  typeInput:string;
  val:string;
}

export class DateInput extends InputOption{
  date:Date;
  constructor(init?: Partial<InputOption>) {
    super();
    super.typeInput="date";
    Object.assign(this, init);
  }
}

export class NumberInput extends InputOption{
  num:number;
  private min:number;
  private max:number;
  private step:number;

  regex:string;

  constructor(init?: Partial<InputOption>) {
    super();
    super.typeInput="number";
    Object.assign(this, init);
  }

  setRegex(regex:string){
    this.regex = regex;
  }

  setmin(value: number) {
    this.min = value;
  }

  setmax(value: number) {
    this.max = value;
  }

  setstep(value: number) {
    this.step = value;
  }
}
export class CheckBoxInput extends InputOption{

  constructor(init?: Partial<InputOption>) {
    super();
    super.typeInput="checkbox";
    Object.assign(this, init);
  }
}

export class RadioPossibleValue {
  id:number;
  label:string;
  val:string;
  constructor(label:string, val:string) {
    this.label = label;
    this.val = val;
  }

}

export class RadioInput extends InputOption{
  constructor(init?: Partial<InputOption>) {
    super();
    super.typeInput="radio";
    Object.assign(this, init);
  }
  radioPossibleValues:RadioPossibleValue[];
}

export class RangeInput extends InputOption{
  min:number;
  max:number;
  ranges:RangePossibleValues[];
  constructor(init?: Partial<InputOption>) {
    super();
    super.typeInput="range";
    Object.assign(this, init);
  }
  getVal(val){
    val=+val;
    for(let range of this.ranges){
      if(val>=range.min && val<range.max) return range.label
    }
    return val;
  }

}

export class RangePossibleValues{
  id:number;
  min:number;
  max:number;
  label:string;
  val:string;
  constructor(min:number,max:number,label:string,value:string) {
    this.min = min;
    this.max = max;
    this.label = label;
    this.val = value;
  }

}

export class TextInput extends InputOption{
  text:string;
  regex:string;
  constructor(init?: Partial<InputOption>) {
    super();
    super.typeInput="text";
    Object.assign(this, init);
  }
  setRegex(regex:string){
    this.regex = regex;
  }
}
