<!-- <div class="container" style="margin-top: 100px;margin-left:30px;"> -->
    <div class="d-flex justify-content-center">
        <div class="col-4">
          <div class="form-group">
            <label>Règles:</label>
            <select (change)="onSelectRule($event.target.value)" class="form-control">
              <option disabled selected>Selectionner votre règle</option>
              <option *ngFor="let r of rulesList" value="{{r.id}}">{{r.label}}</option>
              <option value="0">Ajouter une règle</option>
            </select>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <div class="col-4">
          <div class="form-group">
            <label>Libelle:</label>
            <input type="text" class="form-control" [(ngModel)]="ruleLabel"/>
          </div>
        </div>
      </div>

    <div  class="headPage">
        <div class="titlelist">
          <h1 class="titlemodel">Moteur de Règles</h1>
        </div>
    
        <div class="allpaths">
          <a class="pathing" >Moteur de Règles</a>
        </div>
      </div>
      
      <div *ngIf="freeRule || newRule"class="container mt-4">
        <div class="form-group">
          <label for="jsonInput">Insérer un objet JSON:</label>
          <textarea id="jsonInput" class="form-control" [(ngModel)]="jsonObject" rows="10" (input)="onJsonChange($event.target.value)"></textarea>
        </div>
        <button class="btn btn-primary" (click)="submitJson()">Submit JSON</button>
      </div>

      <div *ngIf="parsedJsonObject" class="container mt-4 mb-4">
        <h3>JSON Viewer:</h3>
        <app-tree-view [inputData]="parsedJsonObject"></app-tree-view> 
      </div>
    
    
      <div *ngIf="freeRule || newRule" id="accordion" class="container1">
          <mat-accordion >
            <mat-expansion-panel hideToggle [disabled]="parsedJsonObject == null" >
              <mat-expansion-panel-header style="background-color: #e5e5e5 !important;padding-top: 13px;padding-bottom: 14px;height: 22px;
              ">
                <mat-panel-title [ngClass]="{'panel-title-enabled': parsedJsonObject !== null, 'panel-title-disabled': parsedJsonObject === null}">
                  Règle de calcul
                </mat-panel-title>
                <!-- <mat-panel-description> -->
                  <div class="col-6">
                  <button [disabled]="parsedJsonObject==null" (click)="updateMethod()" style="float: right"
                  class="btn btn-success"> Enregistrer
          </button>  
          </div>
                <!-- </mat-panel-description> -->
              </mat-expansion-panel-header>
              <!-- <div class="monacoeditorparentdiv" > -->
              <ngx-monaco-editor class="monacoeditorparentdiv" [options]="editorOptions" [(ngModel)]="freeRuleStr"></ngx-monaco-editor>
              <!-- </div> -->
              <div> <button matTooltip="élargir l'éditeur"  (click)="openDialogmonaco()" style="float: right"
                class="btn btn-success"> <mat-icon>open_in_new</mat-icon> </button></div>
            </mat-expansion-panel>
          </mat-accordion>
    
      </div>
    
    
    
    <!-- Pop up Monaco pretarification -->
    <ng-template #monacoeditorpopuppre>
      <div class="row">
        <div class="col-lg-6 d-flex justify-content-start mx-auto">
          <label mat-dialog-title >Méthode de pré-tarification</label>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
          <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonacobigger()"  matTooltip="Plein écran" ><mat-icon>zoom_out_map</mat-icon></button>
          <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
          <mat-divider></mat-divider>
    
        </div>
      
      </div>
        <div class="row">
        <div class="col-lg-12" style="width:100%;border-radius: 5px;">
          <div style="width:70vw;height:400px;padding-right:15px;">      <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="freeRuleStr"></ngx-monaco-editor>
          </div>
        </div>
      </div>
    
    </ng-template>
    
    <!-- Pop up Monaco pretarification -->
    <ng-template #monacoeditorpopupprebigger>
      <div class="row">
        <div class="col-lg-6 d-flex justify-content-start mx-auto">
          <label mat-dialog-title >Méthode de pré-tarification</label>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
          <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonaco()"  matTooltip="réduire" ><mat-icon>zoom_in_map</mat-icon></button>
          <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
          <mat-divider></mat-divider>
    
        </div>
      
      </div>
        <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <div style="width:95vw;height:85vh;">      <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="freeRuleStr"></ngx-monaco-editor>
          </div>
        </div>
      </div>
    
    </ng-template>
    
    
    
    
    