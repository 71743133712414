import {Product} from './Product';

export class Company {
  constructor(init?: Partial<Company>) {
    Object.assign(this, init);
  }
  id:number;
  label:string;
  description:string;
  products:Product[];
}
