import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {TablePrice} from '../pages/PriceTableEngine/entity/TablePrice';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceTableInventoryServiceService {
  service:string="PRICETABLE-INVENTORY/";
  constructor(private http:HttpClient) { }
  addTablePrice(tabelprice:TablePrice){
    return this.http.post(environment.baseUrl+this.service+"table_price/add",tabelprice,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })
    })
  }
  getByProductId(id:number){
    return this.http.get(environment.baseUrl+this.service+"table_price/product/"+id);
  }


}
