<!-- <div class="container" style="margin-top: 100px;margin-left: 0px"> -->
    <div class="row" >
      <div class="col-lg-9 col-sx-12 justify-content-center">
        <h1 >Liste de garanties</h1>
      </div>
      <div class="col-3" >
        <h1 class="pathing">Garanties</h1>
      </div>
    </div>
    <div class="row" >
        <!-- <div class="col-9">
          <h1 class="donne-model">Données des garanties</h1>
        </div> -->
        <div class="col-lg-11 col-sx-12 d-flex justify-content-end">
          <button class="modifiertaxebtn" data-toggle="modal" data-target="#addCompanyModal" (click)="initForm()" >    
            <mat-icon>add</mat-icon>
            Ajouter un garantie
          </button>
          </div>
    </div>
    <div class="row" >
      <div class="col-12">
      <table>
        <tr >
          <td style="padding:20px;">
        <tr>
          <!-- <h1 class="donne-model">Données des garanties</h1> -->
        </tr>
        <tr >
          <!-- <h1 class="donne-model-description"></h1> -->
        </tr>
       <!-- MAT-TABLE DATATABLE BEGINS -->
       <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Rechercher ...">
    </mat-form-field>
    <table  mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
      <!-- Position Column -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef> No. </th>
        <td mat-cell *matCellDef="let index = index">{{index+1}} </td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>  Code </th>
        <td mat-cell *matCellDef="let element"> {{element.code}}</td>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let element"> <mat-slide-toggle   [matTooltip]="!element.active?'Désactive' : 'Active'" [(ngModel)]="element.active"  (click)="setStatus(element.code)" [checked]="element.active"></mat-slide-toggle>
        </td>
      </ng-container> 
      
      <!-- Weight Column -->
      <ng-container matColumnDef="libelle">
        <th mat-header-cell *matHeaderCellDef> Libelle</th>
        <td mat-cell *matCellDef="let element">  {{element.label}} </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="description" >
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;padding-right:50px;"
        > {{element.description}} </td>
      </ng-container>  
      
      <!-- Symbol Column -->
      <ng-container matColumnDef="unite">
        <th mat-header-cell *matHeaderCellDef> Unité </th>
        <td mat-cell *matCellDef="let element"> {{element.unite}} </td>
      </ng-container>
    
      <ng-container matColumnDef="proprietes">
        <th mat-header-cell *matHeaderCellDef> Proprietes </th>
        <td mat-cell *matCellDef="let element">  
          <button class="btniconedit"  (click)="openDialogupdate(element.code)"> <mat-icon>edit</mat-icon></button>
          <!-- (click)="editCoverage(element.code)"  -->
          <!-- <button class="btnicondelete" (click)="openDialog(element.id,element.label)"> <mat-icon>delete</mat-icon></button>   -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"></tr>
    </table>
    <mat-paginator  [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
  </td>
  </tr>
  </table>
    <!-- MAT-TABLE DATATABLE ENDS  -->
    </div>
  </div>
    <!-- Modal -->
<div class="modal fade" id="addCompanyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"     style="background-color: rgba(72,0,114,0.28999999165534973);padding:10px;">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width:auto;height:auto;">
      <div class="modal-header" style="border-color: #a4a4a4;">
        <h5 class="modal-title" id="exampleModalLabel"  style="margin-left: 10px">Ajouter un garantie</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <form class="titles" [formGroup]="CoverageForm">
                <div class="form-group">
                  <label for="label">Libellé</label>
                  <input type="text" class="form-control" id="label" formControlName="label">
                </div>


                <div class="form-group">
                  <label for="description">Description</label>
                  <textarea row="6" class="form-control" id="description" formControlName="description"></textarea>
                </div>

                <div class="form-group">
                  <label for="label">Code</label>
                  <input type="text" class="form-control" id="code" formControlName="code">
                </div>
                
                <div class="form-group">
                  <label for="unite">Unité</label>
                  <input type="text" class="form-control" id="unite" formControlName="unite">
                </div>

            </form>
          </div>

      <div class="col-12 text-center" style="padding:10px;">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" style="margin-right:5px">Annuler</button>
        <button type="button" class="btn btn-primary"  data-dismiss="modal"  style="margin-left:5px" (click)="addCoverage()">Enregistrer</button>
      </div>
      
    </div>
  </div>
</div>
  
<!-- Pop Up Delete Confirmation -->
<ng-template #deleteconfirmationdialog>
  <form  (ngSubmit)="delete()">
    <div class="row d-flex justify-content-center">
    <div class="col-lg-12 d-flex justify-content-center">
      <mat-icon class="warningicon">warning_amber</mat-icon>
    </div> 
    <div class="col-lg-12 d-flex justify-content-center">
    <mat-label>Voulez-vous vraiment supprimer</mat-label>
    </div>
    <div class="col-lg-12 d-flex justify-content-center">
    <h5>{{selectedItemToDelete.label}}</h5>
    </div>
    </div>
    <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-6 d-flex justify-content-center">
      <button class="modifiertaxebtnannuler" type="button" [matDialogClose] >Annuler</button>
      </div>
      <div class="col-lg-6 d-flex justify-content-center">
  <button class="modifiertaxebtn" mat-dialog-close type="submit">Confirmer</button>
</div>
</div>
</form>
</ng-template>
<!-- Pop Up Delete Confirmation -->

<!-- Update COverage Dialog  -->
<ng-template #updatecoveragedialog>
  <!-- <div class="container" style="margin-top: 100px;margin-left: 0px;"> -->
    <div class="row d-flex justify-content-center">
      <div class="col-12">
  
        <div class="row d-flex justify-content-center" style="margin-bottom: 10px">
          <div class="col-12">
            <h1 class="addmcoveragetitle">Modifier un garantie</h1>
          </div>
        </div>
       
      </div>
      <div class="col-12">
        <form [formGroup]="CoverageEditForm" (ngSubmit)="CoverageUpdate(selectedItemToupdate.code)" class="titles">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">Libellé</label>
                <input type="text" class="form-control" id="label" formControlName="label">
              </div>
            </div>
          </div>
  
          <div class="row align-items-end">
            <div class="col-6">
              <div class="form-group">
                <label for="description">Description</label>
                <textarea row="6" class="form-control" id="description" formControlName="description"></textarea>
              </div>
            </div>
          </div>

       <!--   <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">Plafond</label>
                <input type="text" class="form-control" id="plafon" formControlName="plafon">
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">Code</label>
                <input type="text" class="form-control" id="code" formControlName="code">
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="label">unite</label>
                <input type="text" class="form-control" id="unite" formControlName="unite">
              </div>
            </div>
          </div>

          <div class="col-xs-12 text-right" style="margin-top: 62px;">
            <button type="button" class="modifiertaxebtnannuler" [matDialogClose]> Retourner à la liste</button>
            <button class="modifiertaxebtn" type="submit" style="margin-left: 41px;">Enregistrer</button>
          </div>
        </form>
      </div>
    </div>
    <!-- <div mat-dialog-actions> -->
      
    <!-- </div> -->
<!-- </div> -->
</ng-template>