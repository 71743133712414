import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from 'src/app/_models/User';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  protected apiUrl = environment.baseUrl+"USER-INVENTORY";
  currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public currentUser: Observable<User>;

  constructor(protected httpClient: HttpClient, private cookieService: CookieService) {
    if(cookieService.check('currentUser'))
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(cookieService.get('currentUser')));
  }

   /**
     * User authentication
     * @param login
     */
   authentificationSignIn(login: any): Observable<any> {

    let headers = new HttpHeaders();

    return this.httpClient.request<any>('post', `${this.apiUrl}/api/user/sign-in`,
        {
            body: login,
            headers: headers,
        }).pipe(
          tap(({token, refreshToken}) => {
              this.cookieService.set('token', token, 2000000000);
              this.cookieService.set('refreshToken', refreshToken);
          })
      );;
    }

    /**
     * Permettant de retourner les informations details de lutilisateur courant
     */
    getUserDetails(): Observable<any> {

        const headers = new HttpHeaders();

        return this.httpClient.request<any>('get', `${this.apiUrl}/api/user/details`,
            {
                headers: headers,
            });
    }

    logout(): void {
      if (this.cookieService.check('token')) {
        this.cookieService.delete('token');
      }

      if (this.cookieService.check('refreshToken')) {
        this.cookieService.delete('refreshToken');
      }

      if (this.cookieService.check('currentUser')) {
        this.cookieService.delete('currentUser');
      }
      location.reload();
      // this._router.navigate(['/auth']);
      
    }

    public get currentUserValue(): User {
      if(this.cookieService.check('currentUser'))
      return JSON.parse(this.cookieService.get('currentUser'));
      else return null;
    }

      public hasRole(role: string): boolean {
      return this.currentUserValue?.role?.toLowerCase().indexOf(role.toLowerCase()) >= 0;
    }

    isAuthorized(): boolean {
      return !!this.currentUserValue;
    }

    updateUser(user: User): void {
      this.currentUserSubject.next(user);
    }
}
