import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PriceTableInventoryServiceService} from '../../../services/price-table-inventory-service.service';
import {TablePrice} from '../entity/TablePrice';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {PricetableEngineService} from '../../../services/pricetable-engine.service';
import {ModelInventoryService} from '../../../services/model-inventory.service';
import {Option} from '../../Model-Inventory/entity/Option';
import {UploadFileResponse} from '../entity/UploadFileResponse';

@Component({
  selector: 'app-price-tables-list',
  templateUrl: './price-tables-list.component.html',
  styleUrls: ['./price-tables-list.component.css']
})
export class PriceTablesListComponent implements OnInit {
  @Input() productId:number;
  tablePriceList:TablePrice[]=[];
  dataTableIdToValidate:number=null;
  csv_path:string=null;
  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  selectedFile = null;
  changeImage = false;
  showOverlay:boolean = false;

  constructor(
    private route:Router,
    private priceTableInventoryService:PriceTableInventoryServiceService,
    private pricetableEngineService:PricetableEngineService,
    private modelInventoryService:ModelInventoryService
  ) { }

  ngOnInit(): void {


    this.priceTableInventoryService.getByProductId(this.productId).subscribe((data:TablePrice[])=>{
      this.tablePriceList= data;
      for(let tablePrice of this.tablePriceList){
        for (let dt of tablePrice.dataTables){
          let options1:Option[]=[];
          for (let ax of dt.axes){
            this.modelInventoryService.getOptionById(ax.id).subscribe((data:Option)=>{
              options1.push(data);
            })
          }
          dt.axes=options1;
        }
      }
    });

  }

  downloadFile(path:string){
    let filename:string =path.split("/").pop();
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', "http://"+path);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  change($event) {
    this.changeImage = true;
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  upload() {
    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.showOverlay=true;
    this.pricetableEngineService.upload(this.currentFileUpload,this.dataTableIdToValidate).subscribe(event => {
      if (event.type == HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * event.loaded / event.total);
      } else if (event instanceof HttpResponse) {
        this.showOverlay=false;
        alert(event.body);
      }
      }
      ,
      error => {
          alert("CSV not Valid!");
        this.showOverlay=false;
      }
    );
  }
  uploadToFill() {
    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.showOverlay=true;
    this.pricetableEngineService.uploadToFill(this.currentFileUpload,this.dataTableIdToValidate).subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.showOverlay=false;
          alert(event.body);
        }
      }
      ,
      error => {
        alert("CSV not Valid!");
        this.showOverlay=false;
      }
    );
  }

  addPriceTable() {
    this.route.navigate(["/pricetable/add"])
  }

  details(id: any) {

  }

  Generate_CSV(id: number) {
    this.showOverlay=true;
    this.pricetableEngineService.Generate_CSV(id).subscribe((resp: UploadFileResponse)=>{
      this.showOverlay=false;
      this.downloadFile(resp.fileDownloadUri);
    })
  }

  selectdataTable(id:number){
    this.dataTableIdToValidate=id;
  }

  ValidateCSV() {

  }

  CancelCSVValidation() {
    this.dataTableIdToValidate=null;
    this.selectedFiles=null;
  }


}
