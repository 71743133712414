
<div class="row toprow " style="margin-top:3vw;">
    <div class="col-lg-8  d-flex justify-content-start ">
    <h1>Taxes</h1>
  </div>
    <div class="col-lg-4 d-flex justify-content-end">
      <button class="modifiertaxebtn" (click)="openDialogadd();addDateValueField()" >Ajouter Taxe</button>
    </div>
  
  </div>
  <div class="row d-flex justify-content-center ">
    <div class="col-lg-12 justify-content-start">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-sm-6 col-xs-12 justify-content-center">
    <table mat-table [dataSource]="datasource" class="mat-elevation-z8" mat-elevation-z8>
      
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

<ng-container matColumnDef="categorylabel">
  <th mat-header-cell *matHeaderCellDef> Categorie </th>
  <td mat-cell *matCellDef="let element"> {{element.category.label}} </td>
</ng-container>


        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> label </th>
          <td mat-cell *matCellDef="let element"> {{element.label}} </td>
        </ng-container>

      
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef> Date début </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container class="row">
            <mat-select [(value)]="element.selectedTaxVersion" placeholder="Choisir une Date">
              <mat-option  *ngFor="let taxVersion of element.taxeVersion" [value]="taxVersion">
                {{ taxVersion.startDate | date }}
              </mat-option></mat-select>
            </ng-container>
            </td>
            
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> Valeur </th>
          <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.selectedTaxVersion">
            {{ element.selectedTaxVersion.value }} {{element.pourcentage? '%' : ''}}
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="distribution">
          <th mat-header-cell *matHeaderCellDef> Distribution</th>
          <td mat-cell *matCellDef="let element"> {{element.perUnit? 'Par unité' : 'globale'}} </td>
        </ng-container>
        
        
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef> Active</th>
          <td mat-cell *matCellDef="let element"> <mat-icon>
            {{ element.active ? 'check' : 'close' }}
          </mat-icon> </td>
        </ng-container>


        <ng-container matColumnDef="modifier">
          <th mat-header-cell *matHeaderCellDef> Modifier </th>
          <td mat-cell *matCellDef="let element">
            <button class="btnicon" (click)="openDialogupdate(element.id)"><i class="material-icons">settings</i>
            </button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table> 

  </div>
  <div class="col-lg-12 justify-content-end">
    <mat-paginator mat-elevation-z8  #paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>

      </div>
</div>

<!-- Pop up Ajouter -->
<ng-template #addTaxePopup>
    <div class="row  justify-content-center">
      <div class="col-lg-6 justify-content-start">
      <label for="categoryId">Categorie</label>
      <select class="modifiertaxeinput" [(ngModel)]="basicInfo.categoryId" name="categoryId">
        <option *ngFor="let categoryId of taxecategoriesselect" [value]="categoryId.id" >
{{categoryId.label}}
        </option>
      </select>
      </div>
  <div class="col-lg-6  justify-content-end">
      <label for="label">Label</label>
      <input class="modifiertaxeinput" type="text" id="label" [(ngModel)]="basicInfo.label" name="label">
      
  </div>
  </div>
  <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

  <div class="row justify-content-center">
    <div class="col-lg-6  justify-content-start">
      <label for="label">Par Unité</label>
      <input style="margin:10px;" type="checkbox" class="modifiertaxeinput" [(ngModel)]="basicInfo.perUnit" name="perUnit">
      </div>
      <div class="col-lg-6 d-flex justify-content-center">      
      <label for="label">Pourcentage</label>
      <input type="checkbox" style="margin:10px;" [(ngModel)]="basicInfo.pourcentage" name="pourcentage">
      </div>
      </div>
      <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

  <div *ngFor="let field of dateValueFields; let i = index">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-12 d-flex justify-content-center">
    <input type="date" class="modifiertaxeinput" [(ngModel)]="field.startDate" name="startDate{{i}}" (ngModelChange)="updateNextStartDate(i)" placeholder="Date Debut">
    <input type="date" class="modifiertaxeinput" [(ngModel)]="field.endDate" name="endDate{{i}}" disabled placeholder="Date Fin">
    <input class="modifiertaxeinput" [(ngModel)]="field.value" name="value{{i}}" placeholder="Valeur">
    <button class="btnicondelete" type="button" (click)="removeDateValueField(i)"><i class="material-icons">delete_forever</i>
    </button>
    </div>
  </div>
  </div>
  <div class="col-lg-12 d-flex justify-content-start">
    <button mat-raised-button type="button" (click)="addDateValueField()">Ajouter Date <i class="material-icons">edit_calendar</i>
    </button>
      </div>
      <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

  <div class="row d-flex justify-content-center">
    <div class="col-lg-12 d-flex justify-content-end">
    <button class="modifiertaxebtnannuler" type="button" [matDialogClose] >Annuler</button>
    <button class="modifiertaxebtn" type="button" (click)="submitTaxe();closeDialogadd()">Ajouter</button>
    </div>
    </div>
</ng-template>

<!-- Pop up Modifier -->

<ng-template #updateTaxesPopup>
    <div class="row d-flex justify-content-center">
      <div class="col-lg-6">
      <label for="label">Categorie : </label>
      <input class="modifiertaxeinput" type="text" [(ngModel)]="basicInfo.categoryId" name="categoryId"  >
      </div>
      <div class="col-lg-6 d-flex justify-content-end">
      <label for="label">Label : </label>
      <input class="modifiertaxeinput" [(ngModel)]="basicInfo.label" type="text" id="label" name="label" [value]="taxeget.label">
    </div>
    </div>
    <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

    <div class="row d-flex justify-content-center">
    <div class="col-lg-6 d-flex justify-content-center">
      <label for="label">Distribution : </label>
        <input [(ngModel)]="basicInfo.perUnit" type="checkbox" [checked]="taxeget.perUnit===true" name="perUnit" [value]="taxeget.perUnit">
        </div>
        <div class="col-lg-6 d-flex justify-content-center">
      <label for="label">Pourcentage : </label>
        <input [(ngModel)]="basicInfo.pourcentage" type="checkbox" name="pourcentage" [checked]="taxeget.pourcentage===true" [value]="taxeget.pourcentage">
      </div>    
      </div>
      <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

      <div class="row d-flex justify-content-center">
        <div class="col-lg-4 d-flex justify-content-center">
          <label>Date Debut</label>
        </div>
        <div class="col-lg-4 d-flex justify-content-center">
          <label>Date Fin</label>
        </div>
        <div class="col-lg-4 d-flex justify-content-center">
          <label>Valeur</label>
        </div>
      </div>
<div class="row d-flex justify-content-center">
  <div class="col-lg-12">
  <div *ngFor="let taxDet of dateValueFields ; let i = index">
    <input class="modifiertaxeinput" [(ngModel)]="taxDet.startDate" name="startDate" [value]="taxDet.startDate" placeholder="Date Debut">
    <input  class="modifiertaxeinput" disabled [(ngModel)]="taxDet.endDate" name="endDate" placeholder="Date Fin" [value]="taxDet.endDate">
    <input class="modifiertaxeinput" [(ngModel)]="taxDet.value" name="value" [value]="taxDet.value" placeholder="Valeur">
  </div>
  <div class="col-lg-12 d-flex justify-content-start">
  <button mat-raised-button color="secondary" type="button" (click)="addDateValueField()">Ajouter Date <i class="material-icons">edit_calendar</i>
  </button>
</div>
  </div>
  
  </div>
  <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

  <div class="row d-flex justify-content-center">
    <!-- <div class="col-lg-6 d-flex justify-content-start">
    <button mat-raised-button color="secondary" type="button" (click)="addDateValueField()">Ajouter Date</button>
    </div> -->
  <div class="col-lg-12 d-flex justify-content-end">
    <button class="modifiertaxebtn" type="button" (click)="taxeUpdate(taxeget.id);closeDialogupdate()">Sauvegarder</button>
    </div>
    </div>
</ng-template>