import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Company} from '../Company-Inventory/entity/company';
import {Product} from '../Company-Inventory/entity/Product';
import {CompanyInventoryService} from '../../services/company-inventory.service';
import {PricetableEngineService} from '../../services/pricetable-engine.service';
import {PriceTableInventoryServiceService} from '../../services/price-table-inventory-service.service';
import {ProductFull} from '../Company-Engine/entity/ProductFull';
import {TablePrice} from '../PriceTableEngine/entity/TablePrice';
import {RulesInventoryService} from '../../services/rules-inventory.service';
import {RulesEngineService} from '../../services/rules-engine.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-rules-inventory',
  templateUrl: './rules-inventory.component.html',
  styleUrls: ['./rules-inventory.component.css']
})
export class RulesInventoryComponent implements OnInit {
  companysList:Company[]=[];
  productsList:Product[]=[];
  tablePriceList:TablePrice[]=[];
  selectedCompany:Company=new Company();
  selectedProduct:ProductFull=new ProductFull();
  selectedPriceTable:TablePrice=new TablePrice();
  prePricingMethod:string="";
  postPricingMethod:string="";
  contractStructure:string="";
  contractStructureNbRows=0;
  dialogRef: MatDialogRef<any>; // Declare dialogRef as a class property
  engineType: string ="";

  @ViewChild('monacoeditorpopuppre') monacoeditorpopuppre: TemplateRef<any>;
  @ViewChild('monacoeditorpopupprebigger') monacoeditorpopupprebigger: TemplateRef<any>;
  @ViewChild('monacoeditorpost') monacoeditorpost: TemplateRef<any>;
  @ViewChild('monacoeditorpostbigger') monacoeditorpostbigger: TemplateRef<any>;


  constructor(private rulesEngineService:RulesEngineService,private rulesInventoryService:RulesInventoryService,private companyInventoryService:CompanyInventoryService,private pricetableEngineService:PricetableEngineService,private  priceTableInventoryServiceService:PriceTableInventoryServiceService, private elementRef: ElementRef,private dialog:MatDialog) { }


  ngOnInit(): void {
  }

  dialogWidth: string = 'fit-content'; // Initial width
  dialogHeight: string = 'auto'; // Initial width

  openDialogmonaco(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpopuppre, {
      minWidth: this.dialogWidth,
      minHeight: this.dialogHeight,
    });
    console.log(this.dialogWidth,this.dialogHeight);
  }
  
  openDialogmonacobigger(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpopupprebigger, {
      minWidth: '100vw',
      minHeight: '90vh',
    });
    console.log(this.dialogWidth,this.dialogHeight);
  }
  
  openDialogmonacopost(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpost, {
      minWidth: this.dialogWidth,
      minHeight: this.dialogHeight,
    });
    console.log(this.dialogWidth,this.dialogHeight);
  } 
  openDialogmonacopostbigger(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpostbigger, {
      minWidth: '100vw',
      minHeight: '90vh',
    });
    console.log(this.dialogWidth,this.dialogHeight);
  }


  editorOptions = {theme: 'vs-dark', language: 'python', automaticLayout: true};
  code: string= 'function x() {\nconsole.log("Hello world!");\n}';

  onChangeCompany(companyId:number){
    for(let c of this.companysList){
      if(c.id==companyId){
        this.selectedCompany=c
        this.companyInventoryService.getCompanyProducts(c.id).subscribe((data:Product[]) =>{
          this.productsList=data;
        });
      }
    }
  }

  onChangeType(type:string){
    this.engineType = type ; 
    if (this.engineType == 't') {
      this.companyInventoryService.getAllCompanys().subscribe((data:Company[])=>{
        this.companysList=data;
      });
    }
  }

  onChangeProduct(productId:number){
    this.selectedProduct.id=productId;
    this.rulesEngineService.getContractStructure(productId.toString()).subscribe((data: string)=>{
      this.contractStructure=data;
      this.onChangeContructStructure()
    });
    this.priceTableInventoryServiceService.getByProductId(this.selectedProduct.id).subscribe((data:TablePrice[])=>{
      this.tablePriceList=data;
    })
  }

  onChangePriceTable(priceTableId: number) {
    this.selectedPriceTable.id=priceTableId;
    this.rulesInventoryService.getPreRule(priceTableId).subscribe((data:any)=>{
      this.prePricingMethod=data;
    },
      error => this.prePricingMethod=""
    );
    this.rulesInventoryService.getPostRule(priceTableId).subscribe((data:any)=>{
      this.postPricingMethod=data;
    },
      error => this.prePricingMethod="")

  }

  updateMethod(type:string) {
    if(type=="pre"){
      if(this.prePricingMethod==null) this.prePricingMethod="";
      this.rulesInventoryService.addPreRule(this.selectedPriceTable.id,this.prePricingMethod).subscribe(data=>alert("Rule changed!"), err=> alert("Error"))
    }

    if(type=="post"){
      if(this.postPricingMethod==null) this.postPricingMethod="";
      this.rulesInventoryService.addPostRule(this.selectedPriceTable.id,this.postPricingMethod).subscribe(data=>alert("Rule changed!"), err=> alert("Error"))
    }
  }

  onChangeContructStructure() {
     var nb_rows = this.contractStructure.split(/\r*\n/).length;
     this.contractStructureNbRows=nb_rows;

  }
  panelOpenState = false;

}
