export class TaxeCategoryDto {    
    label : string;
    constructor(label: string) {
        this.label = label;
      }
}

export class TaxeDto {
  categoryId: number;
  label: string;
  perUnit: boolean;
  pourcentage: boolean;
  taxeVersion: TaxeVersionDto[];


  constructor(
    categoryId: number,
    label: string,
    perUnit: boolean,
    pourcentage: boolean,
    taxeVersion: TaxeVersionDto[]
  ) {
    this.categoryId = categoryId;
    this.label = label;
    this.perUnit = perUnit;
    this.pourcentage = pourcentage;
    this.taxeVersion = taxeVersion;
  }
}

export class TaxeVersionDto {
  endDate: string;
  startDate: string;
  value: number;

  constructor(endDate: string, startDate: string, value: number) {
    this.endDate = endDate;
    this.startDate = startDate;
    this.value = value;
  }
}


export class ModelDto {
  idModel: number;
  taxesIds:number[]; }

  export class ProductDto {
    modelId: number;
    productId: number;
    taxesIds: number[];}