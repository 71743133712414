import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';

import { environment } from '../../../../environments/environment';
import { ModelInventoryService } from '../../../services/model-inventory.service';
import { Coverage } from '../../Coverage-Inventory/entity/Coverage';
import { Productline } from '../../Coverage-Inventory/entity/productLine';
import { FormControl } from '../entity/FormControl';
import { InsuredModel } from '../entity/InsuredModel';
import { Option } from '../entity/Option';
import { CategoryOption, CategoryPossibleValues } from '../entity/possibleOpt/CategoryOption';
import {
  CheckBoxInput,
  DateInput,
  NumberInput,
  RadioInput,
  RadioPossibleValue,
  RangeInput,
  RangePossibleValues,
  TextInput,
} from '../entity/possibleOpt/InputOption';
import { SelectOption, SelectPossibleValues } from '../entity/possibleOpt/SelectOption';
import { TextAreaOption } from '../entity/possibleOpt/TextAreaOption';
import { TaxesService } from 'src/app/services/taxes.service';
import { ModelDto } from '../../Taxes/entities/dto/dtos';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import { Taxe } from '../../Taxes/entities/models/models';
import { ProductlineDto } from '../../Coverage-Inventory/coverages-list/entities/dtos/coveragedtos';
@Component({
  selector: 'app-update-insured-model',
  templateUrl: './update-insured-model.component.html',
  styleUrls: ['./update-insured-model.component.css']
})
export class UpdateInsuredModelComponent implements OnInit {


  itemsList: Option[]=[];
  toggle=false;

  globalOptionsList: Option[]=[];
  insuredOptionsList: Option[]=[];

  formControlList: FormControl[] = [];
  InsuredModelForm: FormGroup;
  formControl: FormGroup;
  option: Option;
  optionsList: Option[] = [];
  selectedInsuredModel:InsuredModel=new InsuredModel();

  searchStringFormControl: string;

  formControleTargetOrd:number=0;
  insuredModelId:any;
  formControleTargetList:string="";


  coveragesList = [];
  allcoverages = [];
  selectedCoverages : Coverage [] = [];
  selectedCoveragesFinal : Coverage [] = [];
  dropdownSettings :IDropdownSettings = {} ; 
  dropdownSettingsCoverages :IDropdownSettings = {} ; 
  dropdownSettingsTaxes :IDropdownSettings = {} ; 
  coverage:Coverage ; 
  productLine = new ProductlineDto() ; 
  taxemodel: ModelDto[]=[];
taxe:Taxe[]=[]
taxes:Taxe
selectedTaxIds:number[] = [];  
modelTaxes: Taxe[]=[]
selectedCoveragesCodes : any [] = [];

  constructor(private taxesService : TaxesService, private formBuilder: FormBuilder,private activeRoute: ActivatedRoute, private route:Router,private modelInventoryService: ModelInventoryService,private coverageInventoryService:CoverageInventoryService) { 

  }

  selectedTaxes: Taxe[] = []; // Initialize selected taxes array

  taxeinfo = {
    idModel:0,
    taxeid:[],
  };

  async ngOnInit() {
    this.insuredModelId = this.activeRoute.snapshot.paramMap.get('id');
    this.modelInventoryService.getById(Number(this.insuredModelId)).subscribe(
      (data:InsuredModel)=>{
        this.selectedInsuredModel=data;
        this.rebuildParameters();
      },error =>
      {
        alert("selected insured model is invalid!")
      }
    )
    this.getProductCoverages(this.insuredModelId); 
    this.getAllCoverages();
    this.initOptionsList();
    this.initForm();
    this.UpdateFormControleList();
    try{
    await this.getModelTaxesbyId(Number(this.insuredModelId));
    await this.getTax()
    this.getItemsInList(this.taxe,this.modelTaxes)      
    
    this.selectedTaxes = this.getItemsInList(this.taxe, this.modelTaxes);
    console.log(this.modelTaxes);
console.log(this.taxe);
console.log(this.selectedTaxes);

    }catch (error) {
      console.error(error);
      alert("An error occurred while initializing data.");
    }}

  public existInList(searchList: Taxe[],element:Taxe): boolean {
    return searchList.some(item => item.id=== element.id);
  } 

   getItemsInList(searchList: Taxe[], elements: Taxe[]): Taxe[] {
    return elements.filter(element => searchList.some(item => item.id === element.id));
  }

  // getModelTaxesbyId(id:number){
  //   this.taxesService.getModelTaxesbyId(id).subscribe((datamodel:CustomResponse)=>{
  //     this.modelTaxes=datamodel.response;
  //     console.log("modelTaxes from  getModelTaxesbyId",this.modelTaxes)

  //   },
  //   (error) => {
  //     console.error("An error occurred whehn getting taxes:", error);
  
  //   });
  // }

  // getTax(){
  //   this.taxesService.getAllTaxe().subscribe((data:CustomResponse)=>{
  //     this.taxe= data.response ;
  //     console.log("modelTaxes from  getTax",this.taxe)

  //   })
  // } 


  async getModelTaxesbyId(id: number) {
    try {
      const datamodel: any = await this.taxesService.getModelTaxesbyId(id).toPromise();
      this.modelTaxes = datamodel.response;
      console.log("modelTaxes from getModelTaxesbyId", this.modelTaxes);
    } catch (error) {
      console.error("An error occurred when getting taxes:", error);
    }
  }

  async getTax() {
    try {
      const data: any = await this.taxesService.getAllTaxe().toPromise();
      this.taxe = data.response;
      console.log("modelTaxes from getTax", this.taxe);
      this.dropdownSettingsTaxes = {
        singleSelection: false,
        idField: 'id',
        textField: "label",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 4,
        allowSearchFilter: true,
        showSelectedItemsAtTop: true
      }; 
    } catch (error) {
      // Handle errors here if needed
      console.error("Error in getTax:", error);
    }
  }


  private rebuildParameters(){
    for (let opt of this.selectedInsuredModel.parameters) {
      if (opt.typeProperty == 3)
      {
        this.globalOptionsList.push(this.caster(opt))
      }
      else {
        this.insuredOptionsList.push(this.caster(opt))
      }
    }
    this.InsuredModelForm.patchValue({
      label : this.selectedInsuredModel.label,
      description : this.selectedInsuredModel.description ,
         })
    this.sortArrays();
    /*
    @ToDo
      // split this.selectedInsuredModel option into this.globalOptionsList and this.initOptionsList()
      //set this.InsuredModelForm.label to this.selectedInsuredModel.label
      //set this.InsuredModelForm.description to this.selectedInsuredModel.description
   */

  }

  caster(opt:any){
    let selectOpt:any=null;
    if(opt.possibleOption.type=='Select'){
      selectOpt= new SelectOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='TextArea'){
      selectOpt= new TextAreaOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='Category'){
      selectOpt= new CategoryOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='Input'){
      if(opt.possibleOption.typeInput=='text'){
        selectOpt=new TextInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='number'){
        selectOpt=new NumberInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='date'){
        selectOpt=new DateInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='radio'){
        selectOpt=new RadioInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='range'){
        selectOpt=new RangeInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='checkbox'){
        selectOpt=new CheckBoxInput(opt.possibleOption);
      }
    }
    opt.possibleOption=selectOpt;
    return opt;
  }
  sortArrays(){
    this.globalOptionsList = this.globalOptionsList.sort((n1,n2) => {
      if (n1.ord > n2.ord) {
        return 1;
      }

      if (n1.ord < n2.ord) {
        return -1;
      }

      return 0;
    });
    this.insuredOptionsList = this.insuredOptionsList.sort((n1,n2) => {
      if (n1.ord > n2.ord) {
        return 1;
      }

      if (n1.ord < n2.ord) {
        return -1;
      }

      return 0;
    });

  }

  private initOptionsList(){
    this.itemsList=[]

    let option = new Option();
    option.label="Text";
    option.possibleOption = new TextInput();
    this.itemsList.push(option);

    option = new Option();
    option.label="Radio";
    let radioInput = new RadioInput();
    radioInput.radioPossibleValues = [];
    radioInput.radioPossibleValues.push(new RadioPossibleValue("label_1", this.GenerateRandomStr()));
    option.possibleOption = radioInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="Category";
    let categoryInput = new CategoryOption();
    categoryInput.possibleValues = [];
    categoryInput.possibleValues.push(new CategoryPossibleValues("label_1","choice1\nchoice2\nchoice3"));
    option.possibleOption = categoryInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="Multi-Options Select";
    let selectOption = new SelectOption();
    selectOption.selectPossibleValues = [];
    selectOption.selectPossibleValues.push(new SelectPossibleValues("label_1", this.GenerateRandomStr()));
    option.possibleOption = selectOption;
    this.itemsList.push(option);

    option = new Option();
    option.label="Text Area";
    let textAreaOption = new TextAreaOption();
    textAreaOption.setRegex("");
    option.possibleOption = textAreaOption;
    this.itemsList.push(option);

    option = new Option();
    option.label="Date";
    option.possibleOption = new DateInput();
    this.itemsList.push(option);

    option = new Option();
    option.label="Range";
    let rangeInput = new RangeInput();
    rangeInput.min = 0;
    rangeInput.max = 0;
    rangeInput.ranges=[];
    rangeInput.ranges.push(new RangePossibleValues(0, 15,'label_1','<15'));
    option.possibleOption = rangeInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="Number";
    let numberInput = new NumberInput();
    numberInput.setmin(0);
    numberInput.setmax(0);
    numberInput.setstep(0);
    numberInput.setRegex("");
    option.possibleOption = numberInput;
    this.itemsList.push(option);

    option = new Option();
    option.label="CheckBox";
    option.possibleOption = new CheckBoxInput();
    this.itemsList.push(option);


  }

  private initForm() {

    this.InsuredModelForm = this.formBuilder.group({
      label: '',
      description: '',
            });
    this.formControl = this.formBuilder.group({
      label:'',
      uniqueIdentifier:''
    })
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      if(event.previousContainer.id=="cdk-drop-list-init-0"){
        this.initOptionsList();
      }

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      if(event.container.id=="cdk-drop-list-global-0"){
        event.container.data[event.currentIndex].typeProperty=3;
      }else{
        event.container.data[event.currentIndex].typeProperty=2;
      }

    }

    for (let i = 0; i < event.container.data.length; i++) {
      event.container.data[i].ord=i;
    }
    for (let i = 0; i < event.previousContainer.data.length; i++) {
      event.previousContainer.data[i].ord=i;
    }

  }

  startDrag() {

  }

  deleteInsuredOptionsList(i: number) {
    this.insuredOptionsList.splice(i,1)
  }

  deleteGlobalOptionsList(i: number) {
    this.globalOptionsList.splice(i,1)
  }


  noReturnPredicate(){
    return false;
  }

  saveInsuredModel() {

    let im = new InsuredModel(this.InsuredModelForm.value);

    this.globalOptionsList.forEach(element=>{
      im.parameters.push(element);
    });

    this.insuredOptionsList.forEach(element=>{
      im.parameters.push(element);
    });
    im.parameters.forEach(parameter=>{
      parameter.formControl.label = parameter.label
    })
    this.modelInventoryService.updateInsuredModel(im,this.insuredModelId).subscribe(data=>{
        this.updateCoverages(data); 
        alert("Modèle ajouté avec succès");
        this.route.navigate(["/pages/"+environment.insuredModelURL]);
      },
      error => {
        alert("Veuillez revoir votre formulaire, vous avez peut-être fait une configuration manquée");
      });

  }

  retour() {
    this.route.navigate(["/pages/"+environment.insuredModelURL])
  }

  setOptionOrder() {

  }

  GenerateRandomStr(){
    var chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var length=18;
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  setFormControlUniqueIdentifier() {

  }

  saveFormControl() {
    let fc = new FormControl(this.formControl.value);
    this.modelInventoryService.saveFormControl(fc).subscribe(data=>{
      this.UpdateFormControleList();
      this.formControl.reset();
    });
  }

  selectFormControl(id: number) {
    for(let formControl of this.formControlList){
      if(formControl.id==id){
        if(this.formControleTargetList=="global"){
          this.globalOptionsList[this.formControleTargetOrd].formControl=formControl;
          this.fillConfigName("global");
        }else if(this.formControleTargetList=="insured")
        {
          this.insuredOptionsList[this.formControleTargetOrd].formControl=formControl;
          this.fillConfigName("insured");
        }

      }
    }
  }

  fillConfigName(label:string) {
    if(label=="global"){
      this.globalOptionsList[this.formControleTargetOrd].configName=this.field_name_normalizer(this.globalOptionsList[this.formControleTargetOrd].label);
    }else{
      this.insuredOptionsList[this.formControleTargetOrd].configName=this.field_name_normalizer(this.insuredOptionsList[this.formControleTargetOrd].label);
    }
  }

  field_name_normalizer(str:string){
    str.replace("\s","_")
    str.replace("^(\d)+","_\\1")
    return str+'_'+this.GenerateRandomStr();
  }

  UpdateFormControleList() {
    this.modelInventoryService.getFormControlList().subscribe((data:FormControl[])=>{
      this.formControlList=data;
    })
  }


  FormControlIdSetter($event: number) {
    this.formControleTargetOrd=$event;
  }

  setListNameFormControlSetter($event: string) {
    this.formControleTargetList=$event;
  }

  /*---------------------------------------------------CoveragesSettings----------------------------------------------------------------*/

  onItemSelect(item: any) {
    console.log("selectedcoverageslist",this.selectedCoverages)
    this.selectedCoverages.forEach((element,index) => {
      this.selectedCoveragesCodes[index] = element.code
    });
    console.log("coverage based on code ?",this.selectedCoveragesCodes)  }
  onItemSelectTaxe(item: Taxe) {
    this.getTax(); 
  }

  onItemDeselect(item: any) {
    this.selectedCoveragesFinal.forEach((element,index)=>{
      if(element.id == item.id) this.selectedCoveragesFinal.splice(index,1); 
    });
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  getCoverage(id:any){
    this.coverageInventoryService.getCoverageById(id).subscribe((data:Coverage) => {
      this.coverage = data ; 
      this.selectedCoveragesFinal.push(this.coverage);
      this.coverage= null ;  
    }); 

    this.selectedCoverages.forEach((element,index) => {
      this.selectedCoveragesCodes[index]=element.code
      console.log("selected coverage codes 2 :",this.selectedCoveragesCodes)
      
    });
  }

  addCoveragesToProductLine(coverages : any[],id:any){
      this.coverageInventoryService.addCoveragesToProductLine(coverages,id).subscribe(data => {
    });
  }

  addProductLineWithCoverages(im : any){
  
    this.productLine.coveragesCodes = this.selectedCoveragesCodes; 
    this.productLine.productLineId = im.id ; 

    this.coverageInventoryService.addProductLine(this.productLine).subscribe(data =>{
    });

  }

  getProductCoverages(id:number){
    this.coverageInventoryService.getCoveragesByProductLine(id).subscribe((data:CustomResponse)=>{
      this.coveragesList = data.response;
      this.selectedCoveragesFinal= data.response;
      this.selectedCoverages = data.response;
    
      this.dropdownSettingsCoverages = {
        singleSelection: false,
        idField: 'code',
        textField: "label",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 4,
        allowSearchFilter: true,
        showSelectedItemsAtTop: true
      }; 
    })
  } 
  getAllCoverages(){
    this.coverageInventoryService.getAllCoverages().subscribe((data:CustomResponse)=>{
      this.allcoverages = data.response;
      // this.selectedCoveragesFinal= data.response;
      // this.selectedCoverages = data.response;
    
      this.dropdownSettingsCoverages = {
        singleSelection: false,
        idField: 'code',
        textField: "label",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 4,
        allowSearchFilter: true,
        showSelectedItemsAtTop: true
      }; 
    })
  }

  updateCoverages(dt:any){

    this.coverageInventoryService.updateCoveragesInProductLine(dt.id,this.selectedCoverages).subscribe(data => {

    });
  }







  taxeModelUpdate(){
    this.selectedTaxes.forEach((element,index) => {
      this.selectedTaxIds[index]=element.id
      
    });
    const modelDto: ModelDto = {
      idModel:this.insuredModelId,
      taxesIds:this.selectedTaxIds,
    };

    this.taxesService.updateModelTaxes(modelDto).subscribe(
       (data) => {
        console.log("post IDentifier :",this.selectedTaxIds);

       },
       error => {
        console.log("post IDentifier :",this.insuredModelId);
         console.error("Error changing status:", error);
         
       }
     );
 console.log("post IDentifier :",this.taxe);
   }



}
