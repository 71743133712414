<div class="container" style="margin-top: 100px;">
  <div class="row" style="margin-bottom: 100px">
    <div class="col">
      <h1>New Insured Model:</h1>
    </div>
  </div>
  <form [formGroup]="InsuredModelForm" (ngSubmit)="saveInsuredModel()">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="label">Label</label>
          <input type="text" class="form-control" id="label" formControlName="label">
        </div>
      </div>
    </div>

    <div class="row align-items-end">
      <div class="col-6">
        <div class="form-group">
          <label for="description">Description</label>
          <textarea row="6" class="form-control" id="description" formControlName="description"></textarea>
        </div>
      </div>
      <div class="col-6 text-right">
        <button type="button" class="btn btn-secondary" style="margin: 10px 15px 10px 0px;"><i
          class="fa fa-folder-open"></i> Load from template
        </button>
        <button type="button" class="btn btn-primary" style="margin: 10px 0px 10px 0px;" data-toggle="modal" (click)="setOptionOrder()"
                data-target="#SelectOption"><i class="fa fa-plus"></i> Ajouter un option
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table text-center">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Label</th>
            <th scope="col">Autofill</th>
            <th scope="col">Tarification Axe</th>
            <th scope="col">Required Adhesion</th>
            <th scope="col">Property Type</th>
            <th scope="col">Parameters</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let opt of optionsList; let i= index;">
            <th scope="row">{{i + 1}}</th>
            <td>{{opt.label}}</td>
            <td>
              <i *ngIf="!opt.autofill" class="fa fa-times"></i>
              <i *ngIf="opt.autofill" class="fa fa-check"></i>
            </td>

            <td>
              <i *ngIf="!opt.requiredTarif" class="fa fa-times"></i>
              <i *ngIf="opt.requiredTarif" class="fa fa-check"></i>
            </td>
            <td>
              <i *ngIf="!opt.requiredAdhesion" class="fa fa-times"></i>
              <i *ngIf="opt.requiredAdhesion" class="fa fa-check"></i>
            </td>
            <td>
              <p *ngIf="opt.typeProperty==1">Adherent property</p>
              <p *ngIf="opt.typeProperty==2">Product property</p>
              <p *ngIf="opt.typeProperty==3">Contract property</p>
            </td>
            <td>
              <button type="button" class="btn btn-warning"><i class="fa fa-edit"></i> </button>
              <button type="button" class="btn btn-danger" (click)="deleteOptionByIndex(i)"><i class="fa fa-trash"></i> </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button class="btn btn-success" type="submit"><i class="fa fa-save"></i> Save</button>
      </div>
      <div class="col text-right">
        <button type="button" (click)="retour()" class="btn btn-info"><i class="fa fa-undo"></i> Return</button>
      </div>
    </div>
  </form>
</div>

<!-- Modal-->
<div class="modal fade " id="OptionsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Option</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="optionForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group">
            <label for="descText">Label</label>
            <input type="text" rows="4" id="labeloption" class="form-control" formControlName="label">
          </div>
          <div class="form-group">
            <label for="nomText">Form Control</label>
            <div class="row">
              <div class="col-10">
                <input class="form-control" type="text" value="{{selectedFormControl.label}}" >
              </div>
              <div class="col-2">
                <button type="button" data-toggle="modal" data-target="#MainModel" class="btn btn-warning">
                  <i class="fa fa-folder-open"></i>
                </button>
              </div>
            </div>
            <input type="hidden">
          </div>
          <div class="form-group" style="display: none">
            <label for="nomText">configuration Name</label>
            <input id="nomText" class="form-control" type="text" formControlName="configName">
          </div>
          <div class="form-group"  style="display: none">
            <label for="nomText">Plot Order</label>
            <input id="ord" class="form-control" type="number" formControlName="ord">
          </div>
          <div class="form-group">
            <label for="descText">Description</label>
            <textarea rows="4" id="descText" class="form-control" formControlName="description"></textarea>
          </div>
          <div class="row">
            <div class="col-4 text-center">
              <div class="form-group">
                <label for="reqTarifCheck">Tarification Axe</label>
                <input id="reqTarifCheck" class="form-control" type="checkbox" formControlName="requiredTarif" >
              </div>
            </div>
            <div class="col-5 text-center">
              <div class="form-group">
                <label for="reqAdhCheck">Required Adhesion</label>
                <input id="reqAdhCheck" class="form-control" type="checkbox" formControlName="requiredAdhesion">
              </div>
            </div>
            <div class="col-3 text-center">
              <div class="form-group">
                <label for="autofillCheck">Autofill</label>
                <input id="autofillCheck" class="form-control" type="checkbox" formControlName="autofill">
              </div>
            </div>
          </div>

          <form [formGroup]="typeOptionForm">
            <div class="form-group">
              <label for="typeSelect">Option</label>
              <select (change)="onChangeOptionType()" class="form-control" id="typeSelect" formControlName="typeOption">
                <option value="" [defaultSelected]>Choose ...</option>
                <option value="TextInput">Text</option>
                <option value="NumberInput">Number</option>
                <option value="RadioInput">Radio</option>
                <option value="SelectOption">Multi-Options Select</option>
                <option value="CategoryInput">Category</option>
                <option value="DateInput">Date</option>
                <option value="CheckBoxInput">CheckBox</option>
                <option value="TextAreaOption">Text Area</option>
                <option value="RangeInput">Range</option>
              </select>
            </div>
          </form>

          <div class="form-group">
            <label for="typeSelect">Privacy</label>
            <select class="form-control" id="typeOptionSelect" formControlName="typeProperty">
              <option value="" [defaultSelected]>Choose ...</option>
              <option value="1">Adherent property</option>
              <option value="2">Product property</option>
              <option value="3">Contract property</option>
            </select>
          </div>
          <!-- OPTIONS -->
          <!-- Select + Radio -->
          <div class="row" *ngIf="typeOptionForm.value['typeOption']=='SelectOption' || typeOptionForm.value['typeOption']=='RadioInput'">
            <div class="col-12">
              <form [formGroup]="possibleOptionForm">
              <div class="table-responsive col-12 form-group">
                <table id="sort2" class="grid table table-bordered table-sortable">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Label</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let i of possibleVals;let index=index">
                    <td data-id="11">{{index + 1}}</td>
                    <td><input type="text" value="{{i.label}}" class="form-control"  disabled></td>

                    <td>
                      <button class="btn btn-danger">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td data-id="11">{{nbPossibleOpts}}</td>
                    <td><input type="text" value="" class="form-control" formControlName="label"></td>
                    <td>
                      <button type="button" (click)="addPossibleOpt()" class="btn btn-primary">
                        <i class="fa fa-plus"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </form>
            </div>
          </div>
          <!-- Category -->
          <div class="row" *ngIf="typeOptionForm.value['typeOption']=='CategoryInput'">
            <form [formGroup]="possibleOptionForm">
              <div class="table-responsive col-md-12 form-group">
                <table id="sort3" class="grid table table-bordered table-sortable">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Label</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let i of possibleVals;let index=index">
                    <td data-id="11">{{index + 1}}</td>
                    <td><input type="text" value="{{i.label}}" class="form-control" disabled></td>
                    <td><textarea rows="4" value="{{i.val}}" class="form-control" disabled></textarea></td>
                    <td>
                      <button class="btn btn-danger">
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td data-id="11">{{nbPossibleOpts}}</td>
                    <td><input type="text" value="" class="form-control" formControlName="label"></td>
                    <td><textarea rows="4" value="" class="form-control" formControlName="val"></textarea></td>
                    <td>
                      <button type="button" (click)="addPossibleOptCategory()" class="btn btn-primary">
                        <i class="fa fa-plus"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
          <!-- Text + TextArea -->
          <form [formGroup]="regexForm" *ngIf="typeOptionForm.value['typeOption'] == 'TextAreaOption' || typeOptionForm.value['typeOption'] == 'TextInput' ">
            <div class="form-group row">
              <label for="regexInput" class="col-sm-2 col-form-label">Regex:</label>
              <div class="col-sm-10">
                <input id="regexInput" class="form-control" formControlName="regex" type="text">
              </div>
            </div>
          </form>
          <!-- Number -->
          <form [formGroup]="NumberInputOption" *ngIf="typeOptionForm.value['typeOption'] == 'NumberInput'">
            <div class="form-group row">
              <label for="regexInput" class="col-sm-2 col-form-label">Min:</label>
              <div class="col-sm-10">
                <input  class="form-control" formControlName="min" type="number">
              </div>
            </div>
            <div class="form-group row">
              <label for="regexInput" class="col-sm-2 col-form-label">Max:</label>
              <div class="col-sm-10">
                <input  class="form-control" formControlName="max" type="number">
              </div>
            </div>
            <div class="form-group row">
              <label for="regexInput" class="col-sm-2 col-form-label">Step:</label>
              <div class="col-sm-10">
                <input  class="form-control" formControlName="step" type="number">
              </div>
            </div>
            <div class="form-group row">
              <label for="regexInput" class="col-sm-2 col-form-label">Regex:</label>
              <div class="col-sm-10">
                <input  class="form-control" formControlName="regex" type="text">
              </div>
            </div>
          </form>
          <!-- Range -->
          <form [formGroup]="rangeOptionForm" *ngIf="typeOptionForm.value['typeOption'] == 'RangeInput'  ">
            <div class="form-group row" >
              <label for="rangeMin" class="col-sm-2 col-form-label">Min:</label>
              <div class="col-sm-10">
                <input id="rangeMin" class="form-control" formControlName="min" type="number">
              </div>
              <br/>
              <label style="margin-top: 15px;" for="rangeMax" class="col-sm-2 col-form-label">Max:</label>
              <div style="margin-top: 15px;" class="col-sm-10">
                <input id="rangeMax" class="form-control" formControlName="max" type="number">
              </div>
            </div>
            <div class="row">
              <form [formGroup]="possibleOptionForm">
                <div class="table-responsive col-md-12 form-group">
                  <table id="sort4" class="grid table table-bordered table-sortable">
                    <thead>
                    <tr>
                      <th>Range</th>
                      <th>Label</th>
                      <th>min</th>
                      <th>max</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let i of possibleVals;let index=index">
                      <td data-id="11">{{index + 1}}</td>
                      <td><input type="text" value="{{i.label}}" class="form-control" disabled></td>
                      <td><input type="text" value="{{i.min}}" class="form-control" disabled></td>
                      <td><input type="text" value="{{i.max}}" class="form-control" disabled></td>
                      <td>
                        <button class="btn btn-danger">
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td data-id="11">{{nbPossibleOpts}}</td>
                      <td><input type="text" value="" class="form-control" formControlName="label" ></td>
                      <td><input type="number" value="" class="form-control" formControlName="min"></td>
                      <td><input type="number" value="" class="form-control" formControlName="max"></td>
                      <td>
                        <button type="button" (click)="addPossibleOpt()" class="btn btn-primary">
                          <i class="fa fa-plus"></i>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </form>
          <!-- End OPTIONS -->
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Select Form Control Modal -->
<div class="modal fade bd-example-modal-lg" id="MainModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleMainModel">Select Form Control</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-bottom: 20px;">
          <div class="col-4">
            <div class="input-group md-form form-sm form-1 pl-0">
              <div class="input-group-prepend">
            <span class="input-group-text purple lighten-3" id="basic-text1">
              <i class="fa fa-search"></i>
              </span>
              </div>
              <input [(ngModel)]="searchStringFormControl" class="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search">
            </div>
          </div>
          <div class="col-5"></div>
          <div class="col-3 text-right">
            <button class="btn btn-success"  data-toggle="modal" (click)="UpdateFormControleList()" data-target="#addFormControlModal">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table text-center">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Label</th>
                <th scope="col">Select</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fc of formControlList | filter:'label' : searchStringFormControl ; let i = index">
                  <th>{{i+1}}</th>
                  <td>{{fc.label}}</td>
                  <td>
                    <button  type="button" (click)="selectFormControl(fc.id)"   data-toggle="modal" data-target="#fillModelContainer" data-dismiss="modal" class="btn btn-warning"> <i class="fa fa-check-circle"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<!-- Add Form Control Modal -->
<div class="modal fade" id="addFormControlModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Add Form Control</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formControl">
          <div class="form-group">
            <label for="nomText">Label</label>
            <input  class="form-control" type="text" formControlName="label" (change)="setFormControlUniqueIdentifier()">
          </div>
          <div class="form-group" style="display: none">
            <label for="nomText">Unique Identifier</label>
            <input  class="form-control" type="text" formControlName="uniqueIdentifier" >
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveFormControl()">Save changes</button>
      </div>
    </div>
  </div>
</div>

<!-- Select Option Modal -->
<div class="modal fade bd-example-modal-lg" id="SelectOption" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Select Option</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" style="margin-bottom: 20px;">
          <div class="col-4">
            <div class="input-group md-form form-sm form-1 pl-0">
              <div class="input-group-prepend">
            <span class="input-group-text purple lighten-3">
              <i class="fa fa-search"></i>
              </span>
              </div>
              <input [(ngModel)]="searchStringOption" class="form-control my-0 py-1" type="text" placeholder="Search" aria-label="Search">
            </div>
          </div>
          <div class="col-5"></div>
          <div class="col-3 text-right">
            <button class="btn btn-success"  data-toggle="modal" data-dismiss="modal" (click)="UpdateAllOptionsList()"  data-target="#OptionsModal">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table text-center">
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Label</th>
                <th scope="col">Parameters</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let opt of allOptionsList | filter:'configName' : searchStringOption ; let i = index">
                <th>{{i+1}}</th>
                <td>{{opt.formControl.label}}</td>
                <td>
                  <button  type="button" (click)="addOption(opt)"   data-toggle="modal" data-target="#fillModelContainer" data-dismiss="modal" class="btn btn-warning"> <i class="fa fa-check-circle"></i></button>
                  <button  type="button" (click)="deleteOption(opt.id)"   class="btn btn-danger"> <i class="fa fa-trash"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
