import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CompanyInventoryService } from 'src/app/services/company-inventory.service';
import { Company } from '../Company-Inventory/entity/company';
import { User } from 'src/app/_models/User';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-custom-product-fill',
  templateUrl: './custom-product-fill.component.html',
  styleUrls: ['./custom-product-fill.component.scss']
})
export class CustomProductFillComponent implements OnInit {
  @ViewChild('sidenav') sideNavElement:ElementRef;
  companyList:Company[]=[];
  productId: string;
  loadingCompanies: boolean = false;
  user: User = new User();

  constructor(private companyInventoryService: CompanyInventoryService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    // if(this.cookieService.check('currentUser')){
    //   this.user = JSON.parse(this.cookieService.get('currentUser'));
    //   console.log(this.user.role) ; 
    //   if(this.user.role == "TESTER"){
    //     document.getElementById('sidenav-content').style.marginLeft = '0px';
    //     document.getElementById('toggleSideNav').style.display = 'block';
    //     document.getElementById('sideNavBar').style.display = 'block';
    //   }
    // }
    this.listCompanies();
  }


  listCompanies(): void{
    this.loadingCompanies = true;
    this.companyInventoryService.getAllCompanys().subscribe((data:Company[])=>{
      this.companyList =data;
      this.loadingCompanies = false;
    },()=>{
      this.loadingCompanies = false;
    })
  }

}
