import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {TablePrice} from '../pages/PriceTableEngine/entity/TablePrice';

@Injectable({
  providedIn: 'root'
})
export class PricetableEngineService {
  service:string="PRICETABLE-ENGINE/";
  constructor(private http:HttpClient) { }

  getProductById(companyId:number,productId:number){
    return this.http.get(environment.baseUrl+this.service+"pricing/company/"+companyId+"/product/"+productId);
  }

  upload(file: File,id:number) {
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', environment.baseUrl+this.service+"pricing/verify/"+id, data, {
      reportProgress: true,
      responseType: 'text'

    });
    return this.http.request(newRequest);
  }
  uploadToFill(file: File,id:number) {
    const data: FormData = new FormData();
    data.append('file', file);
    const newRequest = new HttpRequest('POST', environment.baseUrl+this.service+"pricing/fill/"+id, data, {
      reportProgress: true,
      responseType: 'text'

    });
    return this.http.request(newRequest);
  }

  addTablePrice(tabelprice:TablePrice){
    return this.http.post(environment.baseUrl+this.service+"pricing/add",tabelprice,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })
    })
  }

  Generate_CSV(id: number) {
    return this.http.get(environment.baseUrl+this.service+"pricing/generate/datatable/"+id);
  }
}
