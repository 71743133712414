import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { environment } from 'src/environments/environment';
import { Coverage } from '../entity/Coverage';
import { CoverageDto } from '../coverages-list/entities/dtos/coveragedtos';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';

@Component({
  selector: 'app-edit-coverage',
  templateUrl: './edit-coverage.component.html',
  styleUrls: ['./edit-coverage.component.css']
})
export class EditCoverageComponent implements OnInit {

  CoverageEditForm: FormGroup;
  coverage:Coverage ; 
  coverageID : any ; 
  coverageCode : any ; 

  constructor(private router:Router,private activeRoute: ActivatedRoute ,private formBuilder: FormBuilder,private coverageInventoryService:CoverageInventoryService) { }

  ngOnInit(): void {
    this.coverageCode = this.activeRoute.snapshot.paramMap.get('code'); 
    this.getCoverage(this.coverageCode); 
    this.initForm(); 

  }

  private initForm() {

    this.CoverageEditForm = this.formBuilder.group({
      label: '',
      description: '', 
      code: '', 
      unite:'',
      active:true

    });
    
  }
  getCoverage(code:any){
    this.coverageInventoryService.getCoverageByCode(code).subscribe((data:CustomResponse) => {
      this.coverage = data.response ; 
      console.log(this.coverage,"that's the coverage ?");
      this.CoverageEditForm.patchValue({
        label : this.coverage.label,
        description : this.coverage.description ,
        code: this.coverage.code,
        //plafon:this.coverage.plafon,
        unite:this.coverage.unite
      })
    }); 
    
  }

  // updateCoverage(){
  //   let coverageUpdated = new Coverage(this.CoverageEditForm.value)

  //   this.coverageInventoryService.updateCoverage(coverageUpdated,this.coverageID).subscribe(data => {
  //     alert("Mise à jour avec succées ! "); 
  //     this.returnToList() ; 
  //       this.CoverageEditForm.reset(); 
  //   })
  // }


  
  CoverageUpdate(code:string){
    if (this.CoverageEditForm.valid) {
      const coveragedto: CoverageDto = {
        label: this.CoverageEditForm.get('label').value,
        description: this.CoverageEditForm.get('description').value,
        code: this.CoverageEditForm.get('code').value,
        unite: this.CoverageEditForm.get('unite').value,
      };

    this.coverageInventoryService.updateCoverage(code,coveragedto).subscribe(
       (data) => {
        window.location.reload();

       },
       error => {
         console.error("Error updating coverage :", error);
         
       }
     );
   }
  }


  

  returnToList(){
    this.router.navigate(['/'+environment.coverageUrl]); 
  }
}
