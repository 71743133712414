import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { ProductCoverageDto } from '../pages/Coverage-Inventory/coverages-list/entities/dtos/coveragedtos';

@Injectable({
  providedIn: 'root'
})
export class CompanyEngineService {

  service:string="COMPANY-ENGINE/";
  constructor(private http:HttpClient) { }

  getAllCompanys(){
    return this.http.get(environment.baseUrl+this.service+environment.companyURL+'/all');
  }

  getCompanyById(id:number){
    return this.http.get(environment.baseUrl+this.service+environment.companyURL+'/'+id)
  }


  getProductById(id:number){
      return this.http.get(environment.baseUrl+this.service+"/product/"+id)
    
  }
  
 
}
