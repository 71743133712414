

export class TotalCoverages {
  constructor(init?: Partial<TotalCoverages>) {
    Object.assign(this, init);
  }
  label:String ; 
  value : String ; 
  unite : String ;
}
