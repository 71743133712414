import { Component, OnInit } from '@angular/core';
import {Company} from '../../Company-Inventory/entity/company';
import {TablePrice} from '../entity/TablePrice';
import {DataTable} from '../entity/DataTable';
import {CompanyInventoryService} from '../../../services/company-inventory.service';
import {Product} from '../../Company-Inventory/entity/Product';
import {ProductFull} from '../../Company-Engine/entity/ProductFull';
import {PricetableEngineService} from '../../../services/pricetable-engine.service';
import {Option} from '../../Model-Inventory/entity/Option';
import * as $ from 'jquery';
import {PriceTableInventoryServiceService} from '../../../services/price-table-inventory-service.service';
import {Router} from '@angular/router';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-price-table-engine',
  templateUrl: './price-table-engine.component.html',
  styleUrls: ['./price-table-engine.component.css']
})
export class PriceTableEngineComponent implements OnInit {
  selectedPriceType:boolean ; 
  selectedPaymentFrequency: boolean ; 
  companysList:Company[]=[];
  productsList:Product[]=[];
  priceTable:TablePrice=new TablePrice();
  dataTableName:string;
  dataTablesList:DataTable[]=[];
  selectedProduct:ProductFull=new ProductFull();
  listAxes:Option[]=[];
  listVarOptions:Option[]=[];

  today: string = new Date().toISOString().split('T')[0];
  constructor(private companyInventoryService:CompanyInventoryService,private pricetableEngineService:PricetableEngineService,private  priceTableInventoryServiceService:PriceTableInventoryServiceService,private route:Router,private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon(
      `plusSign`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/plusSign.svg`)
    );
   
  }


  ngOnInit(): void {
    this.companyInventoryService.getAllCompanys().subscribe((data:Company[])=>{
      this.companysList=data;
    });
  }

  listBoxConfig(){
    this.listAxes=[];
    for (let insuredModelContainer of this.selectedProduct.insuredModelContainers){
      for(let p of insuredModelContainer.insuredModel.parameters){
        this.listAxes.push(p);
      }
    }
    $(function () {
      $('.list-group.checked-list-box .list-group-item').each(function () {
        // Settings
        var $widget = $(this),
          $checkbox = $('<input type="checkbox" style="display: none"/>'),
          color = ($widget.data('color') ? $widget.data('color') : "primary"),
          style = ($widget.data('style') == "button" ? "btn-" : "list-group-item-"),
          settings = {
            on: {
              icon: 'glyphicon glyphicon-check'
            },
            off: {
              icon: 'glyphicon glyphicon-unchecked'
            }
          };

        $widget.css('cursor', 'pointer')
        $widget.append($checkbox);

        // Event Handlers
        $widget.on('click', function () {
          $checkbox.prop('checked', !$checkbox.is(':checked'));
          $checkbox.triggerHandler('change');
          updateDisplay();
        });
        $checkbox.on('change', function () {
          updateDisplay();
        });


        // Actions
        function updateDisplay() {
          var isChecked = $checkbox.is(':checked');

          // Set the button's state
          $widget.data('state', (isChecked) ? "on" : "off");

          // Set the button's icon
          $widget.find('.state-icon')
            .removeClass()
            .addClass('state-icon ' + settings[$widget.data('state')].icon);

          // Update the button's color
          if (isChecked) {
            $widget.addClass(style + color + ' active');
          } else {
            $widget.removeClass(style + color + ' active');
          }
        }

        // Initialization
        function init() {

          if ($widget.data('checked') == true) {
            $checkbox.prop('checked', !$checkbox.is(':checked'));
          }

          updateDisplay();

          // Inject the icon if applicable
          if ($widget.find('.state-icon').length == 0) {
            $widget.prepend('<span class="state-icon ' + settings[$widget.data('state')].icon + '"></span>');
          }
        }
        init();
      });
    });
  }

  onChangeCompany(companyId:number){
    for(let c of this.companysList){
      if(c.id==companyId){
        this.productsList=c.products;
        this.priceTable.companyId=companyId;
      }
    }
  }
  onChangeProduct(productId:number){
    this.priceTable.productId=productId;
    this.pricetableEngineService.getProductById(this.priceTable.companyId,productId).subscribe((data:ProductFull)=>{
      this.selectedProduct=data;
      data.insuredModelContainers.forEach(modelContainer=>{
        modelContainer.insuredModel.parameters.forEach(opt=>{
          if(opt.requiredAdhesion){
            this.listVarOptions.push(opt);
            console.log(opt.formControl.label+" is req for adh")
          }
          else
            console.log(opt.formControl.label+" not req for adh")
        })
      })
    })
  }
  onclickFormulaBtn(val:string){
    this.priceTable.formula+=val;
  }
  onSave() { 
    this.priceTable.priceTypeTTC = this.selectedPriceType;
    this.priceTable.paymentAnnuel = this.selectedPaymentFrequency; 
    this.priceTableInventoryServiceService.addTablePrice(this.priceTable).subscribe(data=>{
      this.route.navigate(["/companys"]);
    })
  }
  addDataTable(){
    this.dataTablesList.push(this.createDataTabel())
    this.dataTableName="";
    this.priceTable.dataTables=this.dataTablesList;
  }

  createDataTabel():any{
    let dataTable:DataTable= new DataTable()
    dataTable.axes=[];
    dataTable.label=this.dataTableName;
    $("#check-list-box li.active").each(function(idx, li) {
      $(li).find("input.dataHolder").each(function(idx,input) {
        let opt=new Option()
        opt.id=$(input).val()
        opt.formControl.label=$(li).text()
        dataTable.axes.push(opt);
      })
    });
    return dataTable;
  }

  reset() {
    this.dataTablesList=[];
    this.priceTable.formula="";
    this.priceTable.label="";
    this.priceTable.effectiveDate="";
  }

  onclickFormulaBtnClear() {
    this.priceTable.formula="";
  }

  onclickFormulaBtnBackSpace() {
    this.priceTable.formula=this.priceTable.formula.slice(0, -1);
  }
}
