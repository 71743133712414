import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ModelInventoryService } from 'src/app/services/model-inventory.service';
import { RangeInput } from '../../Model-Inventory/entity/possibleOpt/InputOption';
import { Option } from '../../Model-Inventory/entity/Option';

@Component({
  selector: 'app-inputs-comparator',
  templateUrl: './inputs-comparator.component.html',
  styleUrls: ['./inputs-comparator.component.css']
})
export class InputsComparatorComponent implements OnInit {
  @Input() insuredFields: Option[]=[];
  @Input() insuredIndex: number;
  @Input() num_form: number;
  @Input() events: Observable<Map<any, any>>;
  @Output() formField = new EventEmitter()
  uniqueIdentifierField:Option=null;
  private eventsSubscription: Subscription;
  formCast: FormGroup;

  updateForm(insuredMap:Map<any, any>) {
    if(insuredMap.get(this.insuredIndex)==null) return;
    if(insuredMap.get(this.insuredIndex).mainInsured){
      this.modelInventoryService.findUniqueIdentifierOption().subscribe((data:Option)=>{
        this.formCast.addControl(data.id.toString(),new FormControl(''));
        this.uniqueIdentifierField=data;
      });
    }else{
      this.uniqueIdentifierField=null;
    }
  }

  constructor(private modelInventoryService:ModelInventoryService) { }

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe((insuredMap) => this.updateForm(insuredMap));
    this.formCast = new FormGroup({});
    this.insuredFields.forEach(x=>{
      if(x.possibleOption.typeInput in ['number','range'])
        this.formCast.addControl(x.id.toString(),new FormControl(0))
      else if(x.possibleOption.typeInput=='checkbox')
        this.formCast.addControl(x.id.toString(),new FormControl(false))
      else
        this.formCast.addControl(x.id.toString(),new FormControl(''))
    })
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  changeForm() {
    var dataForms:FormGroup=new FormGroup({});
    Object.keys(this.formCast.controls).forEach(key => {
      dataForms.addControl(key,new FormControl(this.formCast.controls[key].value))
    });
    this.insuredFields.forEach(opt=>{
        if(opt.possibleOption.typeInput=="range"){
          var  castedOption:RangeInput = new RangeInput(opt.possibleOption);
          var val =dataForms.controls[opt.id].value
          dataForms.controls[opt.id.toString()].setValue(val);
        }
    });
    this.formField.emit(dataForms)
  }

  ngOnChanges(changes: SimpleChanges){
    this.insuredFields.sort((a, b) => a.ord - b.ord);
  }


}
