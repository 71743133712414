<div class="mt-5">
    <div class="headPage">
        <div class="titlelist">
            <h1 class="titlemodel"><strong>Tarificateur</strong></h1>
        </div>
        <div>
            <h1 class="pathing"><strong>Système de Tarification</strong></h1>
        </div>
    </div>
    <!-- Select Product and price Table -->
    <div class="row mt-5">
        <div class="col-12">
            <form class="d-flex justify-content-between px-3" [ngClass]="{'justify-content-around': selectedProduct }">
                <mat-form-field appearance="outline">
                    <mat-label>Produits</mat-label>
                    <mat-select (selectionChange)="changeProduct($event.value)" required>
                        <mat-option disabled>Sélectionner un produit ...</mat-option>
                        <mat-option *ngFor="let p of company.products" [value]="p.id">{{p.title}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" *ngIf="selectedProduct">
                    <mat-label>Tables des prix</mat-label>
                    <mat-select (selectionChange)="onChangePriceTable($event.value)" required>
                        <mat-option disabled>Sélectionner une table des prix ...</mat-option>
                        <mat-option *ngFor="let pt of selectedProduct.priceTableID" value="{{pt.id}}">{{pt.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <select class="form-control" id="exampleFormControlSelect2"
                                        (change)="onChangePriceTable($event.target.value)">
                                        <option disabled selected>Sélectionner un garantie ...</option>
                                  <option *ngFor="let cv of coveragesValues" value="{{cv.coverage.code}}">{{cv.coverage.label}}</option>
                                </select> -->
                <!-- <h1  *ngFor="let cv of coveragesValues">{{cv.coverage.label}}</h1> -->

            </form>
        </div>
    </div>

    <!-- Form Options -->
    <div class="row">
        <div class="col-12">
            <!--TODO remove priceTableId condition after verification-->
            <app-multi-step-bar *ngIf="isDataLoaded && selectedProduct && priceTableId" [product]="selectedProduct" (formField)="handleChange($event)" (insuredContainerIndex)="setInsuredContainerIndex($event)" (checkboxChange)="onCheckboxChange($event)" (fraisSupplement)="addFraisSupplementaires($event)"
                (datesemitEffet)="changeDateEffet($event)" (datesemitSous)="changeDateSous($event)" [CoverageGlobal]="coveragesValuesGlobal" [CoveragePerUnit]="coveragesValuesPerUnit"></app-multi-step-bar>
        </div>
    </div>

    <!-- DETAILS -->
    <ng-container *ngIf="showDetails === true">
        <div class="pl-5">
            <div class="">
                <div class="row">
                    <div class="">
                        <div class="form-group" id="scrollToDetails">
                            <h4>Détails</h4>
                            <table style="display: table;">
                                <tr>
                                    <td class="title-details">Référence de tarification</td>
                                    <td class="title-details">:</td>
                                    <ng-container >
                                        <td>{{ventilation.tarification.tarifRef}}</td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="title-details">Montant HT</td>
                                    <td class="title-details">:</td>
                                    <ng-container *ngFor="let echeancierDetails of ventilation.echeancierDetails">
                                        <td *ngIf="tarifMode === echeancierDetails.periodicite">{{ echeancierDetails.totalAmountHT }}</td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="title-details">Taxes</td>
                                    <td class="title-details">:</td>
                                    <ng-container *ngFor="let echeancierDetails of ventilation.echeancierDetails">
                                        <td *ngIf="tarifMode === echeancierDetails.periodicite">{{ echeancierDetails.totalTaxes }}</td>
                                    </ng-container>
                                </tr>
                            </table>



                            <h4 class="mt-5">Détails des taxes de produit</h4>
                            <table style="display: table;">
                                <tr *ngFor="let taxeDetails of ventilation.totalTaxesDetails">
                                    <td class="title-details">{{ taxeDetails.category }}</td>
                                    <td class="title-details"></td>
                                    <td>
                                        <table style="display: table;">
                                            <tr *ngFor="let taxeVersion of taxeDetails.details">
                                                <td class="title-details">Date début</td>
                                                <td class="title-details">Valeur</td>
                                                <td class="title-details">Distribution</td>
                                            </tr>
                                            <tr *ngFor="let taxeVersion of taxeDetails.details">
                                                <td>{{ taxeVersion.startDate | date: 'yyyy-MM-dd' }}</td>
                                                <td *ngIf="taxeDetails.pourcentage === true">{{ taxeVersion.value }} %</td>
                                                <td *ngIf="taxeDetails.pourcentage === false">{{ taxeVersion.value }}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <ng-container *ngIf="taxeDetails.perUnit === true">Par unité</ng-container>
                                                    <ng-container *ngIf="taxeDetails.perUnit === false">Globale</ng-container>
                                                </td>
                                            </tr>


                                        </table>
                                    </td>
                                </tr>
                            </table>

                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="form-group" *ngIf="selectedProduct != null">
    <label >Garanties</label>
    <div *ngFor="let cover of totalCoverages" class="coverages">
      <table style="display: table;">
        <tr>
        <td style="display: table-cell;min-width: 150px;font-weight: bold;">{{cover.label}}</td>
        <td style="min-width: 15px;font-weight: bold;">:</td>
        <td>{{cover.value}}</td>
        <td >{{cover.unite}}</td>
        </tr>
      </table>
    </div>
  </div> -->

            <div class="row">
                <h4>Détails échéancier</h4>
                <mat-tab-group mat-align-tabs="center">
                    <div class="w-100" *ngFor="let echeancier of ventilation.echeancierDetails">

                        <mat-tab label={{echeancier.typeFractionnement}} (onClick)="setTarifMode(echeancier.periodicite)">
                            <table class="mt-4 text-center">
                                <tr>
                                    <td class="title-details">Ordre</td>
                                    <td class="title-details">Montant TTC</td>
                                    <td class="title-details">Montant HT</td>
                                    <td class="title-details">Taxes</td>
                                    <td class="title-details">Date début</td>
                                    <td class="title-details">Date fin</td>
                                    <td class="title-details">Supplément</td>
                                    <!-- <td style="display: table-cell; min-width: 130px; font-weight: bold;">Détails des taxes</td> -->
                                </tr>
                                <tr *ngFor="let echeance of echeancier.echeances">
                                    <td style="display: table-cell; min-width: 80px; font-weight: bold;">{{ echeance.order }}</td>
                                    <td style="display: table-cell; min-width: 150px; ">{{ echeance.amountTTC }}</td>
                                    <td style="display: table-cell; min-width: 150px; ">{{ echeance.amountHT }}</td>
                                    <td style="display: table-cell; min-width: 150px; ">{{ echeance.taxes }}</td>
                                    <td style="display: table-cell; min-width: 200px; ">{{ echeance.startDate }}</td>
                                    <td style="display: table-cell; min-width: 200px; ">{{ echeance.endDate }}</td>
                                    <td style="display: table-cell; min-width: 150px; ">{{ echeance.supplement }}</td>
                                    <td style="display: table-cell; min-width: 130px;">
                                        <div class="hint-icon">
                                            <div class="hint-content">
                                                <div style="display: flex;justify-content: center;align-items: center;">
                                                    <label style="font-weight: bold;">Détails des taxes</label>
                                                </div>
                                                <table style="display: table;">
                                                    <tr>
                                                        <td style="display: table-cell; min-width: 150px; font-weight: bold;">Taxe</td>
                                                        <td style="display: table-cell; min-width: 150px; font-weight: bold;">Valeur</td>
                                                        <td style="display: table-cell; min-width: 150px; font-weight: bold;">Distribution</td>
                                                    </tr>
                                                    <tr *ngFor="let taxeDetails of echeance.taxeDetails">
                                                        <td>{{ taxeDetails.category }}</td>
                                                        <td>{{ taxeDetails.value }}</td>
                                                        <td *ngIf="taxeDetails.perUnit === true">Par unité</td>
                                                        <td *ngIf="taxeDetails.perUnit === false">Globale</td>

                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="icon">
                                                <span>&#8505;</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="display: flex;justify-content: center;flex-direction: column;align-items: center;width: 100%;">
                                        <button mat-button (click)="openDialog(ventilation)">Détails par unité</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="empty-space"></div>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>

                    </div>
                </mat-tab-group>

            </div>

        </div>
    </ng-container>

    <!-- Submit Bottons -->
    <div class="row" *ngIf="selectedProduct!=null && !productId" style="margin-top: 50px;margin-bottom: 50px">
        <div class="col ">
            <button type="button" routerLink="/pages/companys" class="btn btn-info"><mat-icon>keyboard_arrow_left</mat-icon> Retourner à la liste</button>
        </div>
        <!--
    <div class="col text-right">
      <button class="btn btn-success" type="submit" (click)="onSubmit()"><i class="fa fa-save"></i> Save</button>
    </div>
    -->
    </div>
</div>
<div class="">
    <app-price-bubble *ngIf="showDetails === false" total=0.0></app-price-bubble>
    <ng-container *ngFor="let echeancierDetails of ventilation.echeancierDetails">
        <app-price-bubble *ngIf="tarifMode === echeancierDetails.periodicite && showDetails === true" total="{{echeancierDetails.totalAmountTTC}}"></app-price-bubble>
    </ng-container>
</div>