import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompanyEngineService } from 'src/app/services/company-engine.service';
import { CompanyInventoryService } from 'src/app/services/company-inventory.service';
import { ContractServiceService } from 'src/app/services/contract-service.service';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { ModelInventoryService } from 'src/app/services/model-inventory.service';
import { RulesEngineService } from 'src/app/services/rules-engine.service';
import { Company } from '../../Company-Engine/entity/company';
import { InsuredModelContainerFull } from '../../Company-Engine/entity/InsuredModelContainerFull';
import { ProductFull } from '../../Company-Engine/entity/ProductFull';
import { Insured, PricingEntity } from '../../Company-Engine/product-fill/entity/FormStep';
import { PricingReturn } from '../../Company-Engine/product-fill/entity/PricingReturn';
import { TotalCoverages } from '../../Company-Engine/product-fill/entity/TotalCoverages';
import { CoverageValue } from '../../Company-Inventory/entity/coveragesValue';
import { Product } from '../../Company-Inventory/entity/Product';
import { Coverage } from '../../Coverage-Inventory/entity/Coverage';
import { ProductC } from '../../Coverage-Inventory/entity/Product';
import { InsuredModel } from '../../Model-Inventory/entity/InsuredModel';
import { ProductClientFull} from '../entity/productClientFull';


@Component({
  selector: 'app-comparator',
  templateUrl: './comparator.component.html',
  styleUrls: ['./comparator.component.css']
})
export class ComparatorComponent implements OnInit {
  productLines : InsuredModel [] = []  ; 
  company:Company=new Company();
  selectedProduct:ProductFull=null;
  priceTableId:number=null;
  insuredModelsContainers:InsuredModelContainerFull[]=[]
  insuredSizes:number[]=[];
  steps:number=0;
  dinamicForm: PricingEntity=new PricingEntity();
  dynamicForms : PricingEntity [] = [] ; 
  selectedInsuredContainerIndex=null;
  price:PricingReturn=new PricingReturn();

  selectedProductLine : InsuredModel ;
  productsByProductLine : ProductC [] = [] ; 
  allProducts : ProductFull [] = [] ; 

  clientBudget = 0 ; 

  selectedProducts:ProductFull [] = [];

  productsClient : number[] = [] ; 

  finalProducts : Product [] = []

  budget : FormGroup = this.formBuilder.group({
    value : 0.0,
  }); 


  finalProductsFull : ProductClientFull [] = [] ;  
  finalProductsFullObject : ProductClientFull ;
  coveragesValues : CoverageValue [] = [] ; 
  totalCoverages : TotalCoverages [] = [] ; 
  totalCoverage = new TotalCoverages ()  ;

  pricesList : any [] = [] ; 

 

  constructor(private formBuilder: FormBuilder,private comapnyInventoryService : CompanyInventoryService,private contractService:ContractServiceService,private companyEngineService:CompanyEngineService,private route: ActivatedRoute,private pricingEngineService:RulesEngineService,private coverageInventoryService: CoverageInventoryService,private modelInventoryService : ModelInventoryService) { }

  ngOnInit(): void {
  
    this.modelInventoryService.getAllInsured().subscribe((data : InsuredModel[]) => {
      this.productLines = data;
      //console.log(this.productLines); 
    })

    
  }

    getProductsById (){
      this.finalProductsFull = [] ; 
    for (let proID of this.productsClient){
    this.comapnyInventoryService.getProductById(proID).subscribe(async (data : Product) => {
      this.finalProductsFullObject = new ProductClientFull() ; 
      this.finalProductsFullObject.coverages = [] ; 
      this.finalProductsFullObject.label = data.title ; 
      await this.getCoveragesByProductId(proID).then(e => {
        this.finalProductsFullObject.coverages.push(e) ; 
        this.pricesList.forEach(element => {
          if (element.id == proID){
            this.finalProductsFullObject.price = element.pr ; 
          }
          
        });
        this.finalProductsFull.push(this.finalProductsFullObject); 
        
      console.log(this.finalProductsFull);
      }); 
      
    });
    } 
  }

  

  getProductLineById (id :any) {
    this.modelInventoryService.getById(id).subscribe((data : InsuredModel) => {
      this.selectedProductLine = data; 
      this.getProductsByProducline(this.selectedProductLine.id); 
     }); 

  }



  getProductsByProducline(id:any){
    this.coverageInventoryService.getProductsByProductLineId(id).subscribe((data : ProductC []) => {
      this.productsByProductLine = data ; 
      for (let prodc of this.productsByProductLine) {
        this.companyEngineService.getProductById(prodc.productId).subscribe((data:ProductFull) => {
          this.allProducts.push(data); 
          
       
          this.changeProduct(data);
        });
      }
      
      
    }); 
    

  }

  

  changeProduct(productF :ProductFull){
    this.insuredSizes=[];
    let dForm = new PricingEntity()  ;
    

    this.comapnyInventoryService.getCompanyIdByProduct(productF.id).subscribe((companyId : number) => {
      dForm.companyId = companyId ;  
    })
  
   
    this.selectedProduct=productF;
    this.insuredModelsContainers=productF.insuredModelContainers;
    this.insuredModelsContainers?.forEach(x=>{
        this.insuredSizes.push(1);
        dForm.form.push(new Insured(null));
      })
    this.insuredModelsContainers.sort((a,b)=> a.step - b.step);
    this.steps=productF.steps;
    dForm.productId=this.selectedProduct.id;
    this.priceTableId=this.selectedProduct.priceTableID[0].id;
    dForm.tablePriceId=this.selectedProduct.priceTableID[0].id; 

    this.dinamicForm = dForm ; 

    

    this.selectedProducts.push(this.selectedProduct); 
    this.dynamicForms.push(this.dinamicForm);
   // console.log(this.dynamicForms)

      
    
  }



  handleChange($event: Insured[]) {
   for (let dyn of this.dynamicForms){
    let start=0;
    for(let i=0;i<this.selectedInsuredContainerIndex;i++)
      start+=this.insuredSizes[i];
    let size=this.insuredSizes[this.selectedInsuredContainerIndex];
    dyn.form.splice(start,size);
    $event.forEach((element, index)=>{
      dyn.form.splice(start+index,0,element);
    })
    this.insuredSizes[this.selectedInsuredContainerIndex]=$event.length;
    this.pricingEngineService.getPrice(dyn).subscribe((data:PricingReturn)=>{
      this.price=data;
      if (data.totalPrice < this.clientBudget && data.totalPrice != 0){
        if (!this.productsClient.includes(dyn.productId)){
        this.productsClient.push(dyn.productId);  
        this.pricesList.push({
          id : dyn.productId,
          pr : data.totalPrice}) ;

        }
      //console.log(data.totalPrice)
      }
    })
  }

  }


  setInsuredContainerIndex($event){
    this.selectedInsuredContainerIndex=$event;
  }

  onSubmit() {
    console.log("To Submit:")
    console.log(this.dinamicForm)
    this.contractService.addContract(this.dinamicForm).subscribe(data=>{console.log(data)})

  }

  getBudget(){
    this.clientBudget = this.budget.get('value').value ; 
  }

  async getCoveragesByProductId(id:any): Promise<any> {
    let list : any = [] ;
    this.coverageInventoryService.getByProductId(id).subscribe((data:CoverageValue[]) => {
     this.coveragesValues = data ; 
     this.coveragesValues.forEach((element) => {
       this.coverageInventoryService.getCoverageById(element.coverageId).subscribe((covData:Coverage) => {
        /* this.finalProductsFullObject.coverages.push({
           label : covData.label , 
           value : element.value ,
           unite : element.unite
         })*/
          list.push({
            label : covData.label , 
            value : element.value ,
            unite : element.unite
          })
       })
     })
   }); 
   return list ; 
 }


}
