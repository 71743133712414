import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {ModelInventoryService} from '../../../services/model-inventory.service';
import {InsuredModel} from '../entity/InsuredModel';
import {environment} from '../../../../environments/environment';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-insured-models-list',
  templateUrl: './insured-models-list.component.html',
  styleUrls: ['./insured-models-list.component.css',]
})
export class InsuredModelsListComponent implements OnInit {

  modelsList:InsuredModel[]=[];
  displayedColumns: string[] = ['index', 'libelle', 'description', 'nbroptions', 'proprietes'];
  dataSource :  MatTableDataSource<any>= new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private route:Router,private modelInventoryService:ModelInventoryService,private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer,private coverageInventoryService:CoverageInventoryService,public dialog: MatDialog) { 
  }

  ngOnInit(): void {
    this.modelInventoryService.getAllInsured().subscribe((data:InsuredModel[])=>{
      this.modelsList = data;
      this.dataSource.data= data;
      this.dataSource.paginator = this.paginator;

    })
  }

  addModel() {
    this.route.navigate(['/pages/'+environment.insuredModelURL+'/add'])
  }
  editModel(id:number) {
    this.route.navigate(['/pages/'+environment.insuredModelURL+'/update/'+id])
  }

  details(id: number) {
    this.route.navigate(['/pages/'+environment.insuredModelURL+'/details/'+id])
  }


  


  deleteProductLine(id:any){
    this.coverageInventoryService.deleteProductLine(id).subscribe(data => {

    }); 
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource.filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  @ViewChild('deleteconfirmationdialog') deleteconfirmationdialog: TemplateRef<any>;
  @ViewChild('dialogContentTemplate') dialogContentTemplate: TemplateRef<any>;
   selectedItemToDelete: { id: number, label: string };
   openDialog(id,label): void {
     this.selectedItemToDelete = {id,label};
 
     const dialogRef: MatDialogRef<any> = this.dialog.open(this.deleteconfirmationdialog, {
       width: 'fit-content', // Set the desired width
     
     
     });
 
   
   }
 
   delete() {
    this.modelInventoryService.deleteById(this.selectedItemToDelete.id).subscribe(data=>{
      this.deleteProductLine(this.selectedItemToDelete.id); 
      this.ngOnInit();
    })
  }


}
