import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';
import { environment } from 'src/environments/environment';
import { Coverage } from '../entity/Coverage';

@Component({
  selector: 'app-create-coverage',
  templateUrl: './create-coverage.component.html',
  styleUrls: ['./create-coverage.component.css']
})
export class CreateCoverageComponent implements OnInit {

  CoverageForm: FormGroup;

  constructor(private router:Router,private formBuilder: FormBuilder,private coverageInventoryService:CoverageInventoryService) { }

  ngOnInit(): void {
    this.initForm(); 
  }

  private initForm() {

    this.CoverageForm = this.formBuilder.group({
      label: '',
      description: '', 
      code: '', 
      active:true

    });
    
  }

  createCoverage(){
    let coverage = new Coverage(this.CoverageForm.value);  
    console.log("yi5dim !!! "); 

    this.coverageInventoryService.addCoverage(coverage).subscribe(data => {
      alert("Garantie ajouté avec succées");
      this.returnToList();
    }); 
  }


  returnToList(){
    this.router.navigate(['/'+environment.coverageUrl]); 
  }



}
