import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tab-tax',
  templateUrl: './tab-tax.component.html',
  styleUrls: ['./tab-tax.component.css']
})
export class TabTaxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
