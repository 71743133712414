import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { InsuredModelContainerFull } from '../../Company-Engine/entity/InsuredModelContainerFull';
import {Option} from '../../Model-Inventory/entity/Option';
import {SelectOption} from '../../Model-Inventory/entity/possibleOpt/SelectOption';
import {TextAreaOption} from '../../Model-Inventory/entity/possibleOpt/TextAreaOption';
import {CategoryOption} from '../../Model-Inventory/entity/possibleOpt/CategoryOption';
import {
  CheckBoxInput,
  DateInput,
  NumberInput,
  RadioInput,
  RangeInput,
  TextInput
} from '../../Model-Inventory/entity/possibleOpt/InputOption';
import { InputKVP, Insured } from '../../Company-Engine/product-fill/entity/FormStep';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-step-form-comparator',
  templateUrl: './step-form-comparator.component.html',
  styleUrls: ['./step-form-comparator.component.css']
})
export class StepFormComparatorComponent implements OnInit {
  @Input() insuredModelsContainers:InsuredModelContainerFull;
  @Output() formField = new EventEmitter()
  eventsSubject: Subject<Map<any, any>> = new Subject<Map<any, any>>();
  numberMainInsuredModel:number=0;
  option: Option;
  selectOption:any;
  insuredOptions:Option[]=[];
  productOptions:Option[]=[];
  contractOptions:Option[]=[];
  insuredMap:Map<any, any>=new Map();
  insuredList:Insured[]=[]
  insuredListFull:Insured[]=[]
  contractValues:InputKVP[]=[]
  productValues:InputKVP[]=[]
  nbDuplications:number=1;

  constructor(private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer) { 
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/deleteIcon.svg`)  
    );

    this.matIconRegistry.addSvgIcon(
      `plusSign`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/plusSign.svg`)
    );
  }

  ngOnInit(): void {
    this.insuredModelsContainers.insuredModel.parameters.forEach(x=>{
      if(x.typeProperty!=3 ) this.insuredOptions.push(this.caster(x));
      else this.contractOptions.push(this.caster(x));
    })
    this.insuredMap.set(0,new Insured(this.insuredModelsContainers.step));
    this.insuredList.push(new Insured(this.insuredModelsContainers.step));
  }

  addInsured() {
    if(this.insuredMap.size<this.insuredModelsContainers.maxMultiplicity){
      this.nbDuplications++;
    }
    this.insuredMap.set(this.nbDuplications-1,new Insured(this.insuredModelsContainers.step));
  }

  deleteInsured(i:number) {
    if(this.insuredMap.size>this.insuredModelsContainers.minMultiplicity){
      if(this.insuredMap.get(i).mainInsured) this.numberMainInsuredModel--;
      this.insuredMap.delete(i);
      const el:HTMLElement = document.getElementById('form_'+i);
      el.remove();
      this.buildFullInsuredList();
    }
  }

  caster(opt:any){
    let selectOpt:any=null;
    if(opt.possibleOption.type=='Select'){
      selectOpt= new SelectOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='TextArea'){
      selectOpt= new TextAreaOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='Category'){
      selectOpt= new CategoryOption(opt.possibleOption)
    }
    if(opt.possibleOption.type=='Input'){
      if(opt.possibleOption.typeInput=='text'){
        selectOpt=new TextInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='number'){
        selectOpt=new NumberInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='date'){
        selectOpt=new DateInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='radio'){
        selectOpt=new RadioInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='range'){
        selectOpt=new RangeInput(opt.possibleOption);
      }
      if(opt.possibleOption.typeInput=='checkbox'){
        selectOpt=new CheckBoxInput(opt.possibleOption);
      }
    }
    this.selectOption=selectOpt;
    opt.possibleOption=selectOpt;
    return opt;
  }

  changeForm($event,i:number){
    let insured:Insured =this.insuredMap.get(i);
    insured.inputs=[];
    Object.keys($event.controls).forEach(key => {
      if($event.controls[key].value instanceof Array){
        let val:string="";
        $event.controls[key].value.forEach(opt=>{
          //val+=opt+","
          val+=opt
        })
        insured.inputs.push(new InputKVP(key,val))
      }else{
        insured.inputs.push(new InputKVP(key,$event.controls[key].value))
      }
    });
    this.insuredMap.set(i,insured);
    this.buildFullInsuredList();
  }


  emitEventToChild(insuredMap:Map<any, any>) {
    this.eventsSubject.next(insuredMap);
  }

  changeContractOptions($event: any) {
    this.contractValues=[];
    Object.keys($event.controls).forEach(key => {
      if($event.controls[key].value instanceof Array){
        let val:string="";
        $event.controls[key].value.forEach(opt=>{
          val+=opt+","
        })
        this.contractValues.push(new InputKVP(key,val))
      }else{
        this.contractValues.push(new InputKVP(key,$event.controls[key].value))
      }
    });
    this.buildFullInsuredList();
  }

  changeProductOptions($event: any) {
    this.productValues=[];
    Object.keys($event.controls).forEach(key => {
      if($event.controls[key].value instanceof Array){
        let val:string="";
        $event.controls[key].value.forEach(opt=>{
          val+=opt+","
        })
        this.productValues.push(new InputKVP(key,val))
      }else{
        this.productValues.push(new InputKVP(key,$event.controls[key].value))
      }
    });
    this.buildFullInsuredList();
  }

  addContractValues(insured:Insured):Insured {
    this.contractValues.forEach(val=>{
      insured.inputs.push(val);
    })
    return insured;
  }

  addProductValues(insured:Insured):Insured {
    this.productValues.forEach(val=>{
      insured.inputs.push(val);
    })
    return insured;
  }

  buildFullInsuredList(){
    this.insuredListFull=[];
    for (let insured of this.insuredMap.values()) {
      let insured_1=new Insured(this.insuredModelsContainers.step);
      insured_1.mainInsured=insured.mainInsured;
      insured.inputs.forEach(inputs=>{
        insured_1.inputs.push(inputs);
      });
      insured_1 = this.addContractValues(insured_1);
      insured_1 = this.addProductValues(insured_1);
      this.insuredListFull.push(insured_1);
    }
    this.formField.emit(this.insuredListFull);
  }

  handleMainInsured($event: number,insuredIndex:number) {
    if($event>0)
      if(this.insuredModelsContainers.maxMainInsured>this.numberMainInsuredModel){
        this.numberMainInsuredModel+=$event;
        this.insuredMap.get(insuredIndex).mainInsured=true;
      }
      else {
        alert("Maximum number of main Insured is: "+this.insuredModelsContainers.maxMainInsured);
      }
    else{
      if(this.insuredModelsContainers.minMainInsured<this.numberMainInsuredModel){
        this.numberMainInsuredModel+=$event;
        this.insuredMap.get(insuredIndex).mainInsured=false;
      }
      else {
        alert("Minimum number of main Insured is: "+this.insuredModelsContainers.minMainInsured);
      }
    }
    this.emitEventToChild(this.insuredMap);
    this.buildFullInsuredList();
  }


}
