<!-- <div class="container" style="margin-top: 100px;margin-left: 0px;"> -->
  <div  class="row">
    <div class="col-9">
      <h1 class="titlemodel">Grille de Tarification</h1>
    </div>

    <div class="col-3">
      <a class="pathing" >Grille de Tarification</a>
    </div>
  </div>
  <div class="row" style="margin-bottom: 40px;margin-top: 40px;">
    <div class="col-lg-6 col-sx-12">
      <div class="form-group">
        <label >Compagnie:</label>
        <select (change)="onChangeCompany($event.target.value)" class="form-control" >
          <option>--</option>
          <option *ngFor="let c of companysList" value="{{c.id}}">{{c.label}}</option>
        </select>
      </div>
    </div>
    <div class="col-lg-6 col-sx-12">
      <div class="form-group">
        <label>Produit:</label>
        <select (change)="onChangeProduct($event.target.value)"  class="form-control" >
          <option>--</option>
          <option *ngFor="let c of productsList" value="{{c.id}}">{{c.title}}</option>
        </select>
      </div>
    </div>

    <div class="col-lg-6 col-sx-12">
      <div class="form-group">
        <label for="exampleFormControlInput1">Libellé:</label>
        <input [(ngModel)]="priceTable.label" type="text" class="form-control" id="exampleFormControlInput1" placeholder="2020, 2021">
      </div>
    </div>
    <div class="col-lg-6 col-sx-12">
      <div class="form-group">
        <label for="effectiveDate">Date d'effet du Grille:</label>
        <input [(ngModel)]="priceTable.effectiveDate" type="date" class="form-control" id="effectiveDate" [min]="today | date:'yyyy-MM-dd'" >
      </div>
  </div>


  <div class="row" style="margin-bottom: 40px;margin-top: 40px;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    
    </div>

  </div>
  <div class="axesSettigns">
    <div class="tabdonnees">
      <div class="accordion">
        <div class="card">
          <div class="card-header" id="headingOne" aria-expanded="true" aria-controls="collapseOne" style="background-color: #e5e5e5;">
            <label style="font-size: 14px;color: #480072;" data-toggle="collapse" data-target="#collapseOne">
              Tableaux de données :
            </label>
            <button (click)="listBoxConfig()" style="float: right" class="btn btn-success" type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg"> <mat-icon svgIcon="plusSign" class="plusSignIcon" ></mat-icon></button>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample" style="background-color: #f4f4f4;">
            <div class="card-body">
              <table class="table text-center">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Libellé</th>
                  <th scope="col">Axes</th>
                  <th scope="col">Paramètres</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dt of dataTablesList; let i=index;">
                    <td>{{i+1}}</td>
                    <td>{{dt.label}}</td>
                    <td>
                      <span *ngFor="let ax of dt.axes">{{ax.formControl.label}}, </span>
                    </td>
                    <td>
                      <!--button class="btn btn-success"  data-toggle="tooltip" data-placement="top" title="Download CSV file to fill" > <i class="fas fa-file-download"></i></button-->
                      <button class="btn btn-danger"> <i class="fa fa-trash"></i></button>
                    </td>
                </tr>
                </tbody>
              </table>

              <br/>
              <div>
                <h1>Nature des prix du grille tarifaire :</h1>
                <input type="radio" id="TTC" name="priceType" [(ngModel)]="selectedPriceType" [value]="true">
                <label for="TTC"> TTC (Toutes taxes comprises)</label><br>

                <input type="radio" id="HT" name="priceType" [(ngModel)]="selectedPriceType" [value]="false">
                <label for="HT"> HT (Hors taxe)</label><br>

                <h1>Fréquence de paiement au niveau du grille tarifaire :</h1>
                <input type="radio" id="ANNUEL" name="paymentFrequency" [(ngModel)]="selectedPaymentFrequency" [value]="true">
                <label for="ANNUEL"> ANNUEL</label><br>

                <input type="radio" id="MENSUEL" name="paymentFrequency" [(ngModel)]="selectedPaymentFrequency" [value]="false">
                <label for="MENSUEL"> MENSUEL</label><br>
                
                <h1>Validité de Tarification :</h1>
                <label>* Durée de la validité d'une tarification en jours </label>
                <div class="col-lg-2 col-sx-12">
                  <div class="form-group">
                    <input [(ngModel)]="priceTable.tarificationValidity" type="number" class="form-control" id="tarificationValidity" [min]="0" >
                  </div>
              </div>

              </div>

            </div>
          </div>
        </div>
        <div class="card"></div>
      </div>
    </div>
  </div>
  <div class="formulePart" style="margin-top: 25px;">
    <div class="calculateur">
      <h3 style="margin-top: 10px;margin-bottom: 50px;font-size: 18px;color: #480072;">Formule:</h3>
      <div for="exampleFormControlTextarea1" style="text-align: center;margin-bottom: 25px">
       <div class="calculatorP">
         <!--<div class="col-6 text-right">
           <button *ngFor="let dt of dataTablesList" type="button" class="btn btn-success btn-sm" (click)="onclickFormulaBtn(' ${'+dt.label+'} ')">{{dt.label}}</button>
           <button *ngFor="let opt of listVarOptions" type="button" class="btn btn-warning btn-sm" (click)="onclickFormulaBtn(' ${'+opt.formControl.label+'_<<'+opt.id+'>>} ')">{{opt.formControl.label}}</button>
           <br>
         </div> -->
         <div class="calculator">
          <div style="margin-bottom: 10px;margin-top: 10px"></div>
          <button type="button" class="btn btn-info btn-sm calcBtn" (click)="onclickFormulaBtnClear()">Vider</button>
          <button type="button" class="btn btn-info btn-sm calcBtn" (click)="onclickFormulaBtn(' ( ')">(</button>
           <button type="button" class="btn btn-info btn-sm calcBtn" (click)="onclickFormulaBtn(' ) ')">)</button>
           <button type="button" class="btn btn-info btn-sm calcBtn" (click)="onclickFormulaBtn(' / ')">/</button>
          
          <br>
           <div style="margin-bottom: 10px;margin-top: 10px"></div>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('1')">1</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('2')">2</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('3')">3</button>
           <button type="button" class="btn btn-info btn-sm calcBtn" (click)="onclickFormulaBtn(' * ')">X</button>
           <br>
           <div style="margin-bottom: 10px;margin-top: 10px" ></div>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('4')">4</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('5')">5</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('6')">6</button>
           <button type="button" class="btn btn-info btn-sm calcBtn" (click)="onclickFormulaBtn(' - ')">-</button>
           <br>
           <div style="margin-bottom: 10px;margin-top: 10px"></div>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('7')">7</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('8')">8</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('9')">9</button>
           <button type="button" class="btn btn-info btn-sm calcBtn" (click)="onclickFormulaBtn(' + ')">+</button>
           <br>
           <div style="margin-bottom: 10px;margin-top: 10px"></div>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn(' ')">esp</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('0')">0</button>
           <button type="button" class="btn btn-secondary btn-sm calcBtn" (click)="onclickFormulaBtn('.')">,</button>
           <button type="button" class="btn btn-info btn-sm calcBtn" >=</button>
           <br>
           <div style="margin-bottom: 10px;margin-top: 10px"></div>
          <button *ngFor="let dt of dataTablesList" type="button" class="MainCalc" (click)="onclickFormulaBtn(' ${'+dt.label+'} ')">{{dt.label}}</button>
          <button *ngFor="let opt of listVarOptions" type="button" class="btn btn-warning btn-sm " (click)="onclickFormulaBtn(' ${'+opt.formControl.label+'_<<'+opt.id+'>>} ')">{{opt.formControl.label}}</button>
          <div style="margin-bottom: 10px;margin-top: 10px"></div>
          <br>
         </div>
       </div>

      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-1">
            <span style="float: right;font-size: 35px;">=</span>
          </div>
          <div class="col-11">
            <textarea [(ngModel)]="priceTable.formula" rows="8" class="form-control" id="exampleFormControlTextarea1"  placeholder="( ${table1} *1.25 + ${table2} *1.75 ) / 2"></textarea>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="buttons">
    <div class="col-6"></div>
    <div >
      <button (click)="onSave()" type="button" style="width: 150px" class="btnSave">Enregistrer</button>
    </div>
    <div >
      <button type="button" (click)="reset()" class="btnUndo" style="width:150px">Réinitialiser</button>
    </div>
  </div>
<!-- </div> -->


<!--Add DataTable Modal-->
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style="background-color: rgba(72,0,114,0.28999999165534973);">
  <div class="modal-dialog modal-lg" role="document" >
    <div class="modal-content">
      <div class="modal-header">
      <h5 class="modal-title">Ajouter une table de données:</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Libellé:</label>
          <input [(ngModel)]="dataTableName" type="text" class="form-control">
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10" *ngIf="this.selectedProduct!=null">
              <h3 class="text-left">Liste des axes :</h3>
              <div class="well" style="max-height: 300px;">
                <ul id="check-list-box" class="list-group checked-list-box">
                    <ng-container *ngFor="let ax of this.selectedProduct.insuredModelContainers">
                      <ng-container *ngFor="let opt of ax.insuredModel.parameters">
                        <li *ngIf="!opt.requiredAdhesion" class="list-group-item" data-style="button" data-color="success">
                        {{ax.insuredModel.label}} -> {{opt.formControl.label}}
                        <input type="hidden" class="dataHolder" value="{{opt.id}}">
                        </li>
                      </ng-container>
                    </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" class="btn btn-primary"(click)="addDataTable()" data-dismiss="modal">Enregistrer les changements</button>
      </div>
    </div>
  </div>
</div>
