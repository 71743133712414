<div class="">
    <div class="stepwizard" *ngIf="product.steps>1">
        <div class="stepwizard-row setup-panel">
            <div class="stepwizard-step" *ngFor="let x of ' '.repeat(product.steps).split(''), let i = index ">
                <a *ngIf="(i+1)<=step" (click)="setStep(i+1)" type="button" class="btn btn-success btn-circle">{{i+1}}</a>
                <a *ngIf="(i+1)>step" (click)="setStep(i+1)" type="button" class="btn btn-primary btn-circle">{{i+1}}</a>
                <p>Etape {{i+1}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-12">   <div  *ngFor="let cv of CoverageGlobal" >
              <mat-checkbox class="example-margin" [(ngModel)]="cv.checked" [disabled]="cv.required" [checked]="cv.required" (change)="onCheckboxChange(cv)">{{cv.coverage.label}}</mat-checkbox>
            </div>
            </div> -->
        <div class="col-12">
            <div *ngFor="let insuredContainer of product.insuredModelContainers; let i=index">
                <app-step-form [CoveragePerUnitStepForm]="CoveragePerUnit" [CoverageGlobalStepForm]="CoverageGlobal" [product]="product" [insuredModelsContainers]="insuredContainer" [style.display]="insuredContainer.step==step ? 'block' : 'none'" (formField)="changeForm($event,i)"
                    (checkboxChange)="onCheckboxChange($event)" (fraisSupplement)="addFraisSupplementaires($event)" (datesemitEffet)="changeDateEffet($event)" (datesemitSous)="changeDateSous($event)"></app-step-form>
            </div>
        </div>
    </div>
</div>
