<!-- <div class="container" style="margin-top: 100px;margin-left:0px;"> -->
  <div class="row" >
    <div class="col-9">
      <h1>Comparateur</h1>
    </div>
    <div class="col-3" >

      <h1 class="pathing">Comparateur</h1>
    </div>
  </div>
  <!--<app-price-bubble total="{{price.totalPrice}}"></app-price-bubble>-->
  <!-- Select Product and price Table -->
  <div class="row">
    <div class="col-12">
      <form action="">
        <div class="col-10">
          <div class="form-group">
            <label >Ligne de Produit </label>
            <select (change)="getProductsByProducline($event.target.value)" class="form-control" id="exampleFormControlSelect1">
              <option disabled selected>Sélectionner la ligne de produit ...</option>
              <option *ngFor="let prodL of productLines" value="{{prodL.id}}">{{prodL.label}}</option>
            </select>
          </div>
        </div>
        <div class="col-10">
          <div class="form-group" [formGroup]="budget">
            <label >Budget</label>
            <input class="budgetInput" type="text" formControlName="value" (change)="getBudget()"/>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="row clearbottom"></div> -->

  <!-- Form Options -->
  <div class="row ">
    <div class="col-lg-10 col-sm-10 col-sx-10" *ngFor ="let selectedP of selectedProducts;index as i ">
      <app-multi-step-bar-comparator *ngIf="selectedProduct!=null && 1>i && clientBudget != 0" [product]="selectedP" (formField)="handleChange($event)" (insuredContainerIndex)="setInsuredContainerIndex($event)"></app-multi-step-bar-comparator>
    </div>
  </div>

  <!-- Submit Bottons -->
  <div class="row" *ngIf="selectedProduct!=null" style="margin-top: 50px;margin-bottom: 50px">
  <div class="row" *ngIf="selectedProduct!=null" style="margin-top: 50px;margin-bottom: 50px">
    <div class="col " *ngIf="productsClient.length != 0">
      <button type="button" class="btn btn-info" (click)="getProductsById()">getResult</button>
    </div>
  </div>
</div>
<div *ngIf="selectedProduct!=null && finalProductsFull.length != 0"   >
  
  <table class="mainTable" style="width: 100vh">
    <thead>
      <tr class="botonly">
        <th scope="col">Nom du Produit</th>
        <th scope="col">Garanties/Valeurs</th>
        <th scope="col">Prix</th>
      </tr>
      </thead>
    <tbody>
    <tr class="botonly" *ngFor="let fullP of finalProductsFull; index as i">
      <td style="display: table-cell;min-width: 150px;font-weight: bold;">{{fullP.label}}</td>
      <td>
        <tr *ngFor="let cc of fullP.coverages[0];">
          <td style="display: table-cell;min-width: 150px;">{{cc.label}}</td>
              <td style="min-width: 15px;">:</td>
              <td>{{cc.value}}</td>
              <td *ngIf="cc.unite === 'euros'" style="min-width: 80px;" >€</td>
              <td *ngIf="cc.unite !== 'euros'" style="min-width: 80px;" >{{cc.unite}}</td>
        </tr>
			</td>
      <td style="display: table-cell;min-width: 150px;">{{fullP.price}}€</td>
      </tr>
    </tbody>
    
  </table>

</div>
