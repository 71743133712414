import { InsuredModelContainer } from './InsuredModelContainer';

export class Product {
  constructor(init?: Partial<Product>) {
    Object.assign(this, init);
  }
  id:number;
  title:string;
  productRange:string;
  productLine:string;
  priceTableID:number;
  insuredModelContainers:InsuredModelContainer[];
  steps:number;
}
