<div class="my-overlay" *ngIf="showOverlay">
  <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
</div>

<div class="container" style="margin-top: 100px;">
  <div class="row" style="margin-bottom: 50px">
    <div class="col">
      <h1>Tables des prix :</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <button class="btn btn-success" style="margin-bottom: 15px" (click)="addPriceTable()">
        <i class="fa fa-plus"></i>
        Ajouter une table des prix :
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">

      <div class="accordion" id="accordionExample">
        <div *ngFor="let tablePrice of tablePriceList;let i=index" class="card">
          <div class="card-header" id="table_{{i}}">
            <h5 class="mb-0">
              <button class="btn btn-sm btn-primary" data-toggle="collapse" [attr.data-target]="'#collapse'+tablePrice.id"
                      [attr.aria-controles]="tablePrice.label" [attr.aria-expanded]="true">
                +
              </button>
              <span>
                {{tablePrice.label}}
              </span>
            </h5>
          </div>

          <div id="collapse{{tablePrice.id}}" class="collapse" [attr.aria-labelledby]="tablePrice.label"
               data-parent="#accordionExample">
            <div class="card-body">
              <p class="font-weight-bold">Formule: </p>
              <p> = {{tablePrice.formula}}</p>
              <div class="container" style="margin-top: 50px;">
                <p class="font-weight-bold">Tables de données: </p>
                <div class="container" style="margin-top: 25px;">
                  <table class="table">
                    <thead class="thead-dark">
                    <tr>
                      <th scope="col" class="text-center">#</th>
                      <th scope="col" class="text-center">Libellé</th>
                      <th scope="col" class="text-center">Axes</th>
                      <th scope="col" class="text-center">CSV</th>
                      <th scope="col" class="text-center">Valider / générer</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let dt of tablePrice.dataTables;let j=index">
                      <!--#-->
                      <th scope="row">{{j + 1}}</th>
                      <!--Label-->
                      <td class="text-center">{{dt.label}}</td>
                      <!--Axes-->
                      <td class="text-center">
                        <p *ngFor="let ax of dt.axes" >
                          <ng-container  *ngIf="ax.formControl!=null">
                            {{ax.formControl.label}},
                          </ng-container>
                        </p>
                      </td>
                      <!--CSV-->
                      <td class="text-center">
                        <a class="btn btn-outline-primary"
                                style="font-size: 25px; color: green;margin-right: 15px" target="_blank" href="https://dev.gateway.prunie.neopolis-dev.com/PRICETABLE-ENGINE/pricing/downloadFile/{{dt.filePath}}">
                          <i class="fas fa-download"></i>
                        </a>
                        <!--<a class="btn btn-outline-primary"
                                style="font-size: 25px; color: green;margin-right: 15px" target="_blank" href="https://localhost:8080/PRICETABLE-ENGINE/pricing/downloadFile/{{dt.filePath}}">
                          <i class="fas fa-download"></i>
                        </a>-->
                        <a class="btn btn-outline-primary" style="font-size: 25px; color: red;" data-toggle="modal" data-target="#priceLinesFill" (click)="selectdataTable(dt.id)">
                          <i class="fas fa-upload"></i>
                        </a>
                      </td>
                      <!--Validate / Generate-->
                      <td class="text-center">
                        <button class="btn btn-primary text-center" (click)="Generate_CSV(dt.id)">
                          <i class="fas fa-cogs"></i> Générer CSV
                        </button>
                        <button class="btn btn-primary text-center" data-toggle="modal" data-target="#validateCsvModal" (click)="selectdataTable(dt.id)">
                          <i class="fas fa-copy"></i> Valider CSV
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Validate CSV-->
<div class="modal fade" id="validateCsvModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Valider CSV</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <input type="file" class="form-control-file"  id="customFile" (change)="selectFile($event)" accept=".csv">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="CancelCSVValidation()">Cancel</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" [disabled]="dataTableIdToValidate==null || !selectedFiles" (click)="upload()">Validate</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Fill PriceLines-->
<div class="modal fade" id="priceLinesFill" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" style="background-color: rgba(72,0,114,0.28999999165534973);">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" >Télécharger le fichier pour remplir par les lignes de prix</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <input type="file" class="form-control-file"  id="fillFile" (change)="selectFile($event)" accept=".csv">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="CancelCSVValidation()">Annuler</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" [disabled]="dataTableIdToValidate==null || !selectedFiles" (click)="uploadToFill()">Télécharger</button>
      </div>
    </div>
  </div>
</div>
