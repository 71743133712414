

        <div *ngIf="item.possibleOption.typeInput=='text'" >
          <label>{{item.label}} <span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></label> <label *ngif="item.requiredTarif" class="text-danger">*</label>
          <input type="text" class="form-control" >
          <small class="form-text text-muted">{{item.description}}</small>
        </div>

        <div *ngIf="item.possibleOption.typeInput=='radio'">
          <label>{{item.label}}<span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></label> <label *ngIf="item.requiredTarif" class="text-danger">*</label>
          <div *ngFor="let v of item.possibleOption.radioPossibleValues;let index=index" class="inline-form-group">
            <label class="cust-check-bx">
              <input type="radio" [value]="v.val" name="{{item.label}}" > {{v.label}}
              <span class="checkmark"></span>
            </label>
          </div>
          <small class="form-text text-muted">{{item.description}}</small>
        </div>

        <div *ngIf="item.possibleOption.type=='Category'">
          <label>{{item.label}}<span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></label> <label *ngIf="item.requiredTarif" class="text-danger">*</label>
          <select class='form-control'  style='width: -moz-available;'>
            <ng-container *ngFor="let o of item.possibleOption.possibleValues">
              <option *ngFor="let choice of o.choices" value="{{o.label}}">{{choice.choice}}</option>
            </ng-container>
          </select>
          <small class="form-text text-muted">{{item.description}}</small>
        </div>

        <div  *ngIf="item.possibleOption.type=='Select'" >
          <label>{{item.label}}<span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></label> <label *ngIf="item.requiredTarif" class="text-danger">*</label>
          <select style='width: -moz-available;' class='form-control' size='3' multiple >
            <option *ngFor="let o of item.possibleOption.selectPossibleValues" value="{{o.label}}" >{{o.label}}</option>
          </select>
          <small class="form-text text-muted">{{item.description}}</small>
        </div>

        <div *ngIf="item.possibleOption.type=='TextArea'">
          <label>{{item.label}}<span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></label> <label *ngIf="item.requiredTarif" class="text-danger">*</label>
          <textarea class="form-control" ></textarea>
          <small class="form-text text-muted">{{item.description}}</small>
        </div>

        <div *ngIf="item.possibleOption.typeInput=='date'">
          <label>{{item.label}}<span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></label> <label *ngIf="item.requiredTarif" class="text-danger">*</label>
          <input type="date" class="form-control" >
        </div>

        <div *ngIf="item.possibleOption.typeInput=='number'">
          <label>{{item.label}}<span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></label> <label *ngIf="item.requiredTarif" class="text-danger">*</label>
          <input type="number" class="form-control" step="{{item.possibleOption.step}}"  min="{{item.possibleOption.min}}" max="{{item.possibleOption.max}}" placeholder="{{item.label}}" >
          <small class="form-text text-muted">{{item.description}}</small>
        </div>



        <div *ngIf="item.possibleOption.typeInput=='checkbox'">
          <!--label>{{item.label}}</label--> <label *ngIf="item.requiredTarif" class="text-danger">*</label>
          <mat-slide-toggle>{{item.label}} <span style="color: #dc3545" *ngIf="item.autofill"> (caché)</span></mat-slide-toggle>
          <small class="form-text text-muted">{{item.description}}</small>
        </div>



