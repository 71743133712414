import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TaxeCategory } from '../pages/Taxes/entities/models/models';
import { ModelDto, ProductDto, TaxeCategoryDto, TaxeDto,TaxeVersionDto } from '../pages/Taxes/entities/dto/dtos';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaxesService {
  service:string="TAXES-INVENTORY/";
  constructor(private http:HttpClient) { }
  
  getAllTaxeCategory(){
    
    return this.http.get(environment.baseUrl+this.service+"TaxesCategory/V0/all")
  }

getAllTaxe(){
  return this.http.get(environment.baseUrl+this.service+"Taxes/V0/all")
}


addTaxeCategory(taxeCategoryDto: TaxeCategoryDto) {
  return this.http.post(environment.baseUrl+this.service+"TaxesCategory/V0/add",taxeCategoryDto)
}

addTaxe(taxeDto: TaxeDto) {
  return this.http.post(environment.baseUrl+this.service+"Taxes/V0/add",taxeDto)
}


TaxeCategoryStatus(id:number){
return this.http.put(environment.baseUrl+this.service+"TaxesCategory/V0/setActive/"+id,null);
}

updateTaxe(idTaxe:number,taxeDto:TaxeDto){
return this.http.put(environment.baseUrl+this.service+"Taxes/V0/update/"+idTaxe, taxeDto);
}



getTaxebyId(id:number){
  return this.http.get(environment.baseUrl+this.service+"Taxes/V0/"+id)
}

addTaxeToModel(modelDto:ModelDto){
  return this.http.post(environment.baseUrl+this.service+"Taxes/V0/addTaxesToModel",modelDto)
}


getModelTaxesbyId(id:number){
  return this.http.get(environment.baseUrl+this.service+"Taxes/V0/getModelTaxes/"+id)
}

addTaxeToProduct(productDto:ProductDto){
  return this.http.post(environment.baseUrl+this.service+"Taxes/V0/addTaxesToProduct",productDto)
}

getProductTaxesbyId(id:number){
  return this.http.get(environment.baseUrl+this.service+"Taxes/V0/getProductTaxes/"+id)
}



updateModelTaxes(modelDto:ModelDto){
  return this.http.put(environment.baseUrl+this.service+"Taxes/V0/updateModelTaxes/", modelDto);
  }
  
  updateProductTaxes(productDto:ProductDto){
  return this.http.put(environment.baseUrl+this.service+"Taxes/V0/updateProductTaxes/", productDto);
  }


}
