import {Option} from '../../Model-Inventory/entity/Option';

export class DataTable {
  constructor(init?: Partial<DataTable>) {
    Object.assign(this, init);
  }
  id:number;
  label:string;
  filePath:string;
  axes:Option[];
}
