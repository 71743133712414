import {Option} from './Option';

export class InsuredModel{
  constructor(init?: Partial<InsuredModel>){
    Object.assign(this, init);
    this.parameters=[];
  }
  id:number;
  label:string;
  description:string;
  parameters:Option[];
}

