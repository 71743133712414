import { AuthService } from './../authentification/_service/auth.service';
import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route} from '@angular/router';
import {Observable} from 'rxjs';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthorized()) {
            if(this.authService.hasRole("TESTER"))
            this.router.navigate(['/pages/tarification']);
            else
            this.router.navigate(['/pages/home']);
            return false;
        }
        const roles = route.data.roles as string[];
        if (roles && !roles.some(r => this.authService.hasRole(r))) {
          if(this.authService.hasRole("TESTER"))
          this.router.navigate(['/pages/tarification']);
          else
          this.router.navigate(['/pages/home']);
            return false;
        }
        return true;
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isAuthorized()) {
            return false;
        }
        const roles = route.data && route.data.roles as string[];
        if (roles && !roles.some(r => this.authService.hasRole(r))) {
            return false;
        }
        return true;
    }
}
