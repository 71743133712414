<div id="login">
    <div class="login-form">
        <div class="text-center mb-5 ">
            <svg width="232" height="36" viewBox="0 0 232 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.0559 0.542602H0.37793V35.4346H7.79493V25.3026H15.0559C16.7154 25.3564 18.3687 25.076 19.9176 24.4781C21.4666 23.8801 22.8795 22.9769 24.0724 21.8221C25.2652 20.6672 26.2138 19.2843 26.8616 17.7556C27.5093 16.2268 27.8431 14.5835 27.8431 12.9231C27.8431 11.2628 27.5093 9.61941 26.8616 8.09067C26.2138 6.56192 25.2652 5.17903 24.0724 4.02417C22.8795 2.86931 21.4666 1.96609 19.9176 1.36816C18.3687 0.770242 16.7154 0.489827 15.0559 0.54361V0.542602ZM15.0559 18.1426H7.80993V11.8026H7.79493V7.75162H15.0559C15.752 7.73002 16.4454 7.84849 17.0948 8.09998C17.7443 8.35146 18.3366 8.73084 18.8367 9.21561C19.3367 9.70037 19.7343 10.2806 20.0058 10.922C20.2773 11.5633 20.4172 12.2527 20.4172 12.9491C20.4172 13.6456 20.2773 14.3349 20.0058 14.9763C19.7343 15.6176 19.3367 16.1979 18.8367 16.6826C18.3366 17.1674 17.7443 17.5468 17.0948 17.7983C16.4454 18.0498 15.752 18.1682 15.0559 18.1466V18.1426Z" fill="url(#paint0_linear_471_87)"/>
              <path d="M30.1049 24.0755L36.6339 35.4125H28.1199L22.3199 25.3314H17.2549V35.4625H9.83789V18.1725H24.5159C25.2132 18.1961 25.9082 18.0793 26.5594 17.8288C27.2106 17.5783 27.8048 17.1992 28.3065 16.7143C28.8082 16.2294 29.2071 15.6485 29.4796 15.0061C29.7521 14.3638 29.8925 13.6732 29.8925 12.9755C29.8925 12.2777 29.7521 11.5871 29.4796 10.9448C29.2071 10.3025 28.8082 9.72152 28.3065 9.2366C27.8048 8.75167 27.2106 8.37266 26.5594 8.12216C25.9082 7.87165 25.2132 7.75477 24.5159 7.77847H17.2549V15.2485H9.83789V0.570461H24.5159C27.8517 0.525959 31.0698 1.80267 33.4678 4.12201C35.8658 6.44134 37.2491 9.615 37.3159 12.9505C37.3058 15.3007 36.6208 17.5986 35.3424 19.5708C34.0641 21.543 32.2462 23.1066 30.1049 24.0755Z" fill="url(#paint1_linear_471_87)"/>
              <path d="M24.4109 0.570371H9.73291V15.2484H17.1499V7.77838H24.4109C25.1082 7.75468 25.8032 7.87156 26.4544 8.12207C27.1057 8.37257 27.6998 8.75158 28.2015 9.23651C28.7032 9.72143 29.1021 10.3024 29.3746 10.9447C29.6471 11.587 29.7875 12.2776 29.7875 12.9754C29.7875 13.6731 29.6471 14.3637 29.3746 15.006C29.1021 15.6484 28.7032 16.2293 28.2015 16.7142C27.6998 17.1992 27.1057 17.5782 26.4544 17.8287C25.8032 18.0792 25.1082 18.1961 24.4109 18.1724H9.73291V25.3124H24.9689C26.7289 25.3122 28.4683 24.9333 30.0689 24.2014L29.9949 24.0724C32.1359 23.103 33.9534 21.5392 35.2314 19.5668C36.5094 17.5944 37.194 15.2966 37.2039 12.9464C37.1361 9.61284 35.7533 6.44126 33.3569 4.12298C30.9605 1.80471 27.7449 0.52772 24.4109 0.570371Z" fill="url(#paint2_linear_471_87)"/>
              <path d="M69.1291 12.5775C69.1291 20.0775 63.7041 24.6115 54.7291 24.6115H46.1971V35.0675H41.2661V0.54248H54.7301C63.7041 0.54248 69.1291 5.08048 69.1291 12.5775ZM64.1971 12.5775C64.1971 7.64548 60.8971 4.8335 54.5801 4.8335H46.1961V20.3205H54.5801C60.8921 20.3215 64.1971 17.5095 64.1971 12.5775Z" fill="#480072"/>
              <path d="M99.457 35.0685L92.009 24.4685C91.319 24.5175 90.579 24.5685 89.839 24.5685H81.307V35.0745H76.375V0.54248H89.844C98.82 0.54248 104.244 5.07951 104.244 12.5765C104.356 14.977 103.671 17.3472 102.297 19.3185C100.923 21.2898 98.9352 22.7516 96.644 23.4765L104.831 35.0665L99.457 35.0685ZM99.309 12.5785C99.309 7.64649 96.009 4.8345 89.692 4.8345H81.308V20.3705H89.692C96.004 20.3705 99.309 17.5095 99.309 12.5775V12.5785Z" fill="#480072"/>
              <path d="M111.931 20.1735V0.54248H116.863V19.9745C116.863 27.6195 120.363 31.0745 126.58 31.0745C132.797 31.0745 136.346 27.6215 136.346 19.9745V0.54248H141.13V20.1725C141.13 30.1845 135.656 35.4615 126.53 35.4615C117.455 35.4625 111.931 30.1855 111.931 20.1735Z" fill="#480072"/>
              <path d="M180.926 0.54248V35.0675H176.882L156.168 9.32248V35.0685H151.235V0.54248H155.28L175.992 26.2885V0.54248H180.926Z" fill="#480072"/>
              <path d="M191.28 0.54248H196.212V35.0675H191.28V0.54248Z" fill="#480072"/>
              <path d="M231.622 30.7775V35.0685H206.567V0.54248H230.931V4.8335H211.499V15.4335H228.808V19.6255H211.499V30.7725L231.622 30.7775Z" fill="#480072"/>
              <defs>
              <linearGradient id="paint0_linear_471_87" x1="0.37793" y1="17.9859" x2="27.8431" y2="17.9859" gradientUnits="userSpaceOnUse">
              <stop stop-color="#A733DE"/>
              <stop offset="0.086" stop-color="#9529C9"/>
              <stop offset="0.248" stop-color="#791AAA"/>
              <stop offset="0.416" stop-color="#630E91"/>
              <stop offset="0.592" stop-color="#54067F"/>
              <stop offset="0.78" stop-color="#4A0175"/>
              <stop offset="1" stop-color="#480072"/>
              </linearGradient>
              <linearGradient id="paint1_linear_471_87" x1="37.3708" y1="9.85091" x2="3.42583" y2="22.0015" gradientUnits="userSpaceOnUse">
              <stop stop-color="#577616"/>
              <stop offset="0.231" stop-color="#5A7A16"/>
              <stop offset="0.467" stop-color="#638617"/>
              <stop offset="0.706" stop-color="#739A19"/>
              <stop offset="0.946" stop-color="#8AB71C"/>
              <stop offset="1" stop-color="#90BF1D"/>
              </linearGradient>
              <linearGradient id="paint2_linear_471_87" x1="14.4305" y1="27.4898" x2="24.9576" y2="-0.341901" gradientUnits="userSpaceOnUse">
              <stop stop-color="#577616"/>
              <stop offset="1" stop-color="#90BF1D"/>
              </linearGradient>
              </defs>
              </svg>
        </div>
        <form [formGroup]="loginForm" class="d-flex justify-content-center" (ngSubmit)="onSubmitLogin()">

            <div class="form-container">
                <h1 class="text-center mb-5">CONNECTEZ-VOUS À VOTRE COMPTE</h1>

                <mat-form-field appearance="outline" class="mb-2 mt-2">
                    <label><strong>Email*</strong></label>
                    <input matInput name="email" placeholder="Email" formControlName="email">
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email est requis
                    </mat-error>
                    <mat-error *ngIf="!loginForm.get('email').hasError('required') &&
                              loginForm.get('email').hasError('email')">
                        S'il vous plaît, mettez une adresse email valide
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="mt-2">
                    <label><strong>Mot de passe*</strong></label>
                    <input matInput name="password" [type]="hide ? 'password' : 'text' " placeholder="Mot de passe" formControlName="password">
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" type="button">
                      <mat-icon matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="loginForm.get('password').hasError('required')">
                        Mot de passe est requis
                    </mat-error>
                </mat-form-field>

                <button mat-button class="submit-btn" type="submit" [disabled]="loginForm.invalid">
                  <span *ngIf="!loading">Se connecter</span>
                  <div class="d-flex justify-content-center" *ngIf="loading">
                    <mat-spinner  [diameter]="25" color="primary"></mat-spinner>
                  </div>
                </button>

            </div>

        </form>
    </div>
    <div class="login-img">
        <img src="/assets/img/login.png" alt="">
    </div>
</div>