import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Company} from '../pages/Company-Inventory/entity/company';
import {Product} from '../pages/Company-Inventory/entity/Product';
import { ProductCoverageDto, ProductDtoCoverage } from '../pages/Coverage-Inventory/coverages-list/entities/dtos/coveragedtos';

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class CompanyInventoryService {
  service:string="COMPANY-INVENTORY/";
  constructor(private http:HttpClient) { }
  getAllCompanys(){
    return this.http.get(environment.baseUrl+this.service+environment.companyURL+'/all');
  }
  getCompanyProducts(id:number){
    return this.http.get(environment.baseUrl+this.service+environment.companyURL+"/"+id+"/products")
  }

  getCompanyById(id:number){
    return this.http.get(environment.baseUrl+this.service+environment.companyURL+'/'+id)
  }

  getProductById(id:number){
    return this.http.get(environment.baseUrl+this.service+environment.productURL+'/'+id)
  }

  addCompany(company: Company) {
    return this.http.post(environment.baseUrl+this.service+environment.companyURL+"/add",company,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })
    })
  }
  deleteCompany(id:number){
    return this.http.delete(environment.baseUrl+this.service+environment.companyURL+"/"+id)
  }

  addProduct(id: number, product: Product) {
    return this.http.post(environment.baseUrl+this.service+environment.companyURL+"/"+id+"/product/add", product,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })
    })
  }

  deleteProduct(id: number) {
    return this.http.delete(environment.baseUrl+this.service+environment.productURL+'/'+id)
  }

  getCompanyIdByProduct(id :number){
    return this.http.get(environment.baseUrl+this.service+environment.companyURL+"/findCompanyByProduct/"+id); 
  }

 
}
