import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../_service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/_models/User';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  hide: boolean = true;
  loading: boolean = false;

  constructor(private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _cookieService: CookieService) { }

  ngOnInit(): void {
    if (this._cookieService.check('token')) {
      this._authService.getUserDetails()
          .subscribe((user: User) => {
            if(user){
              this._authService.updateUser(user);
              if(user.role == "TESTER")
              this._router.navigate(['/pages/tarification']);
              else
              this._router.navigate(['/pages/home']);
            }
          });
  }
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  onSubmitLogin(){
    this.loading = true;
    this._authService.authentificationSignIn(this.loginForm.value)
    .subscribe(()=>{
      this._authService.getUserDetails().subscribe(user=>{
        this._cookieService.set('currentUser', JSON.stringify(user), user.expirationTime);
        this._authService.updateUser(user);
        if(user?.expirationTime){
          const token = this._cookieService.get('token');
          this._cookieService.delete('token');
          this._cookieService.set('token', token, user.expirationTime);
        }
        this.loading = false;
        if(user.role == 'TESTER')
        this._router.navigate(['/pages/tarification']);
        else
        this._router.navigate(['/pages/home']);
      },()=>{
        this._matSnackBar.open("Il y a eu un problème de connexion au serveur ou un problème de connexion Internet. Veuillez réessayer", "Fermer", {
          duration: 4000,
          verticalPosition: 'top'
        });
        this.loading = false;
      })
    }, (error: HttpErrorResponse)=>{
      if(error.status == 404 || error.status == 401){
        this._matSnackBar.open("Adresse e-mail ou mot de passe invalide", "Fermer", {
          duration: 4000,
          verticalPosition: 'top'
        });
      }else{
        this._matSnackBar.open("Il y a eu un problème de connexion au serveur ou un problème de connexion Internet. Veuillez réessayer", "Fermer", {
          duration: 4000,
          verticalPosition: 'top'
        });
      }
      this.loading = false;
    })
  }

}
