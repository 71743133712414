<!-- <app-side-bar>
</app-side-bar> -->
<mat-sidenav-container class="sidenav-container" style="height:100%;overflow-x:hidden">
    <mat-sidenav id="sideNavBar" #sidenav mode="side" opened [@sidenavAnimation]="isSidenavReduced ? 'reduced' : 'open'" style="height:100%;overflow-x:hidden">


        <mat-nav-list>
            <mat-list-item [disableRipple]="true">
                <img src="/assets/PRletters.png" class="logo_img">
                <img #nopr src="/assets/prunienopr.png" class="nopr" [ngStyle]="{'display':contentDisplay}">
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="'/pages/home'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="margin-top:22px;padding-top: 5px;" class="matlistitem">
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">home</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}"> Accueil </p>
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="['/pages/company']" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">domain</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [routerLink]="'/pages/company/'" [ngStyle]="{'display':contentDisplay}">Compagnies </p>
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="'/pages/pricetable/add/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">bar_chart</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">Grille de tarification</p>
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="'/pages/rules/edit'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
                <!-- <p><mat-icon mat-list-icon class="sidenavlinkicon">ballot</mat-icon></p> -->
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">rule</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">Moteurs de règles</p>
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="'/pages/insuredmodel/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">folder_shared</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">Modèles</p>
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="'/pages/coverages/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">verified_user</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">Garanties</p>
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="'/pages/comparateur/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">swap_horiz</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">Comparateur</p>
            </mat-list-item>

            <mat-list-item [disableRipple]="true" [routerLink]="'/pages/companys/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
                <p>
                    <mat-icon mat-list-icon class="sidenavlinkicon">attach_money</mat-icon>
                </p>
                <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">Système de tarification</p>
            </mat-list-item>

            <!-- <mat-list-item [disableRipple]="true" [routerLink]="'/pages/taxes/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}" style="padding-top: 5px;">
          <p><mat-icon mat-list-icon class="sidenavlinkicon">attach_money</mat-icon> </p>
              <p mat-ripple="disabled" class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}"><mat-icon svgIcon="commun" ></mat-icon>Taxes</p>
            </mat-list-item> -->


            <!-- <mat-list-item [matMenuTriggerFor]="menu" [disableRipple]="true" style="padding-top: 5px;">
          <p><mat-icon mat-list-icon class="sidenavlinkicon">settings</mat-icon> </p>
              <p mat-ripple="disabled" class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}"><mat-icon svgIcon="commun" ></mat-icon>Paramétrage</p>
            </mat-list-item> -->

            <mat-expansion-panel [class.mat-elevation-z0]="true" hideToggle>
                <mat-expansion-panel-header>
                    <p>
                        <mat-icon mat-list-icon class="sidenavlinkicon" style="margin-top:23px;">settings</mat-icon>
                    </p>
                    <p style="margin-top:20px;" mat-ripple="disabled" class="sidenavlinkexpansion" mat-list-item [ngStyle]="{'display':contentDisplay}">Paramétrage</p>
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <mat-list-item class="sidenavlink" [disableRipple]="true" [routerLink]="'/pages/taxes/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}">
                        <p>
                            <mat-icon mat-list-icon class="sidenavlinkicon">percent</mat-icon>
                        </p>
                        <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">Taxes</p>
                    </mat-list-item>

                    <!-- <mat-list-item class="sidenavlink" [disableRipple]="true" [routerLink]="'/pages/tarifref/'" [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact: true}">
                        <p>
                            <mat-icon mat-list-icon class="sidenavlinkicon">description</mat-icon>
                        </p>
                        <p class="sidenavlink" mat-list-item [ngStyle]="{'display':contentDisplay}">TarifRef</p>
                    </mat-list-item> -->
                </mat-nav-list>
            </mat-expansion-panel>

        </mat-nav-list>
    </mat-sidenav>














    <!-- <div class="sidenav-content"> -->
    <mat-sidenav-content id="sidenav-content" [ngStyle]="{'margin-left.px':contentMargin}">
        <mat-toolbar>
            <div class="toolbaritems">
                <button type="button" aria-label="Toggle sidenav" id="toggleSideNav" mat-icon-button (click)="toggleSidenavWidth()"> <mat-icon style= "color:white">menu</mat-icon> </button>
                <!-- <input type="text" placeholder="Recherche..." class="SearchInput">
                <button mat-button class="searchButton">
                  <mat-icon style="color:white;">search</mat-icon>
                </button> -->
            </div>
            <span class="example-spacer"></span>
            <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
                <mat-icon>favorite</mat-icon>
              </button> -->
            <div class="toolbar-info">
                <span class="avatar-name">{{user.firstName}} {{user.lastName}}</span>
                <img [src]="generateAvatarUrl()" alt="Avatar">
            </div>
            <button mat-icon-button class="toolbar-dropdownMenu" [matMenuTriggerFor]="menu" aria-label="Example icon-button with share icon">
                <mat-icon>arrow_drop_down_circle</mat-icon>
              </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="signOut()">
                  <mat-icon>exit_to_app</mat-icon>
                  <span>Déconnexion</span>
                </button>
            </mat-menu>
        </mat-toolbar>

        <div class="container prunie-body">

            <router-outlet></router-outlet>
        </div>
        <!-- </div> -->
    </mat-sidenav-content>
</mat-sidenav-container>