import { Component, OnInit } from '@angular/core';
import {CompanyInventoryService} from '../../../services/company-inventory.service';
import {Company} from '../../Company-Inventory/entity/company';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-companys-list',
  templateUrl: './companys-list.component.html',
  styleUrls: ['./companys-list.component.css']
})
export class CompanysListComponent implements OnInit {
  companyList:Company[]=[];
  productId: string;

  constructor(private companyInventoryService: CompanyInventoryService,private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `euro`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/euro.svg`)
    );

  }

  ngOnInit(): void {
    this.companyInventoryService.getAllCompanys().subscribe((data:Company[])=>{
      this.companyList =data;
    })
  }
}
