export class Coverage{
    constructor(init?: Partial<Coverage>){
      Object.assign(this, init);
    }
    id:number;
    label:string;
    description:string;
    code:string;
    unite:string;
    active:boolean;
  
  }
  