<div class="row clearbottom" style="margin-top: 10%"></div>

<!-- Contract options -->
<div class="row" *ngIf="contractOptions.length>0">
  <div class="col-12">
    <!--h3 style="text-align: center;">-- Contract Options --</h3-->
  </div>
  <div class="col-12">
    <app-inputs-comparator [events]="eventsSubject.asObservable()" (formField)="changeContractOptions($event)" [insuredFields]="contractOptions"></app-inputs-comparator>
  </div>
</div>


<!-- insured options -->
<div class="row" *ngIf="insuredOptions.length>0">
  
 
  <div class="col-12"  style="background-image: linear-gradient(#f4f4f4, #f4f4f4, #f4f4f48e) ;border-bottom-left-radius: 80px;border-bottom-right-radius: 80px;">
    <mat-expansion-panel  *ngFor="let x of ' '.repeat(nbDuplications).split('');let i=index" id="form_{{i}}" >      
     <mat-expansion-panel-header  [collapsedHeight]="'49px'" [expandedHeight]="'60px'" *ngIf="insuredModelsContainers.multiple" >
       <mat-panel-title class="row">
         <div class="col-6" >
          <p style="color: #480072;font-size: 14px;">
            Assuré
          </p>
         </div>
         <div class="col-6" *ngIf="insuredMap.get(i)">
           <div class="row text-right" style="width: -moz-fit-content; position: absolute;right: 20px">
             
             
           </div>
         </div>

       </mat-panel-title >
     </mat-expansion-panel-header>
      <mat-expansion-panel-header *ngIf="!insuredModelsContainers.multiple">
        <mat-panel-title  class="row">
          <div class="col-6">
            <p style="color: #480072;font-size: 14px;">
              Assuré
            </p>
          </div>
          <div class="col-6" *ngIf="insuredMap.get(i)">
            
          </div>

        </mat-panel-title >
      </mat-expansion-panel-header>
      <div >
        <app-inputs-comparator [num_form]="insuredModelsContainers.step" [events]="eventsSubject.asObservable()" (formField)="changeForm($event,i)" [insuredFields]="insuredOptions" [insuredIndex]="i" ></app-inputs-comparator>
      </div>
    </mat-expansion-panel>
    <hr class="rounded">
    <div class="col-12" [style.display]="insuredModelsContainers.maxMultiplicity>this.insuredMap.size?'block':'none'">
      <div *ngIf="insuredModelsContainers.multiple" class="alert alert-success" style="text-align: center;" role="alert" >
        <label>Ajouter Un Assuré</label>
        <button (click)="addInsured()" class="buttonAdd" ><mat-icon svgIcon="plusSign" class="addIcon" ></mat-icon></button>
      </div>
    </div>
  </div>
</div>
<!-- <div class="row clearbottom"></div> -->




