import {TotalCoverages} from '../../Company-Engine/product-fill/entity/TotalCoverages'

export class ProductClientFull {
    constructor(init?: Partial<ProductClientFull>) {
      Object.assign(this, init);
    }
    label:String ;
    coverages : TotalCoverages [] = [] ; 
    price : number ;


  }