import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { Option } from '../../entity/Option';
import { CategoryOption, CategoryPossibleValues } from '../../entity/possibleOpt/CategoryOption';
import { RadioInput } from '../../entity/possibleOpt/InputOption';
import { SelectOption, SelectPossibleValues } from '../../entity/possibleOpt/SelectOption';

class PossibleOpt {
  constructor(init?: Partial<PossibleOpt>) {
    Object.assign(this, init);
  }
  label: string;
  min:number;
  max:number;
  val: string;
  catVal: string;

}

@Component({
  selector: 'app-form-params',
  templateUrl: './form-params.component.html',
  styleUrls: ['./form-params.component.css']
})

export class FormParamsComponent implements OnInit {
  possibleOptionForm: FormGroup;
  InsuredModelForm: FormGroup;
  formControl: FormGroup;
  NumberInputOption: FormGroup;

  @Input() item: any;
  @Input() listName: string;
  @Output() itemIdEmitter = new EventEmitter<number>();
  @Output() itemListNameEmitter = new EventEmitter<string>();
  constructor(private formBuilder: FormBuilder,private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/deleteIcon.svg`)  
    );
   }

  ngOnInit(): void {
    this.initForm();
  }
  addPossibleOpt(item: Option) {
    let possibleOption = new PossibleOpt(this.possibleOptionForm.value);
    possibleOption.val=this.GenerateRandomStr()
    this.possibleOptionForm.reset();
    if (item.possibleOption instanceof SelectOption) {
      item.possibleOption.selectPossibleValues.push(new SelectPossibleValues(possibleOption.label,possibleOption.val))
    }
    if(item.possibleOption instanceof RadioInput){
      item.possibleOption.radioPossibleValues.push(new SelectPossibleValues(possibleOption.label,possibleOption.val))
    }
    this.possibleOptionForm.reset();
  }


  private initForm() {
    this.possibleOptionForm = this.formBuilder.group({
      label: '',
      min: this.item.possibleOption.min,
      max: this.item.possibleOption.max,
      val: 0,
      catVal : ''
    });
    this.InsuredModelForm = this.formBuilder.group({
      label: '',
      description: ''
    });
    this.formControl = this.formBuilder.group({
      label:'',
      uniqueIdentifier:''
    })
  }

  GenerateRandomStr(){
    var chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var length=18;
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  deletePossibleOption(item: Option, index: number) {
    if (item.possibleOption instanceof SelectOption) {
      item.possibleOption.selectPossibleValues.splice(index,1)
    }
    if(item.possibleOption instanceof RadioInput){
      item.possibleOption.radioPossibleValues.splice(index,1)
    }
  }
  addPossibleOptCategory(item: Option) {
    let possibleOption = new PossibleOpt(this.possibleOptionForm.value);
    let categoryPossibleValue= new CategoryPossibleValues(possibleOption.label,possibleOption.catVal)
    if (item.possibleOption instanceof CategoryOption) {
      item.possibleOption.possibleValues.push(categoryPossibleValue);
    }
    this.possibleOptionForm.reset();
  }

  deleteCategoryOption(item: Option, index: number) {
    if (item.possibleOption instanceof CategoryOption) {
      item.possibleOption.possibleValues.splice(index,1);
    }
  }

  emitItemId() {
    this.itemIdEmitter.emit(this.item.ord);
    this.itemListNameEmitter.emit(this.listName);
  }

  getMinMax(event){
    this.item.possibleOption.min = this.possibleOptionForm.value.min
    this.item.possibleOption.max = this.possibleOptionForm.value.max
  }
}
