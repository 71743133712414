import { CookieService } from 'ngx-cookie-service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'front';

  constructor(private cookiesService:CookieService, private router:Router){
    if(!this.cookiesService.check('token')){
      this.router.navigate(['/auth']);
    }
  }
}
