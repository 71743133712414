<div class="row clearbottom" style="margin-top: 3%"></div>
<div class="row">
    <div class="col-12">
        <form [formGroup]="dateForm" class="d-flex justify-content-around">

            <mat-form-field class="form-element" appearance="outline">
                <mat-label>Date de souscription</mat-label>
                <input matInput formControlName="souscriptionDate" [matDatepicker]="dsPicker" [min]="dateNow" required name="souscriptionDate">
                <mat-datepicker-toggle matSuffix [for]="dsPicker"></mat-datepicker-toggle>
                <mat-datepicker #dsPicker startView="month"></mat-datepicker>
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-error *ngIf="dateForm.controls?.souscriptionDate.invalid">Date de souscription est invalide</mat-error>
            </mat-form-field>



            <!-- <input class="form-control" type="date" matInput (change)="changeDateSous($event)" placeholder="Date de souscription" [min]="DateSousDefault" [value]="DateSousDefault"> -->

            <!-- <input class="form-control" type="date" matInput (change)="changeDateEffet($event)" [min]="DateSousDefault" placeholder="Date d'effet" [value]="DateEffetDefault"> -->
            <mat-form-field class="form-element" appearance="outline">
                <mat-label>Date d'effet</mat-label>
                <input matInput formControlName="effectiveDate" [matDatepicker]="edPicker" [min]="dateForm.value.souscriptionDate" required name="effectiveDate">
                <mat-datepicker-toggle matSuffix [for]="edPicker"></mat-datepicker-toggle>
                <mat-datepicker #edPicker startView="month"></mat-datepicker>
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-error *ngIf="dateForm.controls?.effectiveDate.invalid">Date d'effet est invalide</mat-error>
            </mat-form-field>


        </form>
    </div>
    <div class="col-12 d-flex justify-content-end py-3">
        <button class="btnaddg align-self-center" mat-raised-button (click)="openDialog()"><mat-icon class="mr-2">open_in_new</mat-icon>Ajouter frais supplementaires</button>
    </div>
    <div class="col-12">
        <mat-divider></mat-divider>
    </div>
</div>
<!-- Contract options -->
<div class="row" *ngIf="contractOptions.length>0">
    <div class="col-lg-12">
        <!--h3 style="text-align: center;">-- Contract Options --</h3-->
    </div>

    <div class="col-12" style="margin-top:10px;">
        <app-inputs [events]="eventsSubject.asObservable()" (formField)="changeContractOptions($event)" [insuredFields]="contractOptions"></app-inputs>
    </div>
</div>
<div class="row align-items-baseline">
    <div class=" col-lg-12">
        <div class="row options-box" *ngIf="CoverageGlobalStepForm.length>0">
            <div class="col-12" style="margin-bottom:2%;">
                <h5>Garanties:</h5>
                <mat-divider></mat-divider>
            </div>
            <div class="col-6 " *ngFor="let cvg of CoverageGlobalStepForm">
                <mat-checkbox [checked]="cvg.required" [disabled]="cvg.required" (change)="onCheckboxChange(cvg,$event);">{{cvg.coverage.label}}</mat-checkbox>
            </div>
        </div>
    </div>
</div>
<!-- insured options -->
<div class="row" *ngIf="insuredOptions.length>0">
    <div class="col-12">
        <mat-expansion-panel class="mt-2" *ngFor="let x of ' '.repeat(nbDuplications).split('');let i=index" id="form_{{i}}">
            <mat-expansion-panel-header [collapsedHeight]="'49px'" [expandedHeight]="'60px'" *ngIf="insuredModelsContainers.multiple">
                <mat-panel-title class="row">
                    <div class="col-6">
                        <p class="mb-0" style="color: #480072;">
                            Assuré
                        </p>
                    </div>
                    <div class="col-6" *ngIf="insuredMap.get(i)">
                        <div class="row text-right" style="width: -moz-fit-content; position: absolute;right: 20px">
                            <div class="col" *ngIf="insuredModelsContainers.useAsMainInsured">
                                <app-check-box [useAsMainInsured]="insuredMap.get(i).mainInsured" (onChangeStatus)="handleMainInsured($event,i)" data-toggle="tooltip" class="btn-success" data-placement="top" title="Select As Main Insured"></app-check-box>
                            </div>
                            <div class="col">
                                <button mat-icon-button color="warn" class="position-delete-icon" (click)="deleteInsured(i)" [disabled]="this.nbDuplications<=this.insuredModelsContainers.minMultiplicity"><mat-icon color="wan">delete</mat-icon></button>
                            </div>
                        </div>
                    </div>

                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel-header *ngIf="!insuredModelsContainers.multiple">
                <mat-panel-title class="row">
                    <div class="col-6">
                        <p style="color: #480072;font-size: 14px;">
                            Assuré
                        </p>
                    </div>
                    <div class="col-6" *ngIf="insuredMap.get(i)">
                        <div class="row text-right" style="width: -moz-fit-content; position: absolute;right: 20px">
                            <div class="col" *ngIf="insuredModelsContainers.useAsMainInsured">
                                <app-check-box [useAsMainInsured]="insuredMap.get(i).mainInsured" (onChangeStatus)="handleMainInsured($event,i)" data-toggle="tooltip" class="btn-success" data-placement="top" title="Select As Main Insured"></app-check-box>
                            </div>
                        </div>
                    </div>

                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <app-inputs [num_form]="insuredModelsContainers.step" [events]="eventsSubject.asObservable()" (formField)="changeForm($event,i)" [insuredFields]="insuredOptions" [insuredIndex]="i"></app-inputs>
                <div class="options-box" *ngIf="CoveragePerUnitStepForm.length>0">
                    <div class="col-4" style="margin-bottom:2%;">
                        <h5>Garanties:</h5>
                        <mat-divider></mat-divider>
                    </div>
                    <div *ngFor="let cv of CoveragePerUnitStepForm ; let index=index">
                        <!-- <div  *ngFor="let checkbox of cv ; let index=index" > -->
                        <mat-checkbox id="{{cv.code}}{{i}}{{index}}" name="{{cv.code}}" [disabled]="cv.required" [checked]="cv.required" (change)="onCheckboxChangeperunit(cv,$event)">{{cv.coverage.label}}{{cv.code}}{{i}}{{index}}</mat-checkbox>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <hr class="rounded">
        <div [style.display]="insuredModelsContainers.maxMultiplicity>this.insuredMap.size?'block':'none'" class="p-0">
            <div *ngIf="insuredModelsContainers.multiple" class="alert alert-success" style="text-align: center;" role="alert" style="display: flex;flex-direction: row;justify-content: space-between;border-color: #e5e5e5;background-color: #f9f3fc;font-size:14px;color: #480072;border-width: 0.5px;">
                <label>Ajouter un assuré</label>
                <button (click)="addInsured()" class="buttonAdd"><mat-icon svgIcon="plusSign" class="addIcon" ></mat-icon></button>
            </div>
        </div>
    </div>
</div>
<div class="row clearbottom"></div>



<ng-template #fraissupplementairespopup>
    <div mat-dialog-title>
        <h3 style="margin:10px 0px;"> Ajouter des frais supplementaires</h3>
    </div>
    <div mat-dialog-content>
        <div *ngFor="let fraissupp of fraisSupp; let i = index" class="d-flex justify-content-start align-items-center">
            <mat-form-field appearance="outline" style=" margin:10px;">
                <mat-label>Frais supplementaires {{ i + 1 }}</mat-label>
                <input matInput [(ngModel)]="fraissupp.label" name="label{{i}}" required>

            </mat-form-field>
            <mat-form-field appearance="outline" style=" margin:10px;">
                <mat-label>Valeur frais {{ i + 1 }}</mat-label>
                <input type="number" matInput [(ngModel)]="fraissupp.val" name="val{{i}}">
            </mat-form-field>
            <mat-checkbox class="mr-3" [(ngModel)]="fraissupp.recurrent">Recurrent</mat-checkbox>
            <mat-checkbox class="mr-3" [(ngModel)]="fraissupp.amortized">Amortie</mat-checkbox>
            <mat-checkbox class="mr-3" [(ngModel)]="fraissupp.perUnit">Par unité</mat-checkbox>
            <mat-checkbox class="mr-3" [(ngModel)]="fraissupp.pourcentage">Pourcentage</mat-checkbox>
            <button mat-button color="warn" class="mb-3" (click)="removefraisSupp(i)" *ngIf="i > 0"><mat-icon>delete</mat-icon></button>
        </div>
    </div>
    <button mat-raised-button color="primary" (click)="addfraisSupp();"><mat-icon>add</mat-icon>Ajouter</button>
    <mat-divider style="margin:10px 0px;"></mat-divider>

    <div mat-dialog-actions align="end">
        <button class="modifiertaxebtnannuler" mat-raised-button (click)="closeDialog()"> Annuler  </button>
        <button class="modifiertaxebtn" mat-raised-button (click)="addFraisSupplementaires()" matDialogClose> Terminer </button>
    </div>
</ng-template>
