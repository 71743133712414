import { Injectable } from '@angular/core';
import {InsuredModel} from '../pages/Model-Inventory/entity/InsuredModel';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Option} from '../pages/Model-Inventory/entity/Option';
import {FormControl} from '../pages/Model-Inventory/entity/FormControl';

@Injectable({
  providedIn: 'root'
})
export class ModelInventoryService {
  service:string="MODEL-INVENTORY/";

  constructor(private http:HttpClient) { }



  getAllInsured(){
    return this.http.get(environment.baseUrl+this.service+"insuredModel/all");
  }


  addOption(option:Option){
    return this.http.post(environment.baseUrl+this.service+"option/add/Custom",option,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  addInsuredModel(insuredModel: InsuredModel) {
    return this.http.post<InsuredModel>(environment.baseUrl+this.service+"insuredModel/add",insuredModel,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  deleteById(id: number) {
    return this.http.delete(environment.baseUrl+this.service+"insuredModel/"+id)
  }

  deleteOptionById(id: number) {
    return this.http.delete(environment.baseUrl+this.service+"option/"+id)
  }

  getById(id: number) {
    return this.http.get(environment.baseUrl+this.service+"insuredModel/"+id)
  }
  getOptionById(id:number){
    return this.http.get(environment.baseUrl+this.service+"option/"+id)
  }

  saveFormControl(fc: FormControl) {
    return this.http.post(environment.baseUrl+this.service+"option/formControl/add",fc,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  getFormControlList() {
    return this.http.get(environment.baseUrl+this.service+"option/formControl/all");
  }

  getAllOptions() {
    return this.http.get(environment.baseUrl+this.service+"option/all");
  }
  findUniqueIdentifierOption(){
    return this.http.get(environment.baseUrl+this.service+"option/uniqueIdentifier");
  }
  updateInsuredModel(insuredModel: InsuredModel,id:any){
    return this.http.put<InsuredModel>(environment.baseUrl+this.service+"insuredModel/update/"+id,insuredModel,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }
}
