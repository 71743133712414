import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PricingEntity} from '../pages/Company-Engine/product-fill/entity/FormStep';

@Injectable({
  providedIn: 'root'
})
export class ContractServiceService {
  service:string="CONTRACT-ENGINE/";

  constructor(private http:HttpClient) { }
  addContract(pricingEntity:PricingEntity){
    return this.http.post(environment.baseUrl+this.service+'contract/save',pricingEntity,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })
    });
  }
}
