<div class="row toprow" style="margin-top:3vw;">
    <div class="col-lg-8 col-sm-6 col-xs-12 d-flex justify-content-start">
    <h1>Catégories Taxes</h1>
  </div>
  <div class="col-lg-4 col-sm-6 col-xs-12 d-flex justify-content-end" >
    <button class="modifiertaxebtn" (click)="openDialog()">Ajouter Categorie</button>
    
  </div>
  </div>
  
  <div class="row">
    <!-- <div class="col-lg-12 d-flex justify-content-end">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
    </div> -->
  <div class="col-lg-12 col-sm-6 col-xs-12 ">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <!-- MAT-TABLE DATATABLE BEGINS -->
    <table mat-table [dataSource]="datasource" class="mat-elevation-z8">
      
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> N° </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Categorie </th>
          <td mat-cell *matCellDef="let element"> {{element.label}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell *matHeaderCellDef>Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle color="primary"  [matTooltip]="element.active?'Active' : 'Désactive'" [(ngModel)]="element.active" (click)="setStatus(element.id)" [checked]="element.active"></mat-slide-toggle>
          </td>
        </ng-container>
        
        
        <ng-container matColumnDef="statustext">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">{{ getStatusText(element) }}</td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>

    <!-- MAT-TABLE DATATABLE ENDS  -->
  </div>
  <!-- <div class="col-lg-12 d-flex justify-content-end">
  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
</div> -->
</div>



<ng-template #dialogContentTemplate>
  <form [formGroup]="taxeCategorieForm" (ngSubmit)="submitTaxeCategory()">
    <div class="row d-flex justify-content-center">
    <div class="col-lg-4 d-flex justify-content-start">
    <mat-label>Libelle</mat-label>
    </div>
    <div class="col-lg-8 d-flex justify-content-end">
    <input matInput formControlName="label"/>
    </div>
    </div>
    <mat-divider style="margin-top:2vh;margin-bottom:2vh"></mat-divider>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-12 d-flex justify-content-end">
      <button class="modifiertaxebtnannuler" type="button" [matDialogClose] >Annuler</button>
  <button class="modifiertaxebtn" mat-dialog-close type="submit">Ajouter Catégorie</button>
</div>
</div>
</form>
</ng-template>