import {PossibleOption} from './PossibleOption';
import {FormControl} from './FormControl';

export class Option {
  constructor(init?: Partial<Option>) {
    Object.assign(this, init);
  }
  formControl:FormControl=new FormControl();
  id:number;
  label:string;
  configName:string;
  ord:number;
  description:string;
  regex:string;
  typeProperty:number; //1:Adherent property / 2:Product property /3:Contract property
  autofill:boolean;
  requiredAdhesion:boolean;
  requiredTarif:boolean;
  possibleOption:PossibleOption;
  stepOrder:number;
  @JsonIgnore
  toggle=false;
}

function JsonIgnore(cls: any, name: string) {

  const IGNORE_FIELDS = new Map<string, string[]>();
  let clsName = cls.constructor.name;
  let list: string[];

  if (IGNORE_FIELDS.has(clsName)) {
    list = IGNORE_FIELDS.get(clsName);
  } else {
    list = [];
    IGNORE_FIELDS.set(clsName, list);
  }

  list.push(name);
}
