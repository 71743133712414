import {PossibleOption} from '../PossibleOption';

export class TextAreaOption extends PossibleOption{
    text:string;
    regex:string;
  constructor(init?: Partial<TextAreaOption>) {
    super();
    super.type="TextArea"
    Object.assign(this, init);
  }
  setRegex(regex:string){
    this.regex = regex;
  }
}
