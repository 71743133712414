<div class="options-box">
    <div class="row">
        <div class="col-4 ">
            <div class="input-group input-group-sm ">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm" style="font-weight: 500;">Référence : </span>
                </div>
                <input class="form-control" [ngClass]="{ 'is-invalid': status == false, 'is-valid': status == true}" [(ngModel)]="tarifRef" (input)="setStatus()" />
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                <div class="invalid-feedback" *ngIf="status==false">
                    <mat-icon style="vertical-align: middle;">error_outline</mat-icon>
                    Verifiez la reference
                </div>
            </div>
        </div>
        <div class="col-6">
            <button class="btnaddg" (click)="getTarifref()" [disabled]="loading">Envoyer</button>
        </div>
    </div>
</div>

<div class=" row options-dashedbox justify-content-center" *ngIf="globalcvrgeDetails.length==0">

    <div class="col-3 m-3 options-dashedbox">
    </div>
    <div class="col-4 m-3 options-dashedbox">
    </div>
    <div class="col-3 m-3 options-dashedbox">
    </div>
</div>

<div class="options-box" *ngIf="globalcvrgeDetails.length>0">
    <div class="row mb-3">
        <div class="col-4 my-3">
            <mat-card>
                <mat-card-title class="title-details">Montant HT</mat-card-title>
                <mat-card-content>
                    <ng-container *ngFor="let echeancierdetails of tarifrefresult.echeancierDetails">
                        <td *ngIf="tarifMode === echeancierdetails.periodicite">{{ echeancierdetails.totalAmountHT }}</td>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-4 my-3">
            <mat-card>
                <mat-card-title class="title-details">Taxes:</mat-card-title>
                <mat-card-content>
                    <ng-container *ngFor="let echeancierDetails of tarifrefresult.echeancierDetails">
                        <td *ngIf="tarifMode === echeancierDetails.periodicite">{{ echeancierDetails.totalTaxes }}</td>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="col-4">
            <table style="display: table">
                <tr *ngFor="let taxeDetails of tarifrefresult.totalTaxesDetails">
                    <td style="display: table-cell; min-width: 150px; font-weight: bold;">{{ taxeDetails.category }}</td>
                    <td style="min-width: 15px; font-weight: bold;"></td>
                    <td>
                        <table style="display: table;">
                            <tr *ngFor="let taxeVersion of taxeDetails.details">
                                <td style="display: table-cell; min-width: 150px; font-weight: bold;">Date début</td>
                                <td style="display: table-cell; min-width: 150px; font-weight: bold;">Valeur</td>
                                <td style="display: table-cell; min-width: 150px; font-weight: bold;">Distribution</td>
                            </tr>
                            <tr *ngFor="let taxeVersion of taxeDetails.details">
                                <td>{{ taxeVersion.startDate | date: 'yyyy-MM-dd' }}</td>
                                <td *ngIf="taxeDetails.pourcentage === true">{{ taxeVersion.value }} %</td>
                                <td *ngIf="taxeDetails.pourcentage === false">{{ taxeVersion.value }}</td>
                            </tr>
                            <td></td>
                            <td></td>
                            <td *ngIf="taxeDetails.perUnit === true">Par unité</td>
                            <td *ngIf="taxeDetails.perUnit === false">Globale</td>

                        </table>
                    </td>
                </tr>
            </table>
        </div>


        <div class="tablediv">
            <mat-tab-group>
                <div class="col-8" *ngFor="let echeancier of tarifrefresult.echeancierDetails">
                    <mat-tab label={{echeancier.typeFractionnement}} (onClick)="setTarifMode(echeancier.periodicite)">
                        <table class="mt-4 text-center">
                            <tr>
                                <td class="title-details">Ordre</td>
                                <td class="title-details">Montant TTC</td>
                                <td class="title-details">Montant HT</td>
                                <td class="title-details">Taxes</td>
                                <td class="title-details">Date début</td>
                                <td class="title-details">Date fin</td>
                                <td class="title-details">Supplément</td>
                            </tr>
                            <tr *ngFor="let echeance of echeancier.echeances">
                                <td style="display: table-cell; min-width: auto; font-weight: bold;">{{ echeance.order }}</td>
                                <td style="display: table-cell; min-width: auto; ">{{ echeance.amountTTC }}</td>
                                <td style="display: table-cell; min-width: auto; ">{{ echeance.amountHT }}</td>
                                <td style="display: table-cell; min-width: auto; ">{{ echeance.taxes }}</td>
                                <td style="display: table-cell; min-width: auto; ">{{ echeance.startDate }}</td>
                                <td style="display: table-cell; min-width: auto; ">{{ echeance.endDate }}</td>
                                <td style="display: table-cell; min-width: auto; ">{{ echeance.supplement }}</td>
                                <td style="display: table-cell; min-width: auto;">
                                    <div class="hint-icon">
                                        <div class="hint-content">
                                            <div style="display: flex;justify-content: center;align-items: center;">
                                                <label style="font-weight: bold;">Détails des taxes</label>
                                            </div>
                                            <table style="display: table;">
                                                <tr>
                                                    <td style="display: table-cell; min-width: 150px; font-weight: bold;">Taxe</td>
                                                    <td style="display: table-cell; min-width: 150px; font-weight: bold;">Valeur</td>
                                                    <td style="display: table-cell; min-width: 150px; font-weight: bold;">Distribution</td>
                                                </tr>
                                                <tr *ngFor="let taxeDetails of echeance.taxeDetails">
                                                    <td>{{ taxeDetails.category }}</td>
                                                    <td>{{ taxeDetails.value }}</td>
                                                    <td *ngIf="taxeDetails.perUnit === true">Par unité</td>
                                                    <td *ngIf="taxeDetails.perUnit === false">Globale</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="icon">
                                            <mat-icon>info</mat-icon>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="empty-space"></div>
                                </td>
                            </tr>
                        </table>
                        <div>
                            <mat-divider></mat-divider>
                            <div style="display: flex;justify-content: start;align-items: center;width: 100%; margin-top:8px;">
                                <button mat-button (click)="openDialog(tarifrefresult)">Détails par unité</button>
                            </div>
                        </div>
                    </mat-tab>
                </div>





            </mat-tab-group>
        </div>
    </div>
    <div class="options-box">
        <div>
            <button (click)="toggleExpandState()" class="btnaddg" style="margin:10px;">
            {{ allExpandState ? "Fermer tout" : "Tout ouvrir" }}
            <mat-icon inline=true style="font-size:25px;vertical-align: middle;">{{ allExpandState ? 'expand_less' :
              'expand_more' }}</mat-icon>
          </button>
            <mat-slide-toggle matTooltip="Abonnements uniquement" (change)="updateFilterSub($event)" style="margin:10px;justify-content: center;align-items: baseline;">
            </mat-slide-toggle>
            <mat-form-field appearance="outline">
                <mat-label>Recherche</mat-label>
                <input matInput placeholder="Placeholder" [(ngModel)]="searchText" (input)="updateFilter()" />
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
        <div class="row">
            <div class="col-4" *ngFor="let globalcoveragedetails of totalCoveragesSearchTable">
                <mat-expansion-panel [expanded]="allExpandState" style="margin-top:3px;margin-bottom:10px;" [style.border]="!globalcoveragedetails.subscribed ? '1px solid rgba(240, 76, 76, 0.8)' : '1px solid rgba(76, 240, 76, 0.8)'">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width:11.5vw;padding-top:10px;">
                                <span> {{globalcoveragedetails.label }} </span>
                            </div>
                            <div style="padding-top:10px;">
                                <mat-icon *ngIf="globalcoveragedetails.subscribed" style="color:green;font-size: 20px;margin-left:5px;">check_circle</mat-icon>
                                <mat-icon *ngIf="!globalcoveragedetails.subscribed" style="color:red;font-size: 20px; margin-left:5px;rotate: 45deg;">add_circle</mat-icon>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expnlheight" *ngFor="let globalcoveragedetail of globalcoveragedetails | keyvalue ">
                        <ng-container *ngIf="globalcoveragedetail.key !== 'subscribed' && globalcoveragedetail.key !== 'perUnit' && globalcoveragedetail.key!=='taxesDetails' && globalcoveragedetail.key!=='required'">
                            <span class="title-details">{{ keyDisplayMap [globalcoveragedetail.key] }} : </span> {{globalcoveragedetail.value}}
                        </ng-container>
                    </div>
                </mat-expansion-panel>
            </div>
        </div>
    </div>


    <div *ngIf="fraisArray.length !== 0" class="options-box">
        <h4>Frais Supplémentaires :</h4>
        <table class="mt-4 text-center">
            <tr>
                <td class="title-details">Frais</td>
                <td class="title-details">Valeur</td>
            </tr>
            <tr *ngFor="let frais of fraisArray">
                <td style="display: table-cell; min-width: auto; font-weight: bold;">{{ frais.key }}</td>
                <td style="display: table-cell; min-width: auto; ">{{ frais.value.val }}</td>
            </tr>
            <tr>
                <td>
                    <div class="empty-space"></div>
                </td>
            </tr>
        </table>
    </div>

    <div *ngIf="tarifrefresult" class="options-box">
        <h3>JSON Response <button mat-icon-button (click)="copyToClipboard()"><mat-icon>content_copy</mat-icon></button></h3>
    </div>
</div>