import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TarifRefService } from 'src/app/services/tarif-ref.service';
import { TarifDetailsRequest } from '../entities/dto/dtos';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { DialogComponent } from '../../Company-Engine/product-fill/dialog/dialog/dialog.component';
import { ChangeDetectorRef } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';



@Component({
  selector: 'app-tarif-ref',
  templateUrl: './tarif-ref.component.html',
  styleUrls: ['./tarif-ref.component.css']
})
export class TarifRefComponent {
  tarifrefresult: any;
  tarifRef: string = '';
  status: boolean;
  loading: boolean = false;
  searchText:String='';
  globalcvrgeDetails : {code: string, label: string ,perUnit: boolean,required: boolean,subscribed: boolean,montantHT: number,
  montantTTC: number,taxes:number}[]=[];
  totalCoveragesSearchTable : {code: string, label: string ,perUnit: boolean,required: boolean,subscribed: boolean,montantHT: number,
  montantTTC: number,taxes:number}[]=[];
  allExpandState = false;
  fraisArray: any[] = [];

  constructor(private tarifref:TarifRefService,private http: HttpClient,public dialog: MatDialog,private cdr: ChangeDetectorRef,private clipboard: Clipboard) {
  }

  ngOnInit(): void {
  }

  setStatus(){
    if(this.tarifRef.length == 0){
      this.status = null;
    }
  }

  getTarifref(){
    this.totalCoveragesSearchTable=[]
    this.globalcvrgeDetails=[]
    const tarifDetailsRequest = new TarifDetailsRequest();
    tarifDetailsRequest.tarifRef = this.tarifRef;
    this.loading = true;
    this.tarifref.getTarifRef(tarifDetailsRequest).subscribe((data:CustomResponse)=>{
     if (data.statusCode==200){
        this.status = true;
        }
        else {
          this.status = false;
        }
        this.loading = false

      this.tarifrefresult = data.response ;

      this.tarifrefresult.echeancierDetails[0].totalGlobalCoveragesDetails.forEach((element) => {
          this.globalcvrgeDetails.push(element);
    });
    this.globalcvrgeDetails = this.globalcvrgeDetails.filter((value, index, array) => index == array.findIndex(item => item.code== value.code));
    this.totalCoveragesSearchTable = this.globalcvrgeDetails
    this.transformObjectToArray(this.tarifrefresult.fraisSupp!) ; 
    },
    (error) => {
      this.loading = false
      this.status = false;
    });
  }

  updateFilter() {
    console.log(this.totalCoveragesSearchTable);
    // If search text is empty, show all items
    if (this.searchText.trim() === '') {
      this.totalCoveragesSearchTable =[]
      this.totalCoveragesSearchTable = this.globalcvrgeDetails;
    } else {
      // Update the filteredArray based on the search text
      this.totalCoveragesSearchTable = this.globalcvrgeDetails.filter(item =>
        item.label.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
  }

  updateFilterSub(event) {
    if (!event.checked) {
      this.totalCoveragesSearchTable =[]
      this.totalCoveragesSearchTable = this.globalcvrgeDetails;
    } else {
      // Update the filteredArray based on the search text
      this.totalCoveragesSearchTable = this.globalcvrgeDetails.filter(item =>
      item.subscribed);
    }
  }

  keyDisplayMap: { [key: string]: string } = {
    amountHT: 'Montant HT',
    amountTTC: 'Montant TTC',
    code: 'Code',
    label: 'Label',
    taxes: 'Taxes',
    // Add more keys as needed
  };


  tarifMode=1;
  setTarifMode(mode:number){
    this.tarifMode = mode ;
    console.log(this.tarifMode);
  }


  openDialog(data : TarifDetailsRequest) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  toggleExpandState() {
    this.allExpandState = !this.allExpandState;
  }

  transformObjectToArray(obj: any, parentKey: string = ''): void {
    for (let [key, value] of Object.entries(obj)) {
      if (typeof value === 'object') {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
        this.fraisArray.push({ key: fullKey, value });
      }
    }
  }

  copyToClipboard(): void {
    const jsonData = JSON.stringify(this.tarifrefresult, null, 2);
    this.clipboard.copy(jsonData); 
  }

}
