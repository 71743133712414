import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import { TaxesService } from 'src/app/services/taxes.service';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import { Taxe, TaxeCategory } from '../entities/models/models';
import { TaxeDto,TaxeVersionDto } from '../entities/dto/dtos';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.css']
})


export class TaxesComponent implements OnInit {
  @ViewChild('addTaxePopup') addTaxePopup: TemplateRef<any>;
  @ViewChild('updateTaxesPopup') updateTaxePopup: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  datasource: MatTableDataSource<any>= new MatTableDataSource();


  taxe : Taxe[]=[] ;
  dataSource : Taxe[]=[]; 
  taxeForm:FormGroup;
  taxeVersionGroup:FormGroup;
  taxeVersion:TaxeVersionDto[]=[]
  taxeCategories: any[] = [];
  taxecategoriesselect:TaxeCategory[]=[];
  taxeupdate: Taxe;
  taxeget: Taxe;

constructor(private taxesService:TaxesService,private formBuilder: FormBuilder,private dialog: MatDialog,private http: HttpClient) { 
  this.taxeCategories.forEach(category => {
    category.selectedTaxVersion = category.taxeVersion[0];
  });

}

  ngOnInit(): void {
    
    this.getTaxCategory();
    this.getTax();
    this.dateDisplay();
    this.addDateValueField()
    
  }

  basicInfo = {
    label:'',
    categoryId:0,
    perUnit:null,
    pourcentage:null,  
  };




dateDisplay(){
  if (this.dateValueFields.length === 1) {
    this.dateValueFields[0].selected = true;
  }
}


  dateValueFields: any[] = [];
  addDateValueField() {
    this.dateValueFields.push({
      startDate: '',
      endDate: '',
      value: ''
    });
    
    console.log(this.dateValueFields);
  }  
  

  removeDateValueField(index: number) {
    this.dateValueFields.splice(index, 1);
  } 

  getTaxCategory(){
    this.taxesService.getAllTaxeCategory().subscribe((data:CustomResponse)=>{
      this.taxecategoriesselect = data.response ;
      console.log("these are tax categories",this.taxecategoriesselect);
      this.taxecategoriesselect=this.taxecategoriesselect;

    })
  }

getTax(){
  this.taxesService.getAllTaxe().subscribe((data:CustomResponse)=>{
    this.taxe= data.response ;
    console.log("these are taxes :",this.taxe);
    this.datasource.data=this.taxe;
    this.datasource.paginator = this.paginator

  })
} 

submitTaxe() {
    if (this.isTaxeDataEmpty()) {
      return console.log("Didn't add taxe");
    }
  
    const taxeDto: TaxeDto = {
      categoryId: this.basicInfo.categoryId,
      label: this.basicInfo.label,
      perUnit: this.basicInfo.perUnit,
      pourcentage: this.basicInfo.pourcentage,
      taxeVersion: this.dateValueFields
    };
  
    console.log(taxeDto);
  
    this.taxesService.addTaxe(taxeDto).subscribe(
      response => {
        console.log('POST request successful:', response);
        window.location.reload();

      },
      error => {
        console.error('Error in POST request:', error);
      }
    );
  }
  
updateNextStartDate(index: number) {
    const previousIndex = index - 1;
    if (previousIndex < index) {
      this.dateValueFields[previousIndex].endDate = this.dateValueFields[index].startDate;
    }
  }


isTaxeDataEmpty() {
    return (
      !this.basicInfo || !this.dateValueFields || this.dateValueFields.length === 0
    );
  }

  displayedColumns: string[] = ['position','categorylabel', 'name', 'symbol','value', 'distribution','active','modifier',];


  openDialogadd(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.addTaxePopup, {
      width: 'fit-content',
    });
    this.dateValueFields = [];
  }

    closeDialogadd(): void {
      this.dialog.closeAll();
    }
    
    openDialogupdate(id:number): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.updateTaxePopup, {
      width: 'fit-content', // Set the desired width
    });
    console.log("this should eb the id for the taxe",id);
    console.log("this should be taxeupdate :",this.taxeupdate);
    this.taxeGet(id);}


    closeDialogupdate(): void {
      this.dialog.closeAll(); // Close all open dialogs
    }



     taxeUpdate(id:number){
      const taxeDto: TaxeDto = {
        categoryId: this.basicInfo.categoryId,
        label: this.basicInfo.label,
        perUnit: this.basicInfo.perUnit,
        pourcentage: this.basicInfo.pourcentage,
        taxeVersion: this.dateValueFields
      };

      this.taxesService.updateTaxe(id,taxeDto).subscribe(
         (data) => {
          window.location.reload();

         },
         error => {
          console.log("post IDentifier :",this.taxe);
           console.error("Error changing status:", error);
           
         }
       );
   console.log("post IDentifier :",this.taxe);
     }


     taxeGet(id:number){
      this.taxesService.getTaxebyId(id).subscribe(
        (data:CustomResponse) => {
          this.taxeget = data.response;
          this.dateValueFields = [] ;
          this.basicInfo = {
            categoryId:this.taxeget.category.id,
            label:this.taxeget.label,
            perUnit:this.taxeget.perUnit,
            pourcentage:this.taxeget.pourcentage,
          }
          this.taxeget.taxeVersion.forEach((txv) => {
            this.dateValueFields.push({
              startDate: txv.startDate,
              endDate: txv.endDate,
              value: txv.value
            });
          })
          
        },
         error => {
           console.error("Error changing status:", error);
         }
       );
   console.log("taxeget :",this.taxeget);
     }

     applyFilter(filterValue: string) {
      this.datasource.filter = filterValue.trim().toLowerCase();
      if (this.datasource.paginator) {
        this.datasource.paginator.firstPage();
      }
    }


}
