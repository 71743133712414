import {InsuredModelContainerFull} from './InsuredModelContainerFull';

class PriceTable {
  id:number;
  label:string;
}

export class ProductFull {
  constructor(init?: Partial<ProductFull>) {
    Object.assign(this, init);
  }
  id:number;
  title:string;
  steps:number;
  productRange:string;
  productLine:string;
  priceTableID:PriceTable[];
  insuredModelContainers:InsuredModelContainerFull[];
  countMainInsured:boolean;

}
