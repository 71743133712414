import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Company} from '../../Company-Inventory/entity/company';
import {Product} from '../../Company-Inventory/entity/Product';
import {CompanyInventoryService} from '../../../services/company-inventory.service';
import {PricetableEngineService} from '../../../services/pricetable-engine.service';
import {PriceTableInventoryServiceService} from '../../../services/price-table-inventory-service.service';
import {ProductFull} from '../../Company-Engine/entity/ProductFull';
import {TablePrice} from '../../PriceTableEngine/entity/TablePrice';
import {RulesInventoryService} from '../../../services/rules-inventory.service';
import {RulesEngineService} from '../../../services/rules-engine.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FreeRule } from '../entities/models/models';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';


@Component({
  selector: 'app-free-rule',
  templateUrl: './free-rule.component.html',
  styleUrls: ['./free-rule.component.css']
})
export class FreeRuleComponent implements OnInit {

  jsonObject: string = ''; 
  parsedJsonObject: Object = null;

  freeRuleStr:string="";
  dialogRef: MatDialogRef<any>;
  rulesList: FreeRule [] = [] ; 
  freeRule : FreeRule = null ; 
  newRule: boolean = false ; 
  ruleLabel: string = "" ; 

  @ViewChild('monacoeditorpopuppre') monacoeditorpopuppre: TemplateRef<any>;
  @ViewChild('monacoeditorpopupprebigger') monacoeditorpopupprebigger: TemplateRef<any>;
  @ViewChild('monacoeditorpost') monacoeditorpost: TemplateRef<any>;
  @ViewChild('monacoeditorpostbigger') monacoeditorpostbigger: TemplateRef<any>;


  constructor(private rulesEngineService:RulesEngineService,private rulesInventoryService:RulesInventoryService,private companyInventoryService:CompanyInventoryService,private pricetableEngineService:PricetableEngineService,private  priceTableInventoryServiceService:PriceTableInventoryServiceService, private elementRef: ElementRef,private dialog:MatDialog) { }


  ngOnInit(): void { 
    this.rulesInventoryService.getFreeRules().subscribe((response:CustomResponse) => {
      this.rulesList = response.response ; 
    })
  }

  dialogWidth: string = 'fit-content'; 
  dialogHeight: string = 'auto';

  openDialogmonaco(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpopuppre, {
      minWidth: this.dialogWidth,
      minHeight: this.dialogHeight,
    });
  }
  
  openDialogmonacobigger(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpopupprebigger, {
      minWidth: '100vw',
      minHeight: '90vh',
    });
  }
  
  openDialogmonacopost(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpost, {
      minWidth: this.dialogWidth,
      minHeight: this.dialogHeight,
    });
  } 
  openDialogmonacopostbigger(): void {
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.monacoeditorpostbigger, {
      minWidth: '100vw',
      minHeight: '90vh',
    });
  }


  editorOptions = {theme: 'vs-dark', language: 'python', automaticLayout: true};
  code: string= 'function x() {\nconsole.log("Hello world!");\n}';


  updateMethod() {
    var idRule = null ; 
    if (this.freeRule != null) {
      idRule = this.freeRule.id;
    }
    const freeRule: FreeRule = {
      id: idRule,
      label: this.ruleLabel,
      jsonModel: "",
      rule: this.freeRuleStr
    };
    
    this.rulesInventoryService.addFreeRule(freeRule).subscribe((response) => {

    })
  }

  panelOpenState = false;

  submitJson(): void {
    try {
      this.parsedJsonObject = JSON.parse(this.jsonObject);
    } catch (error) {
      alert('Format JSON invalide');
    }
  }

  onJsonChange(newJson: string): void {
    this.jsonObject = newJson;
    this.parsedJsonObject = null;
  }

  onSelectRule(event:string) {
    if (event == "0") {
      this.jsonObject = "" ; 
      this.freeRuleStr = "" ; 
      this.freeRule = null ; 
      this.newRule = true ; 
      this.parsedJsonObject = null ; 
      this.ruleLabel = "" ; 
    } else {
      this.newRule = false ; 
      this.rulesInventoryService.getFreeRuleById(event).subscribe((response:CustomResponse) => {
        this.freeRule = response.response ; 
        this.jsonObject = this.freeRule.jsonModel ; 
        this.freeRuleStr = this.freeRule.rule ; 
        this.ruleLabel = this.freeRule.label;
      }) ; 
    }
    


  }
}
