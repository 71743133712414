import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {TextAreaOption} from '../entity/possibleOpt/TextAreaOption';
import {SelectOption, SelectPossibleValues} from '../entity/possibleOpt/SelectOption';
import {Option} from '../entity/Option';
import {
  CheckBoxInput,
  DateInput,
  NumberInput,
  RadioInput,
  RadioPossibleValue,
  RangeInput, RangePossibleValues,
  TextInput
} from '../entity/possibleOpt/InputOption';
import {InsuredModel} from '../entity/InsuredModel';
import {ModelInventoryService} from '../../../services/model-inventory.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {CategoryOption, CategoryPossibleValues, ChoiceList } from '../entity/possibleOpt/CategoryOption';
import {FormControl} from '../entity/FormControl';


class PossibleOpt {
  constructor(init?: Partial<PossibleOpt>) {
    Object.assign(this, init);
  }
  label: string;
  min:number;
  max:number;
  val: string;
}

@Component({
  selector: 'app-create-insured-model',
  templateUrl: './create-insured-model.component.html',
  styleUrls: ['./create-insured-model.component.css']
})

export class CreateInsuredModelComponent implements OnInit {

  ord:number=1;
  optionsList: Option[] = [];
  allOptionsList: Option[] = [];
  possibleVals: PossibleOpt[] = [];
  formControl: FormGroup;
  optionForm: FormGroup;
  regexForm: FormGroup;
  typeOptionForm: FormGroup;
  possibleOptionForm: FormGroup;
  rangeOptionForm: FormGroup;
  InsuredModelForm: FormGroup;
  NumberInputOption: FormGroup;

  nbPossibleOpts: number = 1;
  formControlList: FormControl[] = [];
  selectedFormControl: FormControl=new FormControl();
  searchString: string;
  searchStringFormControl: string;
  searchStringOption: string;

  constructor(private formBuilder: FormBuilder, private modelInventoryService: ModelInventoryService,private route:Router) {
  }

  ngOnInit() {
    this.initForm();
    this.UpdateFormControleList();
    this.UpdateAllOptionsList();
  }

  private initForm() {
    this.InsuredModelForm = this.formBuilder.group({
      label: '',
      description: ''
    });

    this.regexForm = this.formBuilder.group({
      regex: ''
    });
    this.NumberInputOption = this.formBuilder.group({
      regex: '',
      min:0,
      max:0,
      step:0
    });

    this.typeOptionForm = this.formBuilder.group({
      typeOption: ''
    });

    this.rangeOptionForm = this.formBuilder.group({
      min: 0,
      max: 100
    });

    this.possibleOptionForm = this.formBuilder.group({
      label: '',
      min: 0,
      max: 0,
      val: 0
    });
    this.optionForm = this.formBuilder.group(
      {
        label: '',
        configName: '',
        ord:0,
        description: '',
        requiredTarif: '',
        requiredAdhesion: '',
        autofill: '',
        typeProperty: '',
        stepOrder:null,

      }
    );
    this.formControl = this.formBuilder.group({
      label:'',
      uniqueIdentifier:'',
    })
  }

  onSubmit() {
    if(this.selectedFormControl.id==null){
      alert("Should Select Form Control!")
      return ;
    }
    let option = new Option(this.optionForm.value);
    option.formControl=this.selectedFormControl;
    switch (this.typeOptionForm.value['typeOption']) {
      case 'RadioInput': {
        let radioInput = new RadioInput();
        radioInput.radioPossibleValues = [];
        for (let opt of this.possibleVals) {
          radioInput.radioPossibleValues.push(new RadioPossibleValue(opt.label, opt.val));
        }
        option.possibleOption = radioInput;
        break;
      }
      case 'CategoryInput': {
        let categoryInput = new CategoryOption();
        categoryInput.possibleValues = [];
        for (let opt of this.possibleVals) {
          categoryInput.possibleValues.push(new CategoryPossibleValues(opt.label,opt.val));
        }
        option.possibleOption = categoryInput;
        break;
      }
      case 'SelectOption': {
        let selectOption = new SelectOption();
        selectOption.selectPossibleValues = [];
        for (let opt of this.possibleVals) {
          selectOption.selectPossibleValues.push(new SelectPossibleValues(opt.label, opt.val));
        }
        option.possibleOption = selectOption;
        break;
      }
      case 'TextAreaOption': {
        let textAreaOption = new TextAreaOption();
        textAreaOption.setRegex(this.regexForm.value['regex']);
        option.possibleOption = textAreaOption;
        break;
      }
      case 'TextInput': {
        let textInput = new TextInput();
        textInput.setRegex(this.regexForm.value['regex']);
        option.possibleOption = textInput;
        break;
      }
      case 'DateInput': {
        option.possibleOption = new DateInput();
        break;
      }
      case 'RangeInput': {
        let rangeInput = new RangeInput();
        rangeInput.min = this.rangeOptionForm.value['min'];
        rangeInput.max = this.rangeOptionForm.value['max'];
        rangeInput.ranges=[];
        for (let opt of this.possibleVals) {
          rangeInput.ranges.push(new RangePossibleValues(opt.min, opt.max,opt.label,opt.val));
        }
        option.possibleOption = rangeInput;
        break;
      }
      case 'NumberInput': {
        let numberInput = new NumberInput();
        numberInput.setmin(this.NumberInputOption.value['min']);
        numberInput.setmax(this.NumberInputOption.value['max']);
        numberInput.setstep(this.NumberInputOption.value['step']);
        numberInput.setRegex(this.NumberInputOption.value['regex']);
        option.possibleOption = numberInput;
        break;
      }
      case 'CheckBoxInput': {
        option.possibleOption = new CheckBoxInput();
        break;
      }
      default: {
        break;
      }
    }
    this.optionsList.push(option);
    this.optionsList.sort((a, b) => a.ord-b.ord);
    this.resetForms();
    this.UpdateAllOptionsList();
    this.setOptionOrder();
  }

  resetForms() {
    this.formControl.reset()
    this.selectedFormControl=new FormControl();
    this.possibleOptionForm.reset();
    this.optionForm.reset();
    this.rangeOptionForm.reset();
    this.typeOptionForm.reset();
  }

  addPossibleOpt() {
    let possibleOption = new PossibleOpt(this.possibleOptionForm.value);
    possibleOption.val=this.GenerateRandomStr()
    this.possibleVals.push(possibleOption);
    this.possibleOptionForm.reset();
    this.nbPossibleOpts = this.possibleVals.length + 1;
  }


  onChangeOptionType() {
    this.possibleOptionForm.reset();
    this.possibleVals = [];
    this.nbPossibleOpts = 0;
    this.optionForm.value['min'] = 0;
    this.optionForm.value['max'] = 100;
  }

  saveInsuredModel() {
    let im = new InsuredModel(this.InsuredModelForm.value);
    im.parameters = this.optionsList;
    this.modelInventoryService.addInsuredModel(im).subscribe(data=>{
      this.route.navigate(["/pages/"+environment.insuredModelURL])
    });
  }

  retour() {
    this.route.navigate(["/pages/"+environment.insuredModelURL])
  }

  deleteOption(id: number) {
    this.modelInventoryService.deleteOptionById(id).subscribe();
  }

  deleteOptionByIndex(i: number) {
    this.optionsList.splice(i,1);
  }

  selectFormControl(id: number) {
    for(let formControl of this.formControlList){
      if(formControl.id==id){
        this.selectedFormControl=formControl;
        this.fillConfigName();
      }
    }
  }

  saveFormControl() {
    let fc = new FormControl(this.formControl.value);
    this.modelInventoryService.saveFormControl(fc).subscribe(data=>{
      this.UpdateFormControleList();
      this.formControl.reset();
      this.setOptionOrder();
    });
  }

  public UpdateFormControleList() {
    this.modelInventoryService.getFormControlList().subscribe((data:FormControl[])=>{
      this.formControlList=data;
    })
  }
  public UpdateAllOptionsList() {
    this.modelInventoryService.getAllOptions().subscribe((data:Option[])=>{
      this.allOptionsList=data;
    })
  }

  addOption(opt: any) {
    this.optionsList.push(opt);
  }

  fillConfigName() {
    this.optionForm.controls["configName"].setValue(this.field_name_normalizer(this.selectedFormControl.uniqueIdentifier));
  }

  field_name_normalizer(str:string){
    str.replace("\s","_")
    str.replace("^(\d)+","_\\1")
    return str+'_'+this.GenerateRandomStr();
  }
  GenerateRandomStr(){
    var chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var length=18;
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  setOptionOrder() {
    this.optionForm.controls["ord"].setValue(this.ord);
    this.ord=this.ord+1;
  }

  setFormControlUniqueIdentifier() {
    console.log(this.field_name_normalizer(this.formControl.controls['label'].value))
    this.formControl.controls['uniqueIdentifier'].setValue(this.field_name_normalizer(this.formControl.controls['label'].value));
  }

  autoFillSelectOptionVal(index:number) {

  }

  addPossibleOptCategory() {
      let possibleOption = new PossibleOpt(this.possibleOptionForm.value);
      this.possibleVals.push(possibleOption);
      this.possibleOptionForm.reset();
      this.nbPossibleOpts = this.possibleVals.length + 1;
  }
}
