import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { element } from 'protractor';
import { CoverageInventoryService } from 'src/app/services/coverage-inventory.service';

import { CompanyInventoryService } from '../../../services/company-inventory.service';
import { ModelInventoryService } from '../../../services/model-inventory.service';
import { Coverage } from '../../Coverage-Inventory/entity/Coverage';
import { ProductC } from '../../Coverage-Inventory/entity/Product';
import { InsuredModel } from '../../Model-Inventory/entity/InsuredModel';
import { Company } from '../entity/company';
import { CoverageValue } from '../entity/coveragesValue';
import { InsuredModelContainer } from '../entity/InsuredModelContainer';
import { Product } from '../entity/Product';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CustomResponse } from '../../Company-Engine/product-fill/entity/CustomResponse';
import { TaxesService } from 'src/app/services/taxes.service';
import { Taxe } from '../../Taxes/entities/models/models';
import { ModelDto, ProductDto } from '../../Taxes/entities/dto/dtos';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { MatDialog, MatDialogRef,MatDialogConfig } from '@angular/material/dialog';
import { ProductCoverageDto } from '../../Coverage-Inventory/coverages-list/entities/dtos/coveragedtos';
import { ProductDtoCoverage } from '../../Coverage-Inventory/coverages-list/entities/dtos/coveragedtos';
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})


export class CompanyDetailsComponent implements OnInit {
 

  
  


  displayedColumnsgaranties: string[] = ['coveragelabel', 'perunit', 'required', 'includedinprice','paymentannuel','pricetypettc','taxes'];
  displayedColumnsgarantiesupdate: string[] = ['coveragelabelupdate', 'perunitupdate', 'requiredupdate', 'includedinpriceupdate','paymentannuelupdate','pricetypettcupdate','taxesupdate'];

  ProductEditForm : FormGroup ; 
  company:Company=new Company();
  displayedColumns: string[] = ['index', 'libelle', 'description', 'nbrproduit', 'proprietes'];
  dataSource :  MatTableDataSource<any>= new MatTableDataSource();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  productForm:FormGroup;
  insuredModelForm:FormGroup;
  insuredModelContainerForm:FormGroup;
  modelsList:InsuredModel[]=[];
  insuredModelContainerList: InsuredModelContainer[] = [];
  insuredModelContainerListEdit: InsuredModelContainer[] = [];
  nbPossibleOpts: number = 1;
  steps:number=1;
  searchString: string;
  productFilled : Product ;
  productInsuredModels : InsuredModel [] = [] ; 


  coveragesList :Coverage [] = [];
  coveragesListupdate :any [] = [];
  selectedCoverages : any [] = [];
  selectedCoveragesadd : any [] = [];
  selectedCoveragestaxes : Coverage [] = [];
  selectedCoveragesdropdown : any [] = [];
  selectedCoveragesFinal : Coverage [] = [];
  dropdownSettings :IDropdownSettings = {} ; 
  // dropdownSettings2 :IDropdownSettings = {} ; 
  dropdownSettingscoverageupdate : IDropdownSettings ={}
  dropdownSettingscoverages :IDropdownSettings = {} ;   
  // dropdownSettingsCoverages :IDropdownSettings = {} ;   
  coverage:Coverage ; 
  productLineId : number ;
  productCId : number ;  

  coverageValues : CoverageValue [] = [] ; 
  coverageFormEditor : FormGroup ; 

  // Taxe
  taxesPerModel : Taxe[]=[] ;
  taxemodel: ModelDto[]=[];
  taxeproduct: ProductDto[]=[];
  productTaxes: Taxe[]=[]
  selectedTaxes: Taxe[] = []; 
  selectedmodel:number[]=[];
  selectedtaxesids:number[]=[];


  coverageValuesFormGroup = this.formBuilder.group({
    coveragesV : this.formBuilder.array([])
});
  errormsg: string;

  constructor(private taxesService:TaxesService,private companyInventoryService:CompanyInventoryService,private modelInventoryService:ModelInventoryService,private routerURL: ActivatedRoute,private router:Router,private formBuilder:FormBuilder
    ,private matIconRegistry: MatIconRegistry,private domSanitizer: DomSanitizer,private coverageInventoryService:CoverageInventoryService,public dialog: MatDialog,private cdr: ChangeDetectorRef) {

      this.coverageFormEditor = this.formBuilder.group({
        id: '',
        productId: '',
        coverageId: '', 
        value :'', 
        unite:''
      });
  
    this.productForm= this.formBuilder.group({
      title:'',
      productRange:'',
      productLine:'',
      steps:''
    });
    this.modelInventoryService.getAllInsured().subscribe((data:InsuredModel[])=>{
      this.modelsList = data;
    });
    this.insuredModelForm = this.formBuilder.group({
      id:'',
      label:''
    });
    this.insuredModelContainerForm = this.formBuilder.group({
      step:'',
      order:'',
      multiple:'',
      minMultiplicity:'',
      maxMultiplicity:'',
      useAsMainInsured:'',
      mainInsured:false,
      minMainInsured:'',
      maxMainInsured:''
    });
  }

  dropdownSettings2:IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };


  dropdownSettingsCoverages:IDropdownSettings = {
    singleSelection: false,
    idField: 'code',
    textField: 'label',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.routerURL.params.subscribe(params=>{
        this.companyInventoryService.getCompanyById(params['id']).subscribe((data:Company)=>{
          this.company=data;
          this.dataSource.data= this.company.products;
          // console.log(this.dataSource)
        })

      });
      this.initProductForm(); 

  
      
  }
  @ViewChild('deleteconfirmationdialog') deleteconfirmationdialog: TemplateRef<any>;
  @ViewChild('dialogContentTemplate') dialogContentTemplate: TemplateRef<any>;
  selectedItemToDelete: { id: number, title: string };
  selectedProductId: number;

  openDialog(id,title): void {
    this.selectedItemToDelete = {id,title};
    const dialogRef: MatDialogRef<any> = this.dialog.open(this.deleteconfirmationdialog, {
      width: 'fit-content', // Set the desired width
    
    
    });

  this.selectedProductId=this.selectedItemToDelete.id
  }



  displayedColumns2: string[] = ['position', 'name', 'weight'];

  getItemsInListCoverages(searchList: Coverage[], elements: ProductDtoCoverage[]): ProductDtoCoverage[] {

    return elements.filter(element =>
      element.coverages.some(coverage =>
        searchList.some(item => item.code === coverage.coverageCode)

      )

      );

  }

  // getItemsInListCoverages(coveragesList: Coverage[], elements:ProductDtoCoverage[]): ProductDtoCoverage[] {
  //   return elements.filter(element => coveragesList.some(item => item.code === element.coverages[1].coverageCode));
  // }


  getItemsInList(searchList: Taxe[], elements: Taxe[]): Taxe[] {
    return elements.filter(element => searchList.some(item => item.id === element.id));
  }

  taxeinfo = {
    idModel:0,
    idproduct:0,
    taxeid:[],
  };

  deleteProduct() {
    this.companyInventoryService.deleteProduct(this.selectedItemToDelete.id).subscribe(data=>{
      this.ngOnInit();
    });
  }


  addProduct() {

    let product: Product = new Product(this.productForm.value);
    let productC : ProductC = new ProductC(); 

    product.insuredModelContainers=this.insuredModelContainerList;
    product.insuredModelContainers.forEach(element =>{
      element.step = 1;
    })
    product.steps = 1;
    this.companyInventoryService.addProduct(this.company.id,product).subscribe((data:Company)=>{
      this.company = data;
      console.log(this.taxeproduct)
      this.productCId =  this.company.products[this.company.products.length-1].id;
      this.addCoverage(this.productCId);

      // this.addProdcutWithCoverages(productC);
      // this.coverageValues.forEach(element => {
      //   element.productId = this.productCId ;
      // } );
      // this.coverageInventoryService.addCoverageValue(this.coverageValues).subscribe(); 
      this.addTaxesToProduct(this.productLineId) ; 
      this.resetProductForm();
// window.location.reload();
    });
  }




async getModelTaxesbyId(id: number) {
  try {
    const datamodel: any = await this.taxesService.getModelTaxesbyId(id).toPromise();
    this.taxesPerModel = datamodel.response;
    console.log("these are taxes per model",this.taxesPerModel);
    this.errormsg = datamodel.message;
    this.enableSelectElement();
    
    this.dropdownSettingscoverageupdate = {
      singleSelection: false,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
     
  } catch (error) {
    console.error("An error occurred when getting taxes:", this.errormsg);
    this.disableSelectElement();
  }
}




disableSelectElement() {
  const selectElement = document.getElementById("selectelementmult") as HTMLSelectElement;
  if (selectElement) {
    selectElement.disabled = true;
  }
}

enableSelectElement() {
  const selectElement = document.getElementById("selectelementmult") as HTMLSelectElement;
  if (selectElement) {
    selectElement.disabled = false;
  }
}


  selectModel(id: number) {
    this.insuredModelForm.controls['id'].setValue(id);
    this.modelInventoryService.getById(id).subscribe((data:InsuredModel)=>{
      this.insuredModelForm.controls['label'].setValue(data.label);
      this.productLineId = data.id; 
      this.getCoveragesByProductLine(this.productLineId);
      this.getModelTaxesbyId(this.productLineId) ;
     
    });

    
  }
  resetModelForms(){
    this.insuredModelContainerForm.reset();
    this.insuredModelForm.reset();
  }
  resetProductForm(){
    this.resetModelForms();
    this.productForm.reset();
    this.modelsList=[];
    this.nbPossibleOpts= 1;
    this.steps=1;
  }

  pushInsuredModelContainer() {
    let insuredModel:InsuredModel=new InsuredModel(this.insuredModelForm.value);
    let insuredModelContainer:InsuredModelContainer = new InsuredModelContainer(this.insuredModelContainerForm.value);
    insuredModelContainer.insuredModel=insuredModel;
    this.insuredModelContainerList.push(insuredModelContainer);
    this.resetModelForms();

  }

  changeSteps() {
    this.steps=this.productForm.value["steps"];
  }



  async getProductById(id: any) {
    try {
      const data: any  = await this.companyInventoryService.getProductById(id).toPromise();
      this.productFilled = data;
      //  this.getProductCoverages(id)
      this.insuredModelContainerListEdit = this.productFilled.insuredModelContainers;
  
      for (const insured of this.insuredModelContainerListEdit) {
        const dataModel: any = await this.modelInventoryService.getById(insured.insuredModel.id).toPromise();
        this.productInsuredModels[0]=dataModel;
  
        await this.getModelTaxesbyId(insured.insuredModel.id);
        await this.getProductTaxesById(id);
        await this.getCoverage(id);
        await this.getCoveragesByProductLine(insured.insuredModel.id)
          this.selectedTaxes = this.getItemsInList(this.taxesPerModel, this.productTaxes);

      }
     
      this.ProductEditForm.patchValue({
        title: this.productFilled.title,
        productRange: this.productFilled.productRange,
        productLine: this.productFilled.productLine,
        steps: this.productFilled.steps,
      });
  
    } catch (error) {
      console.error("An error occurred when getting product data:", error);
    }
  }


  async getProductTaxesById(id: number) {
    try {
      console.log("this is product id:", id);
      const dataproduct: any = await this.taxesService.getProductTaxesbyId(id).toPromise();
      this.productTaxes = dataproduct.response;
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'label',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 4,
        allowSearchFilter: true,
        showSelectedItemsAtTop: true
      }; 
    } catch (error) {
      console.error("An error occurred when getting taxes:", error);
    }
  }

  /*---------------------------------------------------CoveragesSettings----------------------------------------------------------------*/

  private initProductForm() {

    this.ProductEditForm = this.formBuilder.group({
      title:'',
      productRange:'',
      productLine:'',
      steps:''

    });
    
  }



  // onDropdownSelect(): void {
  //   // Add selected coverages to the table
  //   this.selectedCoverages = [...this.selectedCoverages, ...this.selectedCoverages];
  // }

  // onDropdownDeselect(): void {
  //   // Remove deselected coverages from the table
  //   this.selectedCoverages = this.selectedCoverages.filter(c => !this.selectedCoverages.some(selected => selected.code === c.code));
  // }




  onItemSelect(item: any) {
    this.tableDataSource.data = [...this.coveragesListupdate];
    // this.selectedCoverages = [...this.selectedCoverages, item];

  } 

  onItemSelectTaxe() {
    this.getModelTaxesbyId
  }

  onItemDeselect(item: any) {
    this.selectedCoveragesFinal.forEach((element,index)=>{
     if(element.id == item.id) this.selectedTaxes.splice(index,1);
    });
    // this.selectedCoverages = this.selectedCoverages.filter(c => c.code !== item.code);
  }
  
  onItemDeselectCoverage(itemToRemove: any) {
    this.selectedCoverages = this.selectedCoverages.filter(item => item.code !== itemToRemove.code);
 }


  onSelectAll(items: any) {
   console.log(items);
  }

  async getCoveragesByProductLine(id:any){
   try { this.coverageInventoryService.getCoveragesByProductLine(id).subscribe((data:CustomResponse) => {
      this.coveragesList = data.response ; 
      console.log("COVERAGES LIST",this.coveragesList)
        // Create the structure similar to object1
        this.coveragesList.forEach((element,index) => {
          
    
        const newObj = {
          "coverage": {
            "code": this.coveragesList[index].code || "",
            "label": this.coveragesList[index].label || "",
            "description": this.coveragesList[index].description || "",
            "unite": this.coveragesList[index].unite || "",
            "active": this.coveragesList[index].active !== undefined ? this.coveragesList[index].active : false
          },
          "required": false,
          "perUnit": false,
          "includedInTotalPrice": false,
          "priceTypeTTC": false,
          "paymentAnnuel": false,
          "taxes": [
            // {
            //   "id": 0,
            //   "category": {
            //     "id": 0,
            //     "label": "",
            //     "active": false
            //   },
            //   "label": "",
            //   "perUnit": false,
            //   "pourcentage": false,
            //   "active": false,
            //   "taxeVersion": [
            //     {
            //       "startDate": "",
            //       "endDate": "",
            //       "value": 0
            //     }
            //   ]
            // }
          ]
        };

        // Add it to the object1 array
        this.coveragesListupdate.push(newObj);
      });
    });
      // this.coveragesListupdate = data.response ; 
      console.log("COVERAGES LIST UPDATE",this.coveragesListupdate)

    //  this.fillGaranties()

// }); 
}
catch (error) {
  console.error("An error occurred when getting taxes:", error);
}
}
    
    

    garanties:string[]=[]
    fillGaranties(){
    this.coveragesList.forEach((element,index) => {
      this.garanties[index]=element.label
    
    });
    
    }

  async getCoverage(id:any){
   try{ this.coverageInventoryService.getCoverageById(id).subscribe((data:CustomResponse) => {
      this.coverage = data.response ; 
      this.selectedCoveragesFinal.push(this.coverage);
      this.coverage= null ;  
    }); 
  } catch (error) {
    console.error("An error occurred when getting taxes:", this.errormsg);
  }
  
  }

  addProdcutWithCoverages(product:ProductC){
    product.productlineId = this.productLineId ; 
    product.productId = this.productCId; 
    product.coverages = this.selectedCoveragesFinal ; 
    // this.coverageInventoryService.addProduct(product).subscribe(data => {

    // });
  }

  deleteProductC(id:any){
    this.coverageInventoryService.deleteProduct(id).subscribe(); 
  }

  tableDataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  async getProductCoverages(id:any){
    this.cdr.detectChanges();
    this.selectedCoveragesadd=[]
    try{
      this.coverageInventoryService.getProductCoverages(id).subscribe((data:CustomResponse)=> {
      this.selectedCoverages = data.response ; 
      console.log("SELECTED COVERAGES",this.selectedCoverages)
      this.selectedCoverages.forEach((element,index) => {
        this.selectedCoveragesadd[index]=element.coverage;
        console.log("SELECTED COVERAGES ADD",this.selectedCoveragesadd)

        
      }); 
      this.selectedCoverages.forEach((element,index) => {
const taxes = element.taxes;

this.selectedCoveragestaxes[index] = taxes;        
      });
      this.tableDataSource.data = [...this.selectedCoveragesadd];


      

     
    })
} catch (error) {
  console.error("An error occurred when getting taxes:", this.errormsg);
}
  }



  get coveragesV(){
    return this.coverageValuesFormGroup.controls["coveragesV"] as FormArray ; 
  }
  
  

  fillCoverageValue(){
    this.coverageValues = this.coveragesV.value ; 
    }
    
     applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  addTaxesToProduct(modelId:number) {
    var productDto : ProductDto = new ProductDto() ; 
   productDto.modelId = modelId
   productDto.productId = this.productCId
   productDto.taxesIds = this.taxeinfo.taxeid.map(item => item.id);
   if (!productDto || productDto.taxesIds.length === 0) {
  console.log("empty taxes in product dto won't hit api" )
  } else {
    this.taxesService.addTaxeToProduct(productDto).subscribe(
      response => {
        console.log('POST request successful:', response);
      },
      error => {
        console.error('Error in POST request:', error);
        alert("erreur lors de l'ajout des taxes au produit")
      }
    ); 
  }    
  }

  public existInList(searchList: Taxe[],element:Taxe): boolean {
    return searchList.some(item => item.id === element.id);
  }

   
  ProductTaxeUpdate(){
    this.selectedmodel = this.productInsuredModels.map(item => item.id);
    this.selectedtaxesids=this.selectedTaxes.map(item => item.id);
    const product: ProductDto = {
      modelId:this.selectedmodel[0],
      productId:this.productFilled.id,
      taxesIds:this.selectedtaxesids,
    };

    this.taxesService.updateProductTaxes(product).subscribe(
       (data) => {
        console.log("taxesids :",this.selectedtaxesids);
        console.log("productids :",this.productFilled.id);
        console.log("modelids :",this.selectedmodel[0]);

       },
       error => {
        console.log("post IDentifier :",this.selectedmodel);
         console.error("Error changing status:", error);
         
       }
     );
   }

  
coverageselect:any[]=[];
coverageselectupdate:any[]=[];

addCoverage(productid:number) {
const coverageDtoadd = this.prepareDataForApi(productid)
  this.coverageInventoryService.addProductCoverage(coverageDtoadd).subscribe(
    response => {
      console.log('Post request successful:', response);
      // window.location.reload();

    },
    error => {
      console.error('Error in POST request:', error);
    }
  );
}




updateCoverage(productid:number) {
const coverageDto2 = this.prepareDataForApiupdate()
  this.coverageInventoryService.updateProductCoverage(coverageDto2,productid).subscribe(
    response => {
      console.log('Put request successful:', response);
      window.location.reload();

    },
    error => {
      console.error('Error in POST request:', error);
    }
  );
}

checkA(element2: any) {
  if (element2.includedprice) {
    element2.required = true; 
  } else {  }
}

checkAupdate(elementupdate: any) {
  if (elementupdate.includedInTotalPrice) {
    elementupdate.required = true; 
  } else {  }
}

 

prepareDataForApi(productId: number): ProductDtoCoverage {
  const coverages: ProductCoverageDto[] = this.coverageselect.map(element2 => ({
    coverageCode: element2.code,
    includedInTotalPrice: element2.includedprice,
    perUnit: element2.perunit,
    required: element2.required,
    paymentAnnuel:element2.paymentannuel,
    priceTypeTTC:element2.pricetypettc,
    taxes: element2.taxesgaranties ? element2.taxesgaranties.map(item => item.id) : [],

  }));

  const coverageDtoadd: ProductDtoCoverage = {
    coverages: coverages,
    productId: this.productCId, 
    productlineId:this.productLineId  
  };

  console.log("this is coverageDto2", coverageDtoadd);
  return coverageDtoadd;
}

prepareDataForApiupdate(): ProductCoverageDto[] {
  const coveragesDtoadd: ProductCoverageDto[] = this.selectedCoverages.map(elementupdate => ({
    coverageCode: elementupdate.coverage.code,
    includedInTotalPrice: elementupdate.includedInTotalPrice,
    perUnit: elementupdate.perUnit,
    required: elementupdate.required,
    paymentAnnuel:elementupdate.paymentAnnuel,
    priceTypeTTC:elementupdate.priceTypeTTC,
    // taxes:elementupdate.taxes.map(item => item.id),
    taxes: elementupdate.taxes ? elementupdate.taxes.map(item => item.id) : [],



  }));

  console.log("this is coverageDto2", coveragesDtoadd);
  return coveragesDtoadd;
}

resetForm(id :string) {
  // Assuming your form has the ID 'myForm'
  const form = document.getElementById('id') as HTMLFormElement;
  form.reset();
}


isCoverageSelected(coverageupdate: any): boolean {
  // Check if the coverage is already added to the product
  return this.selectedCoverages.some(c => c.coverage.code === coverageupdate.coverage.code);
}

onCheckboxChange(coverageupdate: any): void {
  // Add or remove coverage based on checkbox change
  if (this.isCoverageSelected(coverageupdate)) {
    // Remove coverage
    this.selectedCoverages = this.selectedCoverages.filter(c => c.coverage.code !== coverageupdate.coverage.code);
  } else {
    // Add coverage
    console.log("before adding",this.selectedCoverages)
    this.selectedCoverages=[...this.selectedCoverages,coverageupdate];
    console.log("after adding",this.selectedCoverages)

  }
}

}
