import {PossibleOption} from '../PossibleOption';

export class CategoryOption extends PossibleOption{
  constructor(init?: Partial<CategoryOption>) {
    super();
    super.type="Category"
    Object.assign(this, init);
  }
  valByCategory:boolean;
  possibleValues:CategoryPossibleValues[];
}

export class CategoryPossibleValues {
  id:number;
  label:string;
  choices:ChoiceList[];
  val:string;
  constructor(label:string, choices:string) {
    this.label = label;
    this.val=choices;
    this.setChoices(choices);
  }
  setChoices(choices:string){
    let choiceList = choices.split("\n");
    let allChoiceList:ChoiceList[]=[];
    for(let c of choiceList){
      allChoiceList.push(new ChoiceList(c));
    }
    this.choices =allChoiceList;
  }
}

export class ChoiceList {
  id:number;
  choice:string;
  val:string;
  constructor(choice:string) {
    this.choice = choice;
  }
}
