import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TarifDetailsRequest } from '../pages/TarifRef/entities/dto/dtos';


@Injectable({
  providedIn: 'root'
})
export class TarifRefService {
  service:string="RULES-ENGINE/";
  constructor(private http:HttpClient) { }


getTarifRef(tarifDetailsRequest:TarifDetailsRequest){
  return this.http.post(environment.baseUrl+this.service+"V0/tarification/getDetails",tarifDetailsRequest);
};

}