import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RulesEngineService {

  response : any ; 

  service:string="RULES-ENGINE/";
  constructor(private http:HttpClient) { }

  getContractStructure(productId){
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(environment.baseUrl+this.service+"pyFile/"+productId,{ headers, responseType: 'text'})
  }


  getPrice(data:any){
    this.response = this.http.post(environment.baseUrl+this.service + "V0/tarification/ventilation",data,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })
    })
    console.log(this.response);

    return this.response ; 
  }

  getJsonStructure(data:Object) {
    this.response = this.http.post(environment.baseUrl+this.service + "/V0/freeRule/getObjectStructure",data,{
      headers:new HttpHeaders({
        'content-type':'application/json',
      })
    })

    return this.response ; 
  }
}
