export class Ventilation {
    tarification: Tarification;
    totalTaxesDetails: TaxeRespBody[];
    echeancierDetails: EcheancierDetails[];
    fraisSupp: { [key: string]: any };
  }

  export class Tarification {
    tarifRef: string;
    creationDate: Date;
    expirationDate: Date;
    priceTableVersion: string;
    unitsCount: number
  }


export class EcheancierDetails {
    totalAmountHT: number;
    totalAmountTTC: number = 0.0;
    totalTaxes: number;
    typeFractionnement: string;
    periodicite: number;
    echeances: Echeance[];
    unitsDetails: AdherentPriceDetails[];
  }

export class TaxeRespBody {
    category: string;
    label: string;
    perUnit: boolean;
    pourcentage: boolean;
    details: TaxeVersion[];
  }

export class Echeance {
    order: number;
    amountTTC: number;
    amountHT: number;
    taxes: number;
    startDate: Date;
    endDate: Date;
    supplement: number;
    taxeDetails: TaxeDetailsEcheance[];
    unitsDetails: AdherentPriceDetails[];
  }

export class TaxeVersion {
    startDate: Date;
    endDate: Date;
    value: number;
  }

export class AdherentPriceDetails {
    unitNumber: number;
    totalAmountHT: number;
    totalAmountTTC: number;
    totalTaxeAmount: number;
    taxeDetails: { [key: string]: any };
  }

export class TaxeDetailsEcheance {
    category: string;
    label: string;
    perUnit: boolean;
    value: number;
  }
  
  