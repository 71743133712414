
<div class="row options-box">
  <div class="col-6 px-4 " *ngFor="let option of insuredFields" [style.display]="option.autofill ? 'none' :'block' " >
    <form [formGroup]="formCast" (change)="changeForm()">
      <div class="form-group">
        <label>{{option.label}} <span *ngIf="option.requiredTarif">*</span></label>
        <div style="width: -moz-available;">
          <div [ngSwitch]="option.possibleOption.type">
            <div *ngSwitchCase="'Select'" >
              <select style='width: -moz-available;' class='form-control' size='3' multiple formControlName="{{option.id.toString()}}">
                <option *ngFor="let o of option.possibleOption.selectPossibleValues" value="{{o.label}}" >{{o.label}}</option>
              </select>
            </div>
            <div *ngSwitchCase="'TextArea'">
              <textarea class="form-control" style='width: -moz-available;' rows='2' formControlName="{{option.id.toString()}}"></textarea>
              <mat-divider [vertical]="true"></mat-divider>
            </div>
            <div *ngSwitchCase="'Category'">
              <select class='form-control' formControlName="{{option.id.toString()}}" style='width: -moz-available;'>
                <ng-container *ngFor="let o of option.possibleOption.possibleValues">
                  <option *ngFor="let choice of o.choices" value="{{o.label}}">{{choice.choice}}</option>
                </ng-container>
              </select>


            </div>
            <div *ngSwitchCase="'Input'">
              <div [ngSwitch]="option.possibleOption.typeInput">
                <div *ngSwitchCase="'text'">
                  <input class="form-control" style='width: -moz-available;' type='text' formControlName="{{option.id.toString()}}">
                </div>
                <div *ngSwitchCase="'number'">
                  <input class="form-control" style='width: -moz-available;' type='number' formControlName="{{option.id.toString()}}">
                </div>
                <div *ngSwitchCase="'date'">
                  <input class="form-control"  style='width: -moz-available;' type='date' formControlName="{{option.id.toString()}}">
                </div>
                <div *ngSwitchCase="'radio'">
                  <select class='form-control' formControlName="{{option.id.toString()}}">
                    <option *ngFor="let o of option.possibleOption.radioPossibleValues" value="{{o.label}}">{{o.label}}</option>
                  </select>
                </div>
                <div *ngSwitchCase="'range'" style="padding-left:2%;padding-top:2%;">
                  <input type='range' min="{{option.possibleOption.min}}" max="{{option.possibleOption.max}}"  class='form-control-range' formControlName="{{option.id.toString()}}">
                  <span>valeur = {{formCast.controls[option.id.toString()].value}}</span>
                 
                </div>
                <div *ngSwitchCase="'checkbox'" style="padding-left:2%;padding-top:2%;">
                  <input  class='form-control-checkbox' type='checkbox' value='' formControlName="{{option.id.toString()}}" id="flexCheckDefault">
                </div>
              </div>
            </div>

          </div>
        </div>
        <small id="description" class="form-text text-muted">{{option.description}}</small>
      </div>
    </form>

  </div>

  <div class="col-6">

    <ng-container *ngIf="uniqueIdentifierField != null">
      <form [formGroup]="formCast" (change)="changeForm()">
        <div class="form-group">
          <label>{{uniqueIdentifierField.formControl.label}} <span>*</span></label>
          <div style="width: -moz-available;">
            <div [ngSwitch]="uniqueIdentifierField.possibleOption.type">
              <div *ngSwitchCase="'Input'">
                <div [ngSwitch]="uniqueIdentifierField.possibleOption.typeInput">
                  <div *ngSwitchCase="'text'">
                    <input style='width: -moz-available;' type='text' formControlName="{{uniqueIdentifierField.id}}">
                  </div>
                  <div *ngSwitchCase="'number'">
                    <input style='width: -moz-available;' type='number' formControlName="{{uniqueIdentifierField.id}}">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <small id="description1" class="form-text text-muted">{{uniqueIdentifierField.description}}</small>
        </div>
      </form>
    </ng-container>
  </div>

</div>
