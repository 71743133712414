import {InsuredModel} from './InsuredModel';

export class InsuredModelContainer {
  constructor(init?: Partial<InsuredModelContainer>) {
    Object.assign(this, init);
  }
  id:number;
  step:number;
  order:number;
  multiple:boolean;
  minMultiplicity:number;
  maxMultiplicity:number;
  useAsMainInsured:boolean;
  minMainInsured:number;
  maxMainInsured:number;
  insuredModel:InsuredModel;
}

