import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { CoverageValue } from '../pages/Company-Inventory/entity/coveragesValue';
import { Coverage } from '../pages/Coverage-Inventory/entity/Coverage';
import { ProductC } from '../pages/Coverage-Inventory/entity/Product';
import { Productline } from '../pages/Coverage-Inventory/entity/productLine';
import { CoverageDto, ProductCoverageDto, ProductDtoCoverage, ProductlineDto } from '../pages/Coverage-Inventory/coverages-list/entities/dtos/coveragedtos';

@Injectable({
  providedIn: 'root'
})
export class CoverageInventoryService {

  constructor(private http:HttpClient) { }
  service:string="COVERAGE-INVENTORY"


  /*------------------------------------------------------Coverages-----------------------------------------------------------------*/

  getAllCoverages(){
    return this.http.get(environment.baseUrl+this.service+"/Coverages/all");
  }

  deleteById(id: number){
    return this.http.delete(environment.baseUrl+this.service+"/Coverages/coverage/delete/"+id);
  }

  addCoverage(coverage: CoverageDto) {
    return this.http.post<CoverageDto>(environment.baseUrl+this.service+"/Coverages/coverage/add",coverage,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  updateCoverage(code:any, coverage: CoverageDto){
    return this.http.put<CoverageDto>(environment.baseUrl+this.service+"/Coverages/coverage/update/"+code,coverage,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  getCoverageById(id:any){
    return this.http.get(environment.baseUrl+this.service+"/Products/product/"+id+"/coverages");
  }
  getCoverageByCode(code:any){
    return this.http.get(environment.baseUrl+this.service+"/Coverages/coverage/"+code);
  }

  findByType(type : string){
    let params = new HttpParams().set("type",type);
    return this.http.get<Coverage[]>(environment.baseUrl+this.service+"/Coverages/coverage/type", {params : params});
  }

  /*------------------------------------------------------Coverages_ProductLine-----------------------------------------------------------------*/


  addCoveragesToProductLine(coverageCodes : String [] ,id : any){
    return this.http.put<Coverage>(environment.baseUrl+this.service+"/ProductLines/"+id+"/coverage/add",coverageCodes,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  addProductLine(productline : ProductlineDto){
    return this.http.post<Coverage>(environment.baseUrl+this.service+"/ProductLines/add",productline,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }


  getCoveragesByProductLine(id:any){
    return this.http.get(environment.baseUrl+this.service+"/ProductLines/ProductLines/"+id+"/coverages");
  }


  updateCoveragesInProductLine(id:any,coverages:Coverage[]){
    return this.http.put(environment.baseUrl+this.service+"/ProductLines/"+id+"/coverage/add",coverages);
  }

  deleteProductLine(id:any){
    return this.http.delete(environment.baseUrl+this.service+"/ProductLines/productLine/"+id); 
  }

  /*------------------------------------------------------Coverages_ProductLine_Prodcut-----------------------------------------------------------------*/
  

  addProduct(product:ProductC){
    return this.http.post<Coverage>(environment.baseUrl+this.service+"/Products/product/add",product,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  getProductCoverages(id:any){
    return this.http.get(environment.baseUrl+this.service+"/Products/product/"+id+"/coverages");
  }

  getProductsByProductLineId(id:any){
    return this.http.get(environment.baseUrl+this.service+"/Products/productLine/"+id+"/products");
  }

  deleteProduct(id:any){
    return this.http.delete(environment.baseUrl+this.service+"/Products/products/"+id);
  }

   /*------------------------------------------------------Coverages_Values-----------------------------------------------------------------*/


   addCoverageValue(coveragesValue : CoverageValue []){
    return this.http.post<Coverage>(environment.baseUrl+this.service+"/productCoverage/add",coveragesValue,{
      headers:new HttpHeaders({
        'content-type':'application/json'
      })});
  }

  getCoverageValue(id:any){
    return this.http.get(environment.baseUrl+this.service+"/productCoverage/"+id);
  }

  getByProductId(id:any){
    return this.http.get(environment.baseUrl+this.service+"/productCoverage/prodC/"+id);
  }

/*----------------------------------------------------- Coverages New Dto --------------------------------------------*/ 
CoverageStatus(code:string){
  return this.http.put(environment.baseUrl+this.service+"/Coverages/coverage/setActive/"+ code,null);
  }


  addProductCoverage(productDto:ProductDtoCoverage){
    return this.http.post(environment.baseUrl+this.service+"/Products/product/add",productDto)
  
}
updateProductCoverage(productDto:ProductCoverageDto[],id:number){
    return this.http.put(environment.baseUrl+this.service+"/Products/"+id+"/product/add",productDto)
  
}




}
