import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InsuredModelContainerFull } from '../../Company-Engine/entity/InsuredModelContainerFull';
import { ProductFull } from '../../Company-Engine/entity/ProductFull';
import { Insured } from '../../Company-Engine/product-fill/entity/FormStep';

@Component({
  selector: 'app-multi-step-bar-comparator',
  templateUrl: './multi-step-bar-comparator.component.html',
  styleUrls: ['./multi-step-bar-comparator.component.css']
})
export class MultiStepBarComparatorComponent implements OnInit {
  @Input() product:ProductFull;
  @Output() formField = new EventEmitter();
  @Output() insuredContainerIndex = new EventEmitter();
  pricingForms:Insured[]=[];
  step:number=1;
  selectedInsuredModel:InsuredModelContainerFull=null;
 
   constructor() {}
 
   ngOnInit(): void {
     this.selectInsuredContainer();
     console.log(this.product); 
   }
   selectInsuredContainer(){
     this.product.insuredModelContainers.forEach( insuredContainer =>{
       if(insuredContainer.step==(this.step+1)) this.selectedInsuredModel=insuredContainer;
     });
   }
   setStep(i: number) {
     this.step=i;
     this.selectInsuredContainer();
   }
   changeForm($event:any,i:number) {
     this.pricingForms=$event;
     this.insuredContainerIndex.emit(i);
     this.formField.emit(this.pricingForms);
   }

}
