
<!-- <div class="container" style="margin-top: 100px;margin-left: 0px;width: 100%;"> -->
  <div class="row" >
    <div class="titlelist">
      <h1 class="titlemodel">Détails du Produit</h1>
    </div>
  </div>
  <!-- <div class="row" *ngIf="product!=null" style="margin-bottom: 35px;margin-left: 0px;"> -->
    <!-- <div class="col-12"> -->
      <div class="card bg-light">
        <div class="card-header"><h3>Produit {{product.title}}</h3></div>
        <div class="card-body">
          <div class="row d-flex justify-content-center">
            <div class="col-4 d-flex flex-column justify-content-center">
              <h5>Gamme: </h5>
              <p class="card-text text-success font-weight-bold"> {{product.productRange}}</p>

            </div>

            <div class="col-4 d-flex flex-column justify-content-center">
              <h5>Ligne de produit: </h5>
              <p class="card-text text-success font-weight-bold">{{product.productLine}}</p>

            </div>
            <div class="col-4 d-flex flex-column justify-content-center">
              <h5>Etapes: </h5>
              <p class="card-text text-success font-weight-bold"> {{product.steps}}</p>

            </div>
            <div class="col-12">
            <mat-divider  style="margin:2vh;"></mat-divider>
            </div>
            </div>
            <div class="row d-flex justify-content-center">
            <div class="col-5 d-flex flex-column justify-content-center" >
              <h5>Garanties: </h5>
              <!-- <p class="card-text text-success font-weight-bold" *ngIf="coveragesList.length === 0" > -- </p> -->
              <!-- <p class="card-text text-success font-weight-bold" *ngFor = "let gar of coveragesList"> {{gar.label}} </p> -->
              <mat-chip-list aria-label="garanties">
                <mat-chip *ngFor="let element of garanties2"  >{{element.coverage.label}}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="col-2"><mat-divider [vertical]="true" style="margin:2vh;"></mat-divider>
            </div>
            <div class="col-5 d-flex  flex-column justify-content-center" >
              <h5>Taxes: </h5>
              <!-- <p class="card-text text-success font-weight-bold" *ngIf="coveragesList.length === 0" > -- </p> -->
              <!-- <p class="card-text text-success font-weight-bold" *ngFor = "let gar of coveragesList"> {{gar.label}} </p> -->
              <mat-chip-list aria-label="taxes">
                <mat-chip *ngFor="let element of taxeproduct">{{element.label}}
                </mat-chip>
              </mat-chip-list>
            </div>
            </div>
          </div>
        </div>
      <!-- </div>
    </div> -->
   <div class="row d-flex justify-content-center"> 
    <div class="col-lg-12">
      <app-price-tables-list [productId]="productId"></app-price-tables-list>
    </div>
  <!-- </div> -->
  <!-- </div> -->
<!-- </div> -->
